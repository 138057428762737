import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { pl, enGB } from 'date-fns/locale';
import { groupBy } from 'lodash';
import { CircularProgress } from '@mui/material';
import { ControlWrapper, EmptyStateBox, IconError, IconWarning } from '../../../../../components';
import { AvailableLanguage } from '../../../../../types';
import { useGateHistory } from '../../hooks/use-gate-history';
import { HistoryItem } from './history-item';
import './index.scss';

interface ComponentProps {
  channelId: string;
}

export const GateHistory: React.FC<ComponentProps> = ({ channelId }) => {
  const { t, i18n } = useTranslation('channel-details');
  const { t: tb } = useTranslation('backend-validation');
  const { t: tc } = useTranslation('common');
  const { historyError, emptyData, loading, entries, totalCount, loadMore } = useGateHistory(channelId);
  const historyData = useMemo(() => {
    const groupedHistory = groupBy([...(entries || [])], (x) =>
      format(new Date(x?.occuredAt), 'eeee, dd.MM', { locale: i18n.language === AvailableLanguage.EN ? enGB : pl }),
    );

    return Object.keys(groupedHistory).map((key, index) => (
      <div key={index} className="day-section">
        <p className="date-header">{key}</p>
        <div className="history-items">
          {groupedHistory[key].map((item) => {
            if (item) {
              return <HistoryItem key={item.id} item={item} />;
            }
          })}
        </div>
      </div>
    ));
  }, [entries]);

  return (
    <ControlWrapper className="control-wrapper--column control-wrapper--full-space gate-history">
      {historyError && (
        <div className="history-error">
          <IconError />
          <p>{tb(historyError.errorCode)}</p>
        </div>
      )}
      {emptyData !== null && (
        <>{emptyData && !loading ? <EmptyStateBox content={t('noHistory')} icon={<IconWarning />} /> : historyData}</>
      )}

      <div className="load-more-container">
        {loading ? (
          <CircularProgress color="inherit" />
        ) : entries.length < totalCount ? (
          <button className="button" onClick={loadMore}>
            {tc('buttons.loadMore')}
          </button>
        ) : null}
      </div>
    </ControlWrapper>
  );
};
