import Keycloak, { KeycloakInitOptions } from 'keycloak-js';
import { environment } from '../../environment';

declare module 'keycloak-js/dist/keycloak' {
  interface KeycloakTokenParsed {
    SipName: string;
    SipPassword: string;
    preferred_username: string;
    given_name: string;
    family_name: string;
    email: string;
    name: string;
    roles: string[];
  }
}

const clientId = environment.KEYCLOAK_CLIENT_ID || '';
const realm = environment.KEYCLOAK_REALM || '';

export const keycloakInstance = new Keycloak({ url: `${environment.KEYCLOAK_URL}`, clientId, realm });

export const getKeycloakInitOptions = (): KeycloakInitOptions => {
  const tokenObject = JSON.parse(String(window.localStorage.getItem('tokens')));

  return {
    pkceMethod: 'S256',
    onLoad: 'check-sso',
    // silentCheckSsoRedirectUri: `${location.origin}/silent-check-sso.html`,
    checkLoginIframe: false,
    ...(tokenObject?.token ? { token: tokenObject.token } : {}),
    ...(tokenObject?.idToken ? { idToken: tokenObject.idToken } : {}),
    ...(tokenObject?.refreshToken ? { refreshToken: tokenObject.refreshToken } : {}),
  };
};
