import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QRScannerSecured } from '../../App';
import { DialogConfirmation } from '../dialog';
import { NavHeader } from '../nav-header';
import { QRCodeReader } from '../qr-code-reader';
import './index.scss';

interface PropsInterface {
  openQrReader?: boolean;
  setOpenQrReader?: (value: boolean) => void;
}

// const exalusUrl = 'https://app.tr7.pl/#connect=FORTEST005:0000';

export const IntegrationExalus: React.FC<PropsInterface> = ({ openQrReader = true, setOpenQrReader }) => {
  const [openDialogExalus, setOpenDialogExalus] = useState<boolean>(false);
  const [openReader, setOpenReader] = useState<boolean>(openQrReader);
  const { t } = useTranslation('installation');
  const { t: tc } = useTranslation('common');

  const handleScan = () => {
    // TODO uncomment exalusUrl check
    // if (data === exalusUrl) {
    setOpenReader(false);
    setOpenDialogExalus(true);
    // }
  };

  const onCloseDialog = () => {
    if (QRScannerSecured) {
      // TODO REDIRECT TO HTTPS with removed search param ?qr-scanner=excalus
      // TODO replace below code with function eg. goToBackFromSecuredQRScanner()
      return (location.href = location.href.replace(/^https:\/\//i, 'http://').replace('?qr-scanner=excalus', ''));
    }
    setOpenDialogExalus(false);
    setOpenQrReader && setOpenQrReader(false);
    setOpenReader(false);
  };

  return (
    <>
      {openReader && (
        <div className="integration-exalus">
          <div className="integration-exalus__header">
            <NavHeader onClick={onCloseDialog} />
          </div>
          <QRCodeReader onHandleScan={handleScan} />
        </div>
      )}
      {openDialogExalus && (
        <DialogConfirmation
          show
          setShow={onCloseDialog}
          header={t('exalusDialog.header')}
          content={t('exalusDialog.content')}
          primaryBtnText={tc('close')}
          primaryBtnAction={onCloseDialog}
        />
      )}
    </>
  );
};
