import React, { useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { CustomBackdrop, Header, IconAutomatic, NavHeader, Page, SubmitButton } from '../../../../components';
import { IconUpdateSoftware } from '../../../../components/icons/update-software';
import { ChannelTypeInternal } from '../../../../data-access/gql-types/graphql';
import { useApi, useDevicesAndChannels } from '../../../../hooks';
import { useRefetchData } from '../../../../hooks/refresh-data';
import { ROUTES } from '../../../../routes';
import { useConfiguration } from '../../../configuration/hooks/use-configuration';
import './index.scss';

const UpdateSoftware: React.FC = () => {
  const history = useHistory();
  const { deviceId } = useParams<{ deviceId: string }>();
  const { t } = useTranslation('device-add');
  const { t: tc } = useTranslation('common');
  const { device, channelList } = useDevicesAndChannels({ deviceId });
  const { devicesLoading, channelsLoading } = useApi();
  const { updateDevice, updateDeviceLoading } = useConfiguration({ device });
  const { refetchChannels } = useRefetchData();

  useEffect(() => {
    const interval = setInterval(refreshData, 8000);
    return () => clearInterval(interval);
  }, []);

  const refreshData = () => {
    refetchChannels();
  };

  useEffect(() => {
    if (device?.id) {
      if (device.payload?.currentFirmwareVersion !== device.payload?.newestFirmwareVersion) nextStep();
    }
  }, [device]);

  const nextStep = useCallback(() => {
    switch (device?.type) {
      case ChannelTypeInternal.Blind: {
        const channels = (channelList || []).filter((c) => c.deviceId === deviceId);
        const channel = channels.find((c) => c.data.type === ChannelTypeInternal.Blind);

        if (channel) history.push(ROUTES.ReverseOutputs(channel.id));
        else history.push(ROUTES.AddSuccess());
        break;
      }
      case ChannelTypeInternal.Switch: {
        history.push(ROUTES.ReverseInputs(deviceId));
        break;
      }
      default: {
        history.push(ROUTES.AddSuccess());
        break;
      }
    }
  }, [device, deviceId, channelList]);

  const isUpdateAvailable = useMemo(() => device?.payload?.isUpdateAvailable, [device]);
  const loading = useMemo(() => devicesLoading || channelsLoading, [devicesLoading, channelsLoading]);

  return (
    <Page
      className="update-software"
      header={
        device && (
          <>
            <NavHeader />
            {!loading && (
              <Header
                title={isUpdateAvailable ? t('updateSoftware.updateHeader') : `${t('updateSoftware.updatedHeader')}!`}
                column
                {...(isUpdateAvailable ? { subtitle: t('updateSoftware.updateMessage') } : {})}
                {...(!isUpdateAvailable ? { titleClassName: 'bigger' } : {})}
              />
            )}
          </>
        )
      }
    >
      {device && (
        <>
          <IconUpdateSoftware />
          <div className="section-title">{t('updateSoftware.version')}</div>
          <>
            {!loading ? (
              <>
                <p className="section-subtitle">
                  {isUpdateAvailable
                    ? t('updateSoftware.versionAvailable', {
                        current: device?.payload?.currentFirmwareVersion,
                        available: device?.payload?.newestFirmwareVersion,
                      })
                    : device?.payload?.currentFirmwareVersion}
                </p>
                <button className="button" disabled={!isUpdateAvailable || updateDeviceLoading} onClick={updateDevice}>
                  <IconAutomatic className="update-icon" />
                  {!updateDeviceLoading
                    ? isUpdateAvailable
                      ? t('updateSoftware.update')
                      : t('updateSoftware.updatedHeader')
                    : tc('buttons.loading')}
                </button>
              </>
            ) : (
              <CustomBackdrop loading />
            )}
          </>
        </>
      )}
      <SubmitButton disabled={!device} onClick={nextStep}>
        {tc('buttons.next')}
      </SubmitButton>
      <CustomBackdrop loading={!device} />
    </Page>
  );
};

export default UpdateSoftware;
