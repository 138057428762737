import { useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import {
  InstallationAccessType,
  Query,
  ShareMutation,
  ShareMutationVariables,
  UserGroup,
  SharingItemType,
  CancelInvitationMutation,
  CancelInvitationMutationVariables,
  SharingInfosByInstallationQueryVariables,
  SharedItemDtoInput,
  ShareInfoModificationType,
  ShareInfo,
} from '../../../data-access/gql-types/graphql';
import { CANCEL_INVITATION } from '../../../data-access/mutations/invitation';
import { SHARE } from '../../../data-access/mutations/share';
import { SHARING_INFOS_BY_INSTALLATION } from '../../../data-access/queries/share';
import { useApi, useBackdropContext, useDevicesAndChannels, useInstallation } from '../../../hooks';
import { useInvitationErrors } from '../../../hooks/backend-errors/use-invitation-errors';
import { ROUTES } from '../../../routes';
import { SharedItemType, SharingType } from '../../../types';
import { useShareInstallationContext } from '../context';
import * as types from '../context/constants';
import { Member, ShareChannel, ShareGroup, ShareItem } from '../context/types';
import { getSharedItemType, prepareShareDateTime } from '../utils';

interface HookParameters {
  ignoreChecking?: boolean;
  fetchGroups?: boolean;
  fetchChannels?: boolean;
}

export const useInstallationShare = (params?: HookParameters) => {
  const history = useHistory();
  const { handleErrors } = useInvitationErrors();
  const { installationId } = useParams<{ installationId?: string }>();
  const { state, dispatch } = useShareInstallationContext();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { selectedInstallation } = useInstallation();
  const { groups: groupsData, dashboardLoading, channelsLoading } = useApi();
  const { channelList } = useDevicesAndChannels();

  const { data: sharingInfos, loading: sharingInfosloading } = useQuery<
    Query,
    SharingInfosByInstallationQueryVariables
  >(SHARING_INFOS_BY_INSTALLATION, {
    variables: { installationId: state.installationId },
    fetchPolicy: 'no-cache',
    skip: !state.installationId,
  });

  useEffect(() => {
    if (installationId) handleSetShareInstallationId(installationId);
  }, [installationId]);

  const [shareItem, { loading: shareLoading }] = useMutation<ShareMutation, ShareMutationVariables>(SHARE);
  const [invitationCancel, { loading: cancelInvLoading }] = useMutation<
    CancelInvitationMutation,
    CancelInvitationMutationVariables
  >(CANCEL_INVITATION);

  useEffect(() => {
    if (!state.installationId && !params?.ignoreChecking) {
      history.replace(ROUTES.Installation());
    }
  }, []);

  const groups = useMemo(
    () =>
      groupsData
        .filter((x) => x.group.isPublicGroup)
        .map((group: UserGroup) => ({
          id: group.id,
          name: group.group.name,
        })),
    [groupsData],
  ) as ShareGroup[];

  const members = useMemo(() => {
    if (sharingInfos) {
      return (
        (sharingInfos.sharingInfosByInstallation.shareInfos as ShareInfo[]).map((shareInfo: ShareInfo) => {
          if (shareInfo.sharedToUser) {
            const { sharedToUser, installation, sharedToUserId } = shareInfo;

            return {
              id: sharedToUserId,
              firstName: sharedToUser.firstName,
              email: sharedToUser.email,
              image: sharedToUser.profileImage?.imageUrl || '',
              accessType: installation.accessType,
              sharedItemType: getSharedItemType(shareInfo),
            };
          }
        }) || []
      );
    }

    return [];
  }, [sharingInfos]) as Member[];

  const getShareItems = () => {
    switch (state.subject) {
      case SharedItemType.Channel:
        return state.channels.map((channel) => ({
          id: channel.id,
          name: channel.name,
          itemType: SharingItemType.Channel,
          ...(state.access === SharingType.Quantitative ? { grantedUses: state.quantity } : {}),
          ...(state.access === SharingType.Timed
            ? {
                sharedFrom: prepareShareDateTime(state.dateStart, state.timeStart),
              }
            : {}),
          ...(state.access === SharingType.Timed
            ? {
                sharedUntil: prepareShareDateTime(state.dateEnd, state.timeEnd),
              }
            : {}),
        })) as SharedItemDtoInput[];
      case SharedItemType.Group:
        return state.groups.map((group) => ({
          id: group.id,
          name: group.name,
          itemType: SharingItemType.Group,
          ...(state.access === SharingType.Quantitative ? { grantedUses: state.quantity } : {}),
          ...(state.access === SharingType.Timed
            ? {
                sharedFrom: prepareShareDateTime(state.dateStart, state.timeStart),
              }
            : {}),
          ...(state.access === SharingType.Timed
            ? {
                sharedUntil: prepareShareDateTime(state.dateEnd, state.timeEnd),
              }
            : {}),
        })) as SharedItemDtoInput[];
      default:
        return [];
    }
  };

  const share = (callback: () => void) => {
    turnOnBackdrop();

    shareItem({
      variables: {
        input: {
          invitedUserEmail: state.email,
          installationId: state.installationId,
          accessType: state.permission,
          wholeInstallationShare: state.subject === SharedItemType.Installation,
          ...(state.access === SharingType.Timed
            ? {
                sharedFrom: prepareShareDateTime(state.dateStart, state.timeStart),
              }
            : {}),
          ...(state.access === SharingType.Timed
            ? {
                sharedUntil: prepareShareDateTime(state.dateEnd, state.timeEnd),
              }
            : {}),
          sharedItems: getShareItems(),
        },
      },
      onCompleted: (data) => {
        turnOffBackdrop();
        if (data.share.result?.ok) {
          callback();
        } else {
          handleErrors(data.share.errors || []);
        }
      },
      onError: () => turnOffBackdrop(),
    });
  };

  const cancel = (invitationId: string, callback: () => void) => {
    turnOnBackdrop();

    invitationCancel({
      variables: {
        input: {
          invitationId,
        },
      },
      onCompleted: (data) => {
        turnOffBackdrop();

        if (data.cancelInvitation.result?.ok) {
          callback();
        } else {
          handleErrors(data.cancelInvitation.errors || []);
        }
      },
      onError: () => turnOffBackdrop(),
    });
  };

  const handleSetShareInstallationId = (installationId: string) => {
    dispatch({ type: types.SET_SHARE_INSTALLATION_ID, payload: installationId });
  };

  const handleSetShareEmail = (email: string) => {
    dispatch({ type: types.SET_SHARE_EMAIL, payload: email });
  };

  const handleSetUserId = (userId: string) => {
    dispatch({ type: types.SET_USER_ID, payload: userId });
  };

  const handleSetShareSubject = (subject: SharedItemType) => {
    dispatch({ type: types.SET_SHARE_SUBJECT, payload: subject });
  };

  const handleSetSharePermission = (permission: InstallationAccessType) => {
    dispatch({ type: types.SET_SHARE_PERMISSION, payload: permission });
  };

  const handleSetShareAccess = (access: SharingType) => {
    dispatch({ type: types.SET_SHARE_RESTRICTION_ACCESS, payload: access });
  };

  const handleSetShareQuantity = (quantity: number) => {
    dispatch({ type: types.SET_SHARE_QUANTITY, payload: quantity });
  };

  const handleUpdateGroups = (groups: ShareGroup[]) => {
    dispatch({ type: types.UPDATE_SHARE_GROUPS, payload: groups });
  };

  const handleUpdateChannels = (channels: ShareChannel[]) => {
    dispatch({ type: types.UPDATE_SHARE_CHANNELS, payload: channels });
  };

  const handleSetShareTimeStart = (timeStart: string) => {
    dispatch({ type: types.SET_SHARE_TIME_START, payload: timeStart });
  };

  const handleSetShareTimeEnd = (timeEnd: string) => {
    dispatch({ type: types.SET_SHARE_TIME_END, payload: timeEnd });
  };

  const handleSetShareDateStart = (dateStart: Date) => {
    dispatch({ type: types.SET_SHARE_DATE_START, payload: dateStart });
  };

  const handleSetShareDateEnd = (dateEnd: Date) => {
    dispatch({ type: types.SET_SHARE_DATE_END, payload: dateEnd });
  };

  const handleSetShareItem = (item: ShareItem) => {
    dispatch({ type: types.SET_SHARE_ITEM, payload: item });
  };

  const handleSetInvitationDate = (date: string) => {
    dispatch({ type: types.SET_SHARE_INVITATION_DATE, payload: date });
  };

  const handleSetModificationType = (shareInfoModificationType: ShareInfoModificationType) => {
    dispatch({ type: types.SET_MODIFICATION_TYPE, payload: shareInfoModificationType });
  };

  const handleSetEditedId = (editedId: string) => {
    dispatch({ type: types.SET_EDITED_ID, payload: editedId });
  };

  const handleResetShareInstallation = () => {
    dispatch({ type: types.RESET_SHARE_INSTALLATION });
  };

  const hasInstallation = useMemo(() => {
    if (sharingInfos && state.email) {
      return !!(sharingInfos.sharingInfosByInstallation.shareInfos as ShareInfo[]).find(
        (x) => x.sharedToUser?.email === state.email,
      );
    }

    return false;
  }, [sharingInfos, state.email]);

  return {
    state,
    groups,
    groupsLoading: dashboardLoading,
    channelList,
    channelsLoading,
    members,
    sharingInfosloading,
    shareInfos: sharingInfos?.sharingInfosByInstallation.shareInfos || [],
    installationOwner: sharingInfos?.sharingInfosByInstallation.ownerInfo.owner || undefined,
    shareLoading,
    cancelInvLoading,
    hasInstallation,
    installationName: selectedInstallation?.name || '',
    methods: {
      share,
      cancel,
      handleSetShareInstallationId,
      handleSetShareEmail,
      handleSetUserId,
      handleSetShareSubject,
      handleSetSharePermission,
      handleSetShareAccess,
      handleSetShareQuantity,
      handleUpdateGroups,
      handleUpdateChannels,
      handleSetShareTimeStart,
      handleSetShareTimeEnd,
      handleSetShareDateStart,
      handleSetShareDateEnd,
      handleResetShareInstallation,
      handleSetInvitationDate,
      handleSetShareItem,
      handleSetEditedId,
      handleSetModificationType,
    },
  };
};
