import React from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { CustomBackdrop, IntegrationExalus } from './components';
import { ExalusBackdropPopup } from './components/exalus-backdrop-popup';
import GlobalProviders from './providers';
import { AppRoute, UnauthenticatedRouter } from './routes';
import './styles/main.scss';

declare global {
  interface Window {
    configuration: Record<string, string>;
    loglevel: number;
    chrome: any;
    opr?: any;
  }
}

export const QRScannerSecured = new URLSearchParams(window.location.search).get('qr-scanner');

const DefaultRouterApp: React.FC = () => (
  <GlobalProviders>
    <AppRoute />
    <CustomBackdrop />
    <ExalusBackdropPopup />
  </GlobalProviders>
);

export const AppLocal: React.FC = () => {
  if (QRScannerSecured) return <IntegrationExalus />;

  return <DefaultRouterApp />;
};

export const App: React.FC = () => {
  const { keycloak } = useKeycloak();

  if (QRScannerSecured) return <IntegrationExalus />;

  return <>{keycloak.authenticated ? <DefaultRouterApp /> : <UnauthenticatedRouter />}</>;
};
