import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { range } from 'lodash';
import { sum } from 'lodash';
import { useLazyQuery } from '@apollo/client';
import { PHASES_COLORS } from '../../../../../const';
import {
  AggregateVectorParameterTypeInternal,
  MeasurementDataRangeInternal,
  MeterAggregatedMeasurementsQueryVariables,
  Query,
} from '../../../../../data-access/gql-types/graphql';
import { METER_AGGREGATED_MEASUREMENTS } from '../../../../../data-access/queries/meter';
import { useInstallation } from '../../../../../hooks';
import { MeasurementRange } from '../../../../../types';
import { SelectedPhase } from '../../../measurement/types';
import { ChartDataItem } from '../index';
import { AnalysisHook, AnalysisHookParams } from '../types';

export const useMeterAnalysis = ({ channel, measurementChannel }: AnalysisHookParams): AnalysisHook => {
  const { t } = useTranslation('channel-details');
  const { selectedInstallationId } = useInstallation();
  const [selectedPhases, setSelectedPhases] = useState<SelectedPhase[]>();
  const activeDay = new Date();
  const [activeAggregatedParameter, setActiveAggregatedParameter] = useState<AggregateVectorParameterTypeInternal>(
    AggregateVectorParameterTypeInternal.ForwardActiveEnergy,
  );
  const [activeMeasurementRange, setActiveMeasurementRange] = useState<MeasurementRange>(MeasurementRange.DayInHours);

  const [getAggregatedMeasurements, { data, loading }] = useLazyQuery<Query, MeterAggregatedMeasurementsQueryVariables>(
    METER_AGGREGATED_MEASUREMENTS,
  );

  const options = useMemo(
    () => [
      {
        label: t('selectOptions.totalForwardActiveEnergy'),
        value: AggregateVectorParameterTypeInternal.ForwardActiveEnergy,
      },
      {
        label: t('selectOptions.totalReverseActiveEnergy'),
        value: AggregateVectorParameterTypeInternal.ReverseActiveEnergy,
      },
      {
        label: t('selectOptions.totalForwardReactiveEnergy'),
        value: AggregateVectorParameterTypeInternal.ForwardReactiveEnergy,
      },
      {
        label: t('selectOptions.totalReverseReactiveEnergy'),
        value: AggregateVectorParameterTypeInternal.ReverseReactiveEnergy,
      },
    ],
    [],
  );

  const relativeActiveMeasurementRange = useMemo(() => {
    if (
      activeMeasurementRange === MeasurementRange.WeekInDays ||
      activeMeasurementRange === MeasurementRange.MonthInDays
    ) {
      return MeasurementDataRangeInternal.CurrentMonthInDays;
    } else if (activeMeasurementRange === MeasurementRange.DayInHours) {
      return MeasurementDataRangeInternal.CurrentDayInHours;
    } else if (activeMeasurementRange === MeasurementRange.YearInMonths) {
      return MeasurementDataRangeInternal.CurrentYearInMonths;
    }
  }, [activeMeasurementRange]);

  useEffect(() => {
    getAggregatedMeasurements({
      variables: {
        installationId: selectedInstallationId,
        input: {
          channelId: measurementChannel?.meterChannelId || channel.id,
          deviceId: channel.deviceId,
          indices: measurementChannel ? [measurementChannel.phase] : [],
          aggregateVectorRequestItems: [
            {
              type: activeAggregatedParameter,
              range: relativeActiveMeasurementRange || MeasurementDataRangeInternal.None,
            },
          ],
        },
      },
    });
  }, [activeAggregatedParameter, relativeActiveMeasurementRange]);

  const filterMeasurementsBySelectedPhases = useMemo(() => {
    return data?.meterAggregatedMeasurements.data?.aggregateVectorMeasurements.filter((phaseMeasurement) =>
      selectedPhases?.map((phase) => phase.value).includes(phaseMeasurement.index),
    );
  }, [selectedPhases, data?.meterAggregatedMeasurements]);

  const mappedChartData: ChartDataItem[] = useMemo(() => {
    if (selectedPhases?.length && selectedPhases[0].value === 0) {
      const phaseMeasurements = data?.meterAggregatedMeasurements.data?.aggregateVectorMeasurements;

      if (phaseMeasurements?.length) {
        const length = phaseMeasurements[0].values.length;
        return (
          range(length)?.map((phaseValue, index) => {
            const summedMeasurements = sum(
              phaseMeasurements?.map((phaseMeasurement) => phaseMeasurement.values[index]),
            );
            return {
              values: [
                {
                  type: 0,
                  value: summedMeasurements,
                  color: PHASES_COLORS[0],
                },
              ],
            };
          }) ?? []
        );
      }
    } else {
      if (filterMeasurementsBySelectedPhases?.length) {
        const length = filterMeasurementsBySelectedPhases[0].values.length;
        return (
          range(length)?.map((phaseValue, index) => {
            return {
              values: filterMeasurementsBySelectedPhases?.map((phaseMeasurement) => {
                return {
                  type: phaseMeasurement.index,
                  value: phaseMeasurement.values[index],
                  color: PHASES_COLORS[phaseMeasurement.index],
                };
              }),
            };
          }) ?? []
        );
      }
    }

    return [];
  }, [activeMeasurementRange, activeDay, selectedPhases, data?.meterAggregatedMeasurements]);

  const mappedSummaryData: ChartDataItem[] = useMemo(() => {
    if (selectedPhases?.length && selectedPhases[0].value === 0) {
      const phaseMeasurements = data?.meterAggregatedMeasurements.data?.aggregateVectorMeasurements;

      if (phaseMeasurements?.length) {
        const length = phaseMeasurements[0].values.length;
        return (
          range(length)?.map((phaseValue, index) => {
            const summedMeasurements = sum(
              phaseMeasurements?.map((phaseMeasurement) => phaseMeasurement.values[index]),
            );
            return {
              values: [
                {
                  type: 0,
                  value: summedMeasurements,
                  color: PHASES_COLORS[0],
                },
                ...phaseMeasurements.map((phaseMeasurement) => {
                  return {
                    type: phaseMeasurement.index,
                    value: phaseMeasurement.values[index],
                    color: PHASES_COLORS[phaseMeasurement.index],
                  };
                }),
              ],
            };
          }) ?? []
        );
      }
    } else {
      if (filterMeasurementsBySelectedPhases?.length) {
        const length = filterMeasurementsBySelectedPhases[0].values.length;
        return (
          range(length)?.map((phaseValue, index) => {
            return {
              values: filterMeasurementsBySelectedPhases?.map((phaseMeasurement) => {
                return {
                  type: phaseMeasurement.index,
                  value: phaseMeasurement.values[index],
                  color: PHASES_COLORS[phaseMeasurement.index],
                };
              }),
            };
          }) ?? []
        );
      }
    }

    return [];
  }, [activeMeasurementRange, activeDay, selectedPhases, data?.meterAggregatedMeasurements]);

  const onClickActiveMeasurementRange = useCallback((value: MeasurementRange) => {
    setActiveMeasurementRange(value);
  }, []);

  const handleOnChange = useCallback((value: string) => {
    setActiveAggregatedParameter(value as AggregateVectorParameterTypeInternal);
  }, []);

  return {
    data: data?.meterAggregatedMeasurements?.data,
    isLoading: loading,
    selectedPhases,
    setSelectedPhases,
    mappedChartData,
    mappedSummaryData,
    onClickActiveMeasurementRange,
    handleOnChange,
    activeDay,
    activeAggregatedParameter,
    activeMeasurementRange,
    options,
  };
};
