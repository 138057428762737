import {
  OptimizerScalarParameterTypeInternal,
  OptimizerVectorParameterTypeInternal,
  ScalarParameterTypeInternal,
  VectorParameterTypeInternal,
} from '../../../../data-access/gql-types/graphql';
import { MeasurementRange } from '../../../../types';
import { ChartDataItem } from '../../meter/analysis';

export const conditionallySliceArray = (data: ChartDataItem[], activeMeasurementRange: MeasurementRange) => {
  if (activeMeasurementRange === MeasurementRange.MonthInDays) {
    return data.slice(0, new Date().getDate());
  } else if (activeMeasurementRange === MeasurementRange.WeekInDays) {
    const day = new Date().getDate();
    if (day > 7) {
      return data.slice(new Date().getDate() - 7, new Date().getDate());
    }
    return data.slice(0, new Date().getDate());
  }
  return data;
};

export const filteredSummaryMeasurements = [
  ScalarParameterTypeInternal.Frequence,
  ScalarParameterTypeInternal.TotalArithmeticForwardActiveEnergy,
  ScalarParameterTypeInternal.TotalArithmeticReverseActiveEnergy,
  OptimizerScalarParameterTypeInternal.Temperature,
];
export const filteredPhasesSumMeasurements = [
  // VectorParameterTypeInternal.Voltage,
  VectorParameterTypeInternal.Current,
  VectorParameterTypeInternal.ActivePower,
  VectorParameterTypeInternal.ReactivePower,
  VectorParameterTypeInternal.ApparentPower,
  VectorParameterTypeInternal.ForwardActiveEnergy,
  VectorParameterTypeInternal.ReverseActiveEnergy,
  VectorParameterTypeInternal.ForwardReactiveEnergy,
  VectorParameterTypeInternal.ReverseReactiveEnergy,
];

export const filteredPhasesSumMeasurementsOptimizer = [
  // OptimizerVectorParameterTypeInternal.Voltage,
  OptimizerVectorParameterTypeInternal.Current,
  OptimizerVectorParameterTypeInternal.ActivePower,
  OptimizerVectorParameterTypeInternal.ScrFilling,
];

export const averageSummaryMeasurements = [
  VectorParameterTypeInternal.Voltage,
  OptimizerVectorParameterTypeInternal.Voltage,
  OptimizerVectorParameterTypeInternal.ScrFilling,
  OptimizerScalarParameterTypeInternal.Temperature,
];

export const measurementParameters = {
  [VectorParameterTypeInternal.Unknown]: {
    unit: '',
    symbol: '',
    fixed: 1,
  },
  [VectorParameterTypeInternal.Voltage]: {
    unit: 'V',
    symbol: 'U',
    fixed: 1,
  },
  [VectorParameterTypeInternal.Current]: {
    unit: 'A',
    symbol: 'I',
    fixed: 2,
  },
  [VectorParameterTypeInternal.ActivePower]: {
    unit: 'W',
    symbol: 'P',
    fixed: 3,
  },
  [VectorParameterTypeInternal.ReactivePower]: {
    unit: 'var',
    symbol: 'Q',
    fixed: 3,
  },
  [VectorParameterTypeInternal.ApparentPower]: {
    unit: 'VA',
    symbol: 'S',
    fixed: 3,
  },
  [VectorParameterTypeInternal.PhaseAngle]: {
    symbol: 'fi',
    unit: '°',
    fixed: 1,
  },
  [VectorParameterTypeInternal.PowerFactor]: {
    shortcut: 'PF',
    symbol: 'cos (∅)',
    unit: '',
    fixed: 2,
  },
  [VectorParameterTypeInternal.ForwardActiveEnergy]: {
    unit: 'kWh',
    symbol: 'E',
    fixed: 3,
  },
  [VectorParameterTypeInternal.ReverseActiveEnergy]: {
    unit: 'kWh',
    symbol: 'E',
    fixed: 3,
  },
  [VectorParameterTypeInternal.ForwardReactiveEnergy]: {
    unit: 'kvarh',
    symbol: 'J',
    fixed: 3,
  },
  [VectorParameterTypeInternal.ReverseReactiveEnergy]: {
    unit: 'kvarh',
    symbol: 'J',
    fixed: 3,
  },

  [ScalarParameterTypeInternal.TotalArithmeticForwardActiveEnergy]: {
    unit: 'kWh',
    symbol: '',
    fixed: 3,
  },
  [ScalarParameterTypeInternal.TotalArithmeticReverseActiveEnergy]: {
    unit: 'kWh',
    symbol: '',
    fixed: 3,
  },
  [ScalarParameterTypeInternal.MinActivePower]: {
    unit: 'W',
    symbol: 'P',
    fixed: 3,
  },
  [ScalarParameterTypeInternal.MaxActivePower]: {
    unit: 'W',
    symbol: 'P',
    fixed: 3,
  },
  [ScalarParameterTypeInternal.Frequence]: {
    unit: 'Hz',
    symbol: 'f',
    fixed: 2,
  },
};

export const measurementParametersOptimizer = {
  [OptimizerVectorParameterTypeInternal.Unknown]: {
    unit: '',
    symbol: '',
    fixed: 1,
    dataMax: 1,
  },
  [OptimizerVectorParameterTypeInternal.Voltage]: {
    unit: 'V',
    symbol: 'U',
    fixed: 1,
    dataMax: 300,
  },
  [OptimizerVectorParameterTypeInternal.Current]: {
    unit: 'A',
    symbol: 'I',
    fixed: 2,
    dataMax: 30,
  },
  [OptimizerVectorParameterTypeInternal.ActivePower]: {
    unit: 'kW',
    symbol: 'P',
    fixed: 1,
    dataMax: 30,
  },
  [OptimizerVectorParameterTypeInternal.ScrFilling]: {
    unit: '%',
    symbol: '%',
    fixed: 0,
    dataMax: 100,
  },
  [OptimizerVectorParameterTypeInternal.RelayState]: {
    unit: '',
    symbol: '',
    fixed: 0,
    dataMax: 1,
  },
  [OptimizerScalarParameterTypeInternal.Temperature]: {
    unit: '°C',
    symbol: 't',
    fixed: 1,
    dataMax: 100,
  },
};
