import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import {
  MeasurementChannelKind,
  MeasurementMap,
  MeasurementMapQueryVariables,
  OptimizerScalarParameterTypeInternal,
  OptimizerVectorParameterTypeInternal,
  Query,
  ScalarParameterTypeInternal,
  VectorParameterTypeInternal,
} from '../../data-access/gql-types/graphql';
import { MEASUREMENT_MAP } from '../../data-access/queries/meter';
import { toastError } from '../../utils/toast';
import { useInstallation } from '../installation';

interface HookInterface {
  measurementMap: MeasurementMap | null;
  convertNumberToMeasurement: (
    kind: MeasurementChannelKind,
  ) =>
    | ((parameter: 'scalars' | 'vectors', value: number) => ScalarParameterTypeInternal | VectorParameterTypeInternal)
    | ((
        parameter: 'scalars' | 'vectors',
        value: number,
      ) => OptimizerScalarParameterTypeInternal | OptimizerVectorParameterTypeInternal);
  convertMeasurementToNumber: (
    kind: MeasurementChannelKind,
  ) =>
    | ((parameter: 'scalars' | 'vectors', type: ScalarParameterTypeInternal | VectorParameterTypeInternal) => number)
    | ((
        parameter: 'scalars' | 'vectors',
        type: OptimizerScalarParameterTypeInternal | OptimizerVectorParameterTypeInternal,
      ) => number);
}

export default function (): HookInterface {
  const { t: tc } = useTranslation('common');
  const { skipLavvaFetch } = useInstallation();
  const [measurementMap, setMeasurementMap] = useState<MeasurementMap | null>(null);

  const measurementMapData = useQuery<Query, MeasurementMapQueryVariables>(MEASUREMENT_MAP, {
    fetchPolicy: 'no-cache',
    skip: skipLavvaFetch,
    onError: () => toastError({ content: tc('errors.downloadData') }),
  });

  useEffect(() => {
    if (!measurementMapData.data) return;
    setMeasurementMap(measurementMapData.data.measurementMap);
  }, [measurementMapData.data]);

  const convertMeterMeasurementToNumber = (
    parameter: 'scalars' | 'vectors',
    type: ScalarParameterTypeInternal | VectorParameterTypeInternal,
  ) => {
    if (parameter === 'scalars') return measurementMap?.meter.scalars.find((x) => x.type === type)?.value || 0;
    return measurementMap?.meter.vectors.find((x) => x.type === type)?.value || 0;
  };

  const convertOptimizerMeasurementToNumber = (
    parameter: 'scalars' | 'vectors',
    type: OptimizerScalarParameterTypeInternal | OptimizerVectorParameterTypeInternal,
  ) => {
    if (parameter === 'scalars') return measurementMap?.optimizer.scalars.find((x) => x.type === type)?.value || 0;
    return measurementMap?.optimizer.vectors.find((x) => x.type === type)?.value || 0;
  };

  const convertMeterNumberToMeasurement = (parameter: 'scalars' | 'vectors', value: number) => {
    if (parameter === 'scalars')
      return (
        measurementMap?.meter.scalars.find((x) => x.value.toString() === value.toString())?.type ||
        ScalarParameterTypeInternal.Unknown
      );
    return (
      measurementMap?.meter.vectors.find((x) => x.value.toString() === value.toString())?.type ||
      VectorParameterTypeInternal.Unknown
    );
  };

  const convertOptimizerNumberToMeasurement = (parameter: 'scalars' | 'vectors', value: number) => {
    if (parameter === 'scalars')
      return (
        measurementMap?.optimizer.scalars.find((x) => x.value.toString() === value.toString())?.type ||
        OptimizerScalarParameterTypeInternal.Unknown
      );

    return (
      measurementMap?.optimizer.vectors.find((x) => x.value.toString() === value.toString())?.type ||
      OptimizerVectorParameterTypeInternal.Unknown
    );
  };

  const convertNumberToMeasurement = (kind: MeasurementChannelKind) => {
    return kind === MeasurementChannelKind.Meter
      ? convertMeterNumberToMeasurement
      : convertOptimizerNumberToMeasurement;
  };

  const convertMeasurementToNumber = (kind: MeasurementChannelKind) => {
    return kind === MeasurementChannelKind.Meter
      ? convertMeterMeasurementToNumber
      : convertOptimizerMeasurementToNumber;
  };

  return {
    measurementMap,
    convertMeasurementToNumber,
    convertNumberToMeasurement,
  };
}
