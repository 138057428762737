import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useBlindSwapOut } from '../../../../api/modules/blind/blind.hooks';
import { ChannelBox, CustomBackdrop, Header, NavHeader, Page, SubmitButton } from '../../../../components';
import { useBackdropContext, useDevicesAndChannels } from '../../../../hooks';
import { ROUTES } from '../../../../routes';
import { toastSuccess } from '../../../../utils/toast';
import './index.scss';

const ReverseOutputs: React.FC = () => {
  const history = useHistory();
  const { channelId } = useParams<{ channelId: string }>();
  const { t } = useTranslation('device-add');
  const { t: tc } = useTranslation('common');
  const { t: td } = useTranslation('channel-settings');
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { channel } = useDevicesAndChannels({ channelId });
  const swapOutBlind = useBlindSwapOut();

  const swapOut = () => {
    if (channel) {
      turnOnBackdrop();
      swapOutBlind.mutate(
        { deviceId: channel.deviceId, channelId: channel.id },
        {
          onSuccess: () => {
            toastSuccess({ content: `${td('toast.swapOutSuccess')}` });
            turnOffBackdrop();
          },
          onError: () => {
            turnOffBackdrop();
          },
        },
      );
    }
  };

  const nextStep = () => {
    history.push(ROUTES.ReverseInputs(channel?.deviceId));
  };

  return (
    <Page
      className="reverse-outputs"
      header={
        <>
          <NavHeader />
          <Header title={t('reverseOutputs.header')} subtitle={t('reverseOutputs.message')} column />
        </>
      }
    >
      <div className="action-box-container">{channel && <ChannelBox channel={channel} isListItem />}</div>
      <hr />
      <div className="row_container">
        <button className="button button--secondary" onClick={swapOut}>
          {t('reverseOutputs.option')}
        </button>
      </div>
      <SubmitButton onClick={nextStep} disabled={!channel}>
        {tc('buttons.next')}
      </SubmitButton>
      <CustomBackdrop loading={!channel} />
    </Page>
  );
};

export default ReverseOutputs;
