import { useTranslation } from 'react-i18next';
import { useMutation, UseMutationResult } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useErrors } from '../../../hooks/use-errors';
import { useRestClient } from '../../clients/rest-client';
import { DeviceResponse } from '../device/device.types';
import { MeasurementsResetBody } from './meter.types';

export const useResetAggregateMeasurements = (): UseMutationResult<
  AxiosResponse<DeviceResponse>,
  AxiosError,
  MeasurementsResetBody
> => {
  const { t } = useTranslation('backend-validation');
  const restClient = useRestClient();
  const { handleRestErrors } = useErrors();

  return useMutation<AxiosResponse<DeviceResponse>, AxiosError, MeasurementsResetBody>(
    ['optimizer-reset-aggregate-measurements'],
    async (body) => await restClient.post<DeviceResponse>('/meter/resetAggregateMeasurements', body),
    {
      onError: (error: AxiosError) => {
        handleRestErrors(error, t('DEVICE_SYNC_REQUEST_TIMEOUT'));
      },
    },
  );
};
