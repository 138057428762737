import { PointerEvent } from 'react';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { FacadeActionEnum, SetFacadePosition } from 'lavva.exalushome/build/js/Services/Devices/Tasks/Tasks';
import { FacadeControlHook } from '../types';

export const useFacadeControl = (channel?: IDeviceChannel): FacadeControlHook => {
  const open = async () => {
    const task = new SetFacadePosition();
    task.FacadeAction = FacadeActionEnum.Open;
    await channel?.ExecuteTaskAsync(task);
  };

  const close = async () => {
    const task = new SetFacadePosition();
    task.FacadeAction = FacadeActionEnum.Close;
    await channel?.ExecuteTaskAsync(task);
  };

  const stop = async () => {
    const task = new SetFacadePosition();
    task.FacadeAction = FacadeActionEnum.Stop;
    await channel?.ExecuteTaskAsync(task);
  };

  const handleAction = async (action: FacadeActionEnum, value?: number) => {
    const task = new SetFacadePosition();
    task.FacadeAction = action;

    if (typeof value === 'number' && value >= 0 && value <= 100) {
      switch (action) {
        case FacadeActionEnum.Percentage:
          task.Position = value;
          break;
        case FacadeActionEnum.Tilt:
          task.Tilt = value;
          break;
      }
    }

    await channel?.ExecuteTaskAsync(task);
  };

  const handleSliderEvent = (event: PointerEvent<HTMLInputElement>) => {
    handleAction(FacadeActionEnum.Percentage, Number((event.target as HTMLInputElement).value));
  };

  const handleTiltEvent = (value: number) => {
    handleAction(FacadeActionEnum.Tilt, value);
  };

  return {
    open,
    close,
    stop,
    handleAction,
    handleSliderEvent,
    handleTiltEvent,
  };
};
