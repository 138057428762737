import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CoverButton, ControlWrapper, Slider, IconVent, IconChevron } from '../../../components';
import { IconBulb } from '../../../components/icons';
import { Action } from '../../../data-access/gql-types/graphql';
import { useGate } from '../../../hooks/use-gate';
import { ChannelInterface } from '../../../types';
import { toastInfo } from '../../../utils/toast';

interface PropsInterface {
  channels: ChannelInterface[];
  action: Action;
}
const ActionGate: React.FC<PropsInterface> = ({ channels, action }) => {
  const { t } = useTranslation('channel-details');
  const { t: tc } = useTranslation('common');
  const { handleAction } = useGate(channels, !!action);
  const [sliderValue, setSliderValue] = useState<number>(50);

  const onChange = useCallback(
    (value: string | number) => {
      setSliderValue(typeof value === 'number' ? value : value === 'close' ? 100 : 0);
      handleAction(value);
    },
    [setSliderValue, handleAction],
  );

  const handleSliderEvent = useCallback(
    (event: React.PointerEvent<HTMLInputElement>) => handleAction(Number((event.target as HTMLInputElement).value)),
    [handleAction],
  );

  return (
    <>
      <div className="cover-detail-view">
        <ControlWrapper>
          <Slider
            value={sliderValue}
            onPointerUp={handleSliderEvent}
            onChange={(e) => setSliderValue(Number(e.target.value))}
            wider
          />
        </ControlWrapper>
        <div className="cover-detail-view__controls p-l-24 p-r-24">
          <div className="cover-detail-view__controls-down" onClick={() => onChange('close')}>
            <IconChevron withCircle direction="down" />
          </div>
          <button className="cover-detail-view__controls-stop" onClick={() => onChange('stop')}>
            {tc('status.stop')}
          </button>
          <div className="cover-detail-view__controls-up" onClick={() => onChange('open')}>
            <IconChevron withCircle direction="up" />
          </div>
        </div>
      </div>
      <div className="favourite p-l-24 p-r-24">
        <div className="favourite__buttons favourite__buttons--gate">
          {[0, 1].map((index) => (
            <CoverButton
              key={index}
              lastPosition={sliderValue}
              position={0}
              onClick={() => toastInfo({ content: index === 0 ? 'ON' : 'OFF' })}
            >
              <IconBulb isOn={index === 0} />
              <span>{index === 0 ? 'ON' : 'OFF'}</span>
            </CoverButton>
          ))}
          <CoverButton lastPosition={sliderValue} position={99} onClick={() => onChange(99)}>
            <IconVent />
            <span>{t('microventilation')}</span>
          </CoverButton>
        </div>
      </div>
    </>
  );
};

export default ActionGate;
