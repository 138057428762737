import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ChartDataItem } from '../../../index';
import './index.scss';

interface PropsInterface {
  active: boolean;
  payload?: ChartDataItem;
  label: string;
}

export const Tooltip: React.FC<PropsInterface> = ({ payload, active, label }) => {
  const { t } = useTranslation('channel-details');

  return (
    <div className={classNames('tooltip', { 'tooltip--active': active })}>
      {label && <span className="tooltip__text tooltip__label">{label}</span>}
      {active && (
        <div className="tooltip__values">
          {payload?.values.map((el) => (
            <div key={el.type} className="tooltip__element">
              <span className="tooltip__color" style={{ backgroundColor: el.color }} />
              <span className="tooltip__text">
                {el.type ? `L${el.type}: ` : null}
                {el.value.toFixed(2)} {t('kwh')}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
