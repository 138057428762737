import { IntegrationDefaultEnum } from '../../../types';

interface Contact {
  phoneNumber: string;
  phoneLabel: string;
  email: string;
}

interface SupportData {
  websiteUrl: string;
  supportUrl: string;
  contacts: Contact[];
}

export const supportData: Record<IntegrationDefaultEnum, SupportData> = {
  [IntegrationDefaultEnum.Lavva]: {
    websiteUrl: 'https://zamel.com/',
    supportUrl: 'https://zamel.com/pl/wsparcie-techniczne',
    contacts: [
      {
        phoneNumber: '+48322104665',
        phoneLabel: '32 210 46 65',
        email: 'lavva@zamel.pl',
      },
    ],
  },
  [IntegrationDefaultEnum.Exalus]: {
    websiteUrl: 'https://www.tr7.pl/',
    supportUrl: 'https://www.tr7.pl/kontakt/',
    contacts: [
      {
        phoneNumber: '+48693156115',
        phoneLabel: '693 156 115',
        email: 'k.chojnacki@portosrolety.pl',
      },
      {
        phoneNumber: '+48603050262',
        phoneLabel: '603 050 262',
        email: 'm.chrzanowski@portosrolety.pl',
      },
    ],
  },
  [IntegrationDefaultEnum.Wisniowski]: {
    websiteUrl: 'https://zamel.com/',
    supportUrl: 'https://zamel.com/pl/wsparcie-techniczne',
    contacts: [
      {
        phoneNumber: '+48322104665',
        phoneLabel: '+48 32 210 46 65',
        email: 'lavva@zamel.pl',
      },
    ],
  },
};
