import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconLavva } from '../../../components';
import ArrowButton from '../../../components/arrow-button';
import { IntegrationType } from '../../../data-access/gql-types/graphql';
import { useInstallation } from '../../../hooks';

const Integration: React.FC = () => {
  const { t } = useTranslation('installation');
  const { selectedInstallation } = useInstallation();

  return (
    <>
      <hr className="m-t-24" />
      <div className="installation-settings__integration m-t-24">
        <h2>{t('integrations')}</h2>
        <div className="installation-settings__integration-list grid-list-24">
          <ArrowButton
            title={selectedInstallation?.integrationType}
            {...(selectedInstallation?.integrationType === IntegrationType.Lavva ? { icon: <IconLavva /> } : {})}
            arrowIcon={<></>}
          />
        </div>
      </div>
    </>
  );
};
export default Integration;
