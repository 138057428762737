import {
  AggregateVectorParameterTypeInternal,
  GetAggregateVectorMeasurementResponse,
  Maybe,
  MeasuredBy,
} from '../../../../../data-access/gql-types/graphql';
import { ChannelInterface, MeasurementRange } from '../../../../../types';
import { SelectedPhase } from '../../../measurement/types';
import { ChartDataItem } from '../index';

interface MeterAnalysisOption {
  label: string;
  value: AggregateVectorParameterTypeInternal;
}

export interface AnalysisHook {
  data: Maybe<GetAggregateVectorMeasurementResponse> | undefined;
  isLoading: boolean;
  selectedPhases: SelectedPhase[] | undefined;
  setSelectedPhases: (value: SelectedPhase[]) => void;
  mappedChartData: ChartDataItem[];
  mappedSummaryData: ChartDataItem[];
  onClickActiveMeasurementRange: (value: MeasurementRange) => void;
  handleOnChange: (value: string) => void;
  activeDay: Date;
  activeAggregatedParameter: AggregateVectorParameterTypeInternal;
  activeMeasurementRange: MeasurementRange;
  options: MeterAnalysisOption[];
}

export interface AnalysisHookParams {
  channel: ChannelInterface;
  measurementChannel?: MeasuredBy;
}

export enum AvailableShortFormatEnum {
  HOURLY = 'HH:mm',
  WEEKLY = 'EEEEEE',
  MONTHLY = 'dd.LL',
  YEARLY = 'LL',
}

export enum AvailableLongFormatEnum {
  HOURLY = 'HH:mm',
  WEEKLY = 'eeee, dd.MM',
  MONTHLY = 'dd.LL.yyyy',
  YEARLY = 'LLLL',
}
