import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { MeasurementRange } from '../../../types';
import './index.scss';

interface PropsInterface {
  activeMeasurementRange: MeasurementRange;
  setActiveMeasurementRange: (rangeValue: MeasurementRange) => void;
  disableMonth?: boolean;
  disableYear?: boolean;
}

export const TimeRanges: React.FC<PropsInterface> = ({
  activeMeasurementRange,
  setActiveMeasurementRange,
  disableMonth,
  disableYear,
}) => {
  const { t } = useTranslation('channel-details');

  const timeRanges = useMemo(
    () => [
      { value: MeasurementRange.DayInHours, label: `1 ${t('dateAbbreviation.day')}` },
      { value: MeasurementRange.WeekInDays, label: `1 ${t('dateAbbreviation.week')}` },
      ...(!disableMonth ? [{ value: MeasurementRange.MonthInDays, label: `1 ${t('dateAbbreviation.month')}` }] : []),
      ...(!disableYear ? [{ value: MeasurementRange.YearInMonths, label: `1 ${t('dateAbbreviation.year')}` }] : []),
    ],
    [t],
  );

  return (
    <div className="time-ranges">
      {timeRanges.map((element) => (
        <div
          key={element.label}
          onClick={() => setActiveMeasurementRange(element.value)}
          className={classNames('time-ranges__element', {
            'time-ranges__element--active': element.value === activeMeasurementRange,
          })}
        >
          {element.label}
        </div>
      ))}
    </div>
  );
};
