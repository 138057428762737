import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Status } from 'lavva.exalushome/build/js/DataFrame';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import { IUser } from 'lavva.exalushome/build/js/Services/Users/IUser';
import { User } from 'lavva.exalushome/build/js/Services/Users/User';
import { useBackdropContext } from '../../../../../hooks';
import { toastSuccess } from '../../../../../utils/toast';
import { useExalusServicesContext } from '../../../context/services';
import { useHandleDataFrameErrors } from '../../../hooks';
import { ChangePasswordForm, CreateEditUserForm } from '../types';

export const useUsers = () => {
  const history = useHistory();
  const { t } = useTranslation('profile');
  const { userId } = useParams<{ userId: string }>();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleError } = useHandleDataFrameErrors();
  const { usersApi } = useExalusServicesContext();

  const changePassword = async (values: ChangePasswordForm) => {
    turnOnBackdrop();

    const result: ResponseResult<Status> = await usersApi().ChangePasswordAsync(
      { Guid: userId } as IUser,
      values.Password,
    );

    if (result.Type === Status.OK) {
      toastSuccess({ content: t('exalus.passwordChangedSuccessfully') });
    } else {
      handleError(result.Type, result.Type === Status.WrongData ? t(`exalus.${result.Data}`) : '');
    }

    turnOffBackdrop();
  };

  const deleteUser = async (id: string, onSuccess: Dispatch<SetStateAction<boolean>>) => {
    turnOnBackdrop();
    const result: Status = await usersApi().DeleteUserAsync(id);

    if (result === Status.OK) {
      toastSuccess({ content: t('exalus.deletedSuccessfully') });
      onSuccess(false);
      history.goBack();
    } else {
      handleError(result);
    }

    turnOffBackdrop();
  };

  const createOrEditUser = async (values: CreateEditUserForm, image?: string) => {
    turnOnBackdrop();

    const newUser = new User();
    newUser.Name = values.Name;
    newUser.Surname = values.Surname;
    newUser.Email = values.Email;
    newUser.Gender = values.Gender;
    newUser.AccessLevel = values.AccessLevel;
    if (values.Phone) newUser.Phone = values.Phone;
    if (userId) newUser.Guid = userId;

    const result: ResponseResult<Status> = !userId
      ? await usersApi().CreateUserAsync(newUser, values.Password)
      : await usersApi().UpdateUserAsync(newUser);

    if (result.Type === Status.OK) {
      if (image) {
        const resultImage: ResponseResult<Status> = await usersApi().SetUserProfilePictureAsync(newUser, image);

        if (resultImage.Type === Status.OK) {
          toastSuccess({ content: t(`exalus.${userId ? 'edited' : 'created'}Successfully`) });
          history.goBack();
        } else {
          handleError(resultImage.Type, resultImage.Type === Status.WrongData ? t(`exalus.${result.Data}`) : '');
        }
      } else {
        toastSuccess({ content: t(`exalus.${userId ? 'edited' : 'created'}Successfully`) });
        history.goBack();
      }
    } else {
      handleError(result.Type, result.Type === Status.WrongData ? t(`exalus.${result.Data}`) : '');
    }

    turnOffBackdrop();
  };

  return {
    changePassword,
    deleteUser,
    createOrEditUser,
  };
};
