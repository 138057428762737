import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Status } from 'lavva.exalushome/build/js/DataFrame';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import * as dndCore from '@dnd-kit/core';
import * as dndSortable from '@dnd-kit/sortable';
import { DragAndDrop, SubmitButton, dragOverlayStyle } from '../../../../../components';
import { useBackdropContext } from '../../../../../hooks';
import { toastSuccess } from '../../../../../utils/toast';
import { ChannelBox } from '../../../components/channel/box';
import { useExalusServicesContext } from '../../../context/services';
import { useExalusStorageContext } from '../../../context/storage';
import { useHandleDataFrameErrors } from '../../../hooks';
import { GroupChannelsSortable } from '../types';
import { ChannelItemSortable } from './item-sortable';

interface PropsInterface {
  sortableChannels: GroupChannelsSortable[];
  setSortableChannels: (value: GroupChannelsSortable[]) => void;
}

export const ChannelsSortBody: React.FC<PropsInterface> = ({ sortableChannels, setSortableChannels }) => {
  const { t } = useTranslation('groups');
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { groupsApi } = useExalusServicesContext();
  const { isDashboardGridView } = useExalusStorageContext();
  const [dndActiveId, setDndActiveId] = useState<string | null>(null);
  const { handleError } = useHandleDataFrameErrors();

  const onDragStart = useCallback((event: dndCore.DragStartEvent) => setDndActiveId(event.active.id), [setDndActiveId]);

  const onDragEnd = useCallback(
    (event: dndCore.DragEndEvent) => {
      if (!event.active || !event.over) return;

      if (event.active.id !== event.over.id && sortableChannels) {
        const newList = Array.from(sortableChannels);
        const oldIndex = newList.findIndex((channel: GroupChannelsSortable) => channel.id === event.active.id);
        const newIndex = newList.findIndex((channel: GroupChannelsSortable) => channel.id === event.over?.id);

        const sortedChannels = dndSortable.arrayMove(newList, oldIndex, newIndex);
        setSortableChannels(sortedChannels);
      }

      setDndActiveId(null);
    },
    [sortableChannels],
  );

  const onSave = useCallback(async () => {
    if (!sortableChannels || sortableChannels.length === 0) return;

    turnOnBackdrop();
    let sortedChannelList: IDeviceChannel[] = [];

    sortableChannels.map((x: GroupChannelsSortable) => {
      if (!x.groupChannels) sortedChannelList.push(x.channel);
      else {
        sortedChannelList = [...sortedChannelList, ...x.groupChannels];
      }
    });

    const result = await groupsApi().ChangeObjectsPositionsInGroupAsync(sortableChannels[0].group, sortedChannelList);

    if (result !== Status.OK) handleError(result);
    else toastSuccess({ content: t('changeChannelsOrderSuccess') });

    turnOffBackdrop();
  }, [sortableChannels]);

  const channelDragged = useMemo(
    () => sortableChannels.find((x) => x.id === dndActiveId)?.channel,
    [sortableChannels.length, dndActiveId],
  );

  return (
    <>
      <DragAndDrop onDragStart={onDragStart} onDragEnd={onDragEnd} items={sortableChannels}>
        {sortableChannels.map((channelSortable: GroupChannelsSortable) => (
          <ChannelItemSortable
            key={channelSortable.id}
            channelSortable={channelSortable}
            isListItem={!isDashboardGridView}
          />
        ))}
        <dndCore.DragOverlay>
          {channelDragged && (
            <ChannelBox
              channel={channelDragged}
              isListItem={!isDashboardGridView}
              style={dragOverlayStyle}
              isDraggable
            />
          )}
        </dndCore.DragOverlay>
      </DragAndDrop>
      <SubmitButton onClick={onSave} />
    </>
  );
};
