import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import {
  Carousel,
  CarouselItem,
  CustomBackdrop,
  EmptyStateBox,
  Header,
  IconDelete,
  IconWarning,
  NavHeader,
  Page,
  SelectInputController,
  SubmitButton,
  Tabs,
} from '../../../components';
import { ChannelTypeInternal } from '../../../data-access/gql-types/graphql';
import { GateAction } from './components/gate-action';
import { LightAction } from './components/light-action';
import { PositionAction } from './components/position-action';
import { SetOnTimeAction } from './components/set-on-time-action';
import { useDeviceBindings } from './hooks/use-device-bindings';
import { useSubmitBindings } from './hooks/use-submit-bindings';
import './index.scss';
import { ActionBindingType, DeviceBindingsForm } from './types';

const DeviceBindings: React.FC = () => {
  const { t } = useTranslation('device-settings');
  const {
    loading,
    channelOptions,
    inputOptions,
    inputTriggers,
    form,
    fields,
    channelType,
    actionSelectOptions,
    activeTabIndex,
    channels,
    addBindingItem,
    handleChangeIndex,
    handleDeleteBinding,
    handleChangeInput,
    handleChangeActionType,
  } = useDeviceBindings();
  const { handleSubmitBindings } = useSubmitBindings();
  const { bindings } = form.watch();

  const handleSubmit = (values: DeviceBindingsForm) => {
    handleSubmitBindings(values, channelType);
  };

  const additionalBindingsByType = (index: number) => {
    if (!bindings.length || !activeTabIndex.length) return null;
    const binding = bindings[index].list[activeTabIndex[index]];
    if (!binding.actionType || binding.actionType === ActionBindingType.Default) return null;

    switch (channelType) {
      case ChannelTypeInternal.Switch: {
        return binding.setOnTimeValue ? (
          <SetOnTimeAction
            value={binding.setOnTimeValue}
            bindingIndex={index}
            bindingListIndex={activeTabIndex[index]}
          />
        ) : null;
      }
      case ChannelTypeInternal.Blind: {
        return typeof binding.position === 'number' ? (
          <PositionAction value={binding.position} bindingIndex={index} bindingListIndex={activeTabIndex[index]} />
        ) : null;
      }
      case ChannelTypeInternal.Light: {
        return <LightAction bindingIndex={index} bindingListIndex={activeTabIndex[index]} channels={channels} />;
      }
      case ChannelTypeInternal.Gate: {
        return <GateAction bindingIndex={index} bindingListIndex={activeTabIndex[index]} />;
      }
      default: {
        return null;
      }
    }
  };

  const channelUsedValidation = (value: string) => {
    if (bindings.filter((x) => x.channelId === value).length > 1) return t('bindings.errors.theSameChannels');
  };

  const InputUsedValidation = (value: string) => {
    if (bindings.filter((x) => x.inputId === value).length > 1) return t('bindings.errors.theSameInputs');
  };

  return (
    <Page
      className="bindings"
      header={
        <>
          <NavHeader />
          <Header title={t('bindings.bindings')} />
        </>
      }
    >
      {inputOptions.length ? (
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            {fields.map((field, index) => (
              <div key={field.id}>
                <div className="grid-list-24">
                  <SelectInputController
                    name={`bindings.${index}.channelId`}
                    label={t('bindings.channel')}
                    options={channelOptions}
                    {...(channels.length > 1 ? { validation: channelUsedValidation } : {})}
                  />
                  <SelectInputController
                    name={`bindings.${index}.inputId`}
                    label={t('bindings.input')}
                    options={inputOptions}
                    handleChange={(inputId) => handleChangeInput(inputId, index)}
                    {...(channelType === ChannelTypeInternal.Blind ? { validation: InputUsedValidation } : {})}
                  />
                </div>
                {field.channelId && field.inputId && (
                  <>
                    <Tabs
                      tabList={[
                        ...field.list.map((_, i) => ({ label: (i + 1).toString() })),
                        ...(field.list.length < 2 ? [{ label: '+', onClick: () => addBindingItem(index) }] : []),
                      ]}
                      activeTabIndex={activeTabIndex[index]}
                      setActiveTabIndex={(tabIndex) => handleChangeIndex(tabIndex, index)}
                      tabsType="pills"
                      isDivider={false}
                    >
                      {field.list.length ? (
                        <div className="remove-section">
                          <IconButton onClick={() => handleDeleteBinding(index)}>
                            <IconDelete />
                            <div className="number">{activeTabIndex[index] + 1}</div>
                          </IconButton>
                        </div>
                      ) : null}
                    </Tabs>
                    <Carousel
                      setActiveIndex={(tabIndex) => handleChangeIndex(tabIndex, index)}
                      activeIndex={activeTabIndex[index]}
                      preventSwipeEvent
                    >
                      {field.list.map((_, i) => (
                        <CarouselItem key={i}>
                          <div className="grid-list-24">
                            <SelectInputController
                              name={`bindings.${index}.list.${i}.inputTrigger`}
                              label={t('bindings.inputTrigger')}
                              options={inputTriggers}
                            />
                            <SelectInputController
                              name={`bindings.${index}.list.${i}.actionType`}
                              label={t('bindings.action')}
                              options={actionSelectOptions}
                              handleChange={(actionType) => handleChangeActionType(actionType, index)}
                            />
                            {additionalBindingsByType(index)}
                          </div>
                        </CarouselItem>
                      ))}
                    </Carousel>
                  </>
                )}

                {index < fields.length - 1 && <hr className="m-b-24 m-t-24" />}
              </div>
            ))}
            <SubmitButton />
          </form>
        </FormProvider>
      ) : (
        <>{!loading && <EmptyStateBox content={t('bindings.noControlledInputs')} icon={<IconWarning />} />}</>
      )}
      <CustomBackdrop loading={loading} />
    </Page>
  );
};

export default DeviceBindings;
