import React, { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ControlWrapper, EmptyStateBox, IconWarning, Tabs } from '../../../../components';
import { WRONG_MEASUREMENT_VALUE } from '../../../../const';
import { OptimizerVectorParameterTypeInternal } from '../../../../data-access/gql-types/graphql';
import { measurementParametersOptimizer } from '../../measurement/utils';
import { Balance } from './components/balance';
import { Diagram } from './components/diagram';
import { DiagramSimple } from './components/diagram-simple';
import { EnergyDonut } from './components/energy-donut';
import './index.scss';
import { OptimizerCurrentDataMap } from './types';

interface PropsInterface {
  optimizerData: Record<OptimizerVectorParameterTypeInternal, OptimizerCurrentDataMap[]> | undefined;
  workMode: string;
  temperature?: number;
}

export const OptimizerCurrent: React.FC<PropsInterface> = ({ optimizerData, workMode, temperature }) => {
  const { t } = useTranslation('channel-details');
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [activeViewIndex, setActiveViewIndex] = useState<number>(0);
  const views = [{ label: t('simpleView') }, { label: t('detailsView') }];

  const selectedParameter = useMemo(() => {
    if (activeTabIndex === 0) return OptimizerVectorParameterTypeInternal.Voltage;
    if (activeTabIndex === 1) return OptimizerVectorParameterTypeInternal.ActivePower;
    return OptimizerVectorParameterTypeInternal.ScrFilling;
  }, [activeTabIndex]);

  const getDonutValue = (val: number) => {
    if (selectedParameter === OptimizerVectorParameterTypeInternal.ActivePower) return val / 1000;
    if (selectedParameter === OptimizerVectorParameterTypeInternal.ScrFilling && val === WRONG_MEASUREMENT_VALUE)
      return 0;
    return val;
  };

  const tabs = [
    { label: t('energyMeasurements.types.phase.VOLTAGE') },
    { label: t('energyMeasurements.types.phase.POWER') },
    { label: t('energyMeasurements.types.phase.SCR_FILLING') },
  ];

  return (
    <div className="optimizer-current">
      <ControlWrapper className=" control-wrapper--column p-l-24 p-r-24">
        <div className="row-container m-b-24">
          <Tabs
            tabList={tabs}
            activeTabIndex={activeTabIndex}
            setActiveTabIndex={setActiveTabIndex}
            tabsType="pills"
            isDivider={false}
            tabsBgColor="grey"
            fullWidth={false}
          />
        </div>
        {!optimizerData ? (
          <EmptyStateBox content={t('noDataToDisplay')} icon={<IconWarning />} />
        ) : (
          <div className="row-container">
            {optimizerData &&
              optimizerData[selectedParameter]?.map((data) => (
                <Fragment key={data.index}>
                  <EnergyDonut
                    phase={data.index}
                    value={getDonutValue(data.value)}
                    dataMax={measurementParametersOptimizer[selectedParameter].dataMax}
                    activeTabIndex={activeTabIndex}
                    data={optimizerData[selectedParameter]}
                    selectedParameter={selectedParameter}
                    disabled={
                      selectedParameter === OptimizerVectorParameterTypeInternal.ScrFilling &&
                      data.value === WRONG_MEASUREMENT_VALUE
                    }
                  />
                  {data.index !== 3 && <div className="separator" />}
                </Fragment>
              ))}
          </div>
        )}
        <Balance
          kind="full"
          selectedParameter={selectedParameter}
          optimizerData={optimizerData}
          activeTabIndex={activeTabIndex}
          workMode={workMode}
          temperature={temperature}
        />
        <hr className="m-t-24 m-b-24" />
        <div className="row-container m-b-24">
          <h3 className="view-header">{t('view')}</h3>
          <Tabs
            tabList={views}
            activeTabIndex={activeViewIndex}
            setActiveTabIndex={setActiveViewIndex}
            tabsType="pills"
            isDivider={false}
            tabsBgColor="grey"
            fullWidth={false}
          />
        </div>
        <div className="diagram-center">
          {activeViewIndex === 0 ? (
            <DiagramSimple optimizerData={optimizerData} />
          ) : (
            <Diagram optimizerData={optimizerData} temperature={temperature} />
          )}
        </div>
      </ControlWrapper>
    </div>
  );
};
