import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { uniq } from 'lodash';
import { ChannelTypeInternal } from '../../data-access/gql-types/graphql';
import {
  ChannelInterface,
  DeviceInterface,
  DevicesAndChannelsStateInterface,
  UseDevicesAndChannelsInterface,
  Visibility,
} from '../../types';
import { isWisniowski } from '../../utils/helpers/wisniowski';
import { DevicesAndChannelsContext } from './provider';

interface UseDevicesAndChannelsProps {
  channelId?: string;
  deviceId?: string;
  visibility?: Visibility;
  channelTypes?: ChannelTypeInternal[];
}

const sortedChannelTypes = [
  ChannelTypeInternal.Blind,
  ChannelTypeInternal.Switch,
  ChannelTypeInternal.Gate,
  ChannelTypeInternal.Meter,
  ChannelTypeInternal.Light,
  ChannelTypeInternal.Optimizer,
];

const wisniowskiSortedChannelTypes = [
  ChannelTypeInternal.Gate,
  ChannelTypeInternal.Blind,
  ChannelTypeInternal.Switch,
  ChannelTypeInternal.Meter,
  ChannelTypeInternal.Light,
  ChannelTypeInternal.Optimizer,
];

export const useDevicesAndChannels = ({
  channelId,
  deviceId,
  visibility = Visibility.Visible,
  channelTypes = [
    ChannelTypeInternal.Switch,
    ChannelTypeInternal.Blind,
    ChannelTypeInternal.Meter,
    ChannelTypeInternal.Light,
    ChannelTypeInternal.Optimizer,
    ChannelTypeInternal.Gate,
  ],
}: UseDevicesAndChannelsProps = {}): UseDevicesAndChannelsInterface => {
  const context = useContext(DevicesAndChannelsContext);
  const { t: tc } = useTranslation('common');
  const { channelList, deviceList, setDeviceList } = context;
  const sortedArray = !isWisniowski ? sortedChannelTypes : wisniowskiSortedChannelTypes;

  const channel = useMemo(() => {
    if (channelId) {
      return channelList.find((channel: ChannelInterface) => channel.id === channelId);
    }
  }, [channelId, channelList]);

  const device = useMemo(() => {
    if (deviceId) {
      return deviceList.find((device) => device.id === deviceId);
    }
  }, [deviceId, deviceList]);

  const editDeviceName = useCallback(
    (editDeviceId: string, editDeviceName: string) => {
      const newDeviceList = Array.from(deviceList);
      const foundIndex = newDeviceList.findIndex((d) => d?.id === editDeviceId);

      if (foundIndex !== -1) {
        const newDevice = newDeviceList[foundIndex] as DeviceInterface;
        newDevice.payload.name = editDeviceName;

        setDeviceList(newDeviceList);
      }
    },
    [deviceList],
  );

  const deviceChannels = useMemo(() => {
    return (channelList || []).filter((channel) => channel?.deviceId === deviceId && channel) as ChannelInterface[];
  }, [channelList, deviceId]);

  const deviceChannelsById = useCallback(
    (id: string) => {
      return (channelList || []).filter((channel) => channel?.deviceId === id && channel) as ChannelInterface[];
    },
    [channelList],
  );

  const channelTypesList = useMemo(() => {
    if (channelList) {
      return uniq(
        channelList
          .filter((channel) => channel && channel.data.type !== undefined)
          .filter((channel) => channelTypes?.includes(channel.data.type))
          .map((x) => x?.data.type as string),
      );
    }

    return [];
  }, [channelList, sortedArray]);

  const visibilityCondition = useCallback(
    (channel: ChannelInterface) => {
      switch (visibility) {
        case Visibility.Visible:
          return channel.isVisible;
        case Visibility.InVisible:
          return !channel.isVisible;
        default:
          return channel;
      }
    },
    [visibility],
  );

  const getGroupsList = useCallback(() => {
    return sortedArray
      .sort(
        (a: string, b: string) =>
          sortedArray.findIndex((f) => f === a) - sortedArray.findIndex((f) => f === b),
      )
      .map((type) => ({
        label: tc(`typesPlural.${type}`),
        type: type as ChannelTypeInternal,
        channels: channelList.filter((channel) => {
          return channel.data.type === type && visibilityCondition(channel);
        }) as ChannelInterface[],
      }))
      .filter((x) => x.channels.length > 0);
  }, [sortedArray, channelList, visibility]);

  const channelGroups = useMemo(() => getGroupsList(), [sortedArray, visibility, channelTypes]);

  const contextValue = useMemo((): DevicesAndChannelsStateInterface => context, Object.values(context));

  return {
    ...contextValue,
    channel,
    device,
    deviceChannels,
    deviceChannelsById,
    channelGroups,
    channelTypesList,
    editDeviceName,
  };
};
