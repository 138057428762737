import { useEffect, useRef } from 'react';
import { useSwitchSetOff, useSwitchSetOn } from '../../api/modules/switch/switch.hooks';
import { SwitchStateInternal } from '../../data-access/gql-types/graphql';
import { ChannelSwitchStateInterface } from '../../types';
import { useChannelsState } from '../channels-state';
import { useNative } from '../native';

interface SwitchHook {
  channelId: string;
  deviceId: string;
}

interface UseSwitchActionInterface {
  handleControlOn: (status: boolean) => void;
  isOn: boolean;
}

export const useSwitch = ({ channelId, deviceId }: SwitchHook): UseSwitchActionInterface => {
  const { channelState } = useChannelsState();
  const { handleVibration } = useNative();
  const switchOn = useSwitchSetOn();
  const switchOff = useSwitchSetOff();

  const isOn = (channelState[channelId] as ChannelSwitchStateInterface).lavvaState === SwitchStateInternal.On;

  const previousValueRef = useRef<boolean>(isOn);

  useEffect(() => {
    if (isOn !== previousValueRef.current) {
      handleVibration(channelId);
    }

    previousValueRef.current = isOn;
  }, [isOn, previousValueRef]);

  const handleControlOn = (status: boolean) => {
    if (!status) {
      switchOff.mutate({ deviceId, channelId });
    } else {
      switchOn.mutate({ deviceId, channelId });
    }
  };

  return { handleControlOn, isOn };
};
