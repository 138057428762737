import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, InputSelect, NavHeader, Page } from '../../../../components';
import { useExalusContext } from '../../context';
import { useUpdatesSettings } from './hooks/use-updates-settings';

const UpdatesSettings: React.FC = () => {
  const { t } = useTranslation('installation');
  const { synchronized } = useExalusContext();
  const {
    getControllerUpdateBranch,
    getControllerAutomaticOption,
    branch,
    handleChangeBranch,
    handleChangeAutomatic,
    branchOptions,
    automatic,
    automaticOptions,
    showBranch,
    showAutomatic,
  } = useUpdatesSettings();

  useEffect(() => {
    if (synchronized) {
      getControllerUpdateBranch();
      getControllerAutomaticOption();
    }
  }, [synchronized]);

  return (
    <Page
      isStickyHeader
      header={
        <>
          <NavHeader />
          <Header title={t('updatesSettings')} isUnderline />
        </>
      }
    >
      <div className="grid-list-24">
        {showBranch && (
          <InputSelect
            options={branchOptions}
            value={branch}
            onChange={handleChangeBranch}
            label={t('exalus.environment')}
          />
        )}

        {showAutomatic && (
          <InputSelect
            options={automaticOptions}
            value={automatic}
            onChange={handleChangeAutomatic}
            label={t('exalus.automaticUpdates')}
          />
        )}
      </div>
    </Page>
  );
};

export default UpdatesSettings;
