import React from 'react';

export const IconOrder: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.34106 7.7093H6.24468H8.1483H11.4555C11.7317 7.7093 11.9555 7.93315 11.9555 8.2093V9.17234C11.9555 9.55876 12.3752 9.79913 12.7085 9.60363L17.2599 6.93408C17.5904 6.74026 17.589 6.26205 17.2575 6.07009L12.7056 3.43456C12.3723 3.24156 11.9551 3.48209 11.9551 3.86726V4.83987C11.9551 5.11602 11.7313 5.33987 11.4551 5.33987H8.14809H6.24458"
      stroke="#15141D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.7798 12.6822H11.8762H8.56893C8.29279 12.6822 8.06893 12.4583 8.06893 12.1822V11.2308C8.06893 10.8419 7.64428 10.6019 7.31103 10.8025L2.73836 13.5555C2.40943 13.7536 2.41718 14.2331 2.75234 14.4204L7.32502 16.9757C7.6583 17.162 8.06893 16.9211 8.06893 16.5393V15.5516C8.06893 15.2754 8.29279 15.0516 8.56893 15.0516H11.8762H15.5001"
      stroke="#15141D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
