import { PointerEvent } from 'react';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { IDeviceTask } from 'lavva.exalushome/build/js/Services/Devices/IDeviceTask';
import { SetLightBrightness } from 'lavva.exalushome/build/js/Services/Devices/Tasks/Tasks';
import { useExalusServicesContext } from '../../../context/services';
import { CentralActionLightControlHook } from '../types';

export const useCentralActionLightControl = (channels: IDeviceChannel[]): CentralActionLightControlHook => {
  const { devicesApi } = useExalusServicesContext();

  const handleAction = async (brightness?: number) => {
    const mappedChannels: [Device: IDevice, Task: IDeviceTask][] = channels.map((channel) => {
      const task = new SetLightBrightness();
      task.DeviceGuid = channel.GetDevice().Guid;
      task.Channel = channel.Number;
      if (typeof brightness === 'number' && brightness >= 0) {
        task.Brightness = brightness;
      }

      return [channel.GetDevice(), task];
    });

    return await devicesApi().ExecuteDevicesTasksAsync(mappedChannels);
  };

  const handleSliderEvent = (event: PointerEvent<HTMLInputElement>) => {
    handleAction(Number((event.target as HTMLInputElement).value));
  };

  return { handleAction, handleSliderEvent };
};
