import { useCallback, useEffect, useRef } from 'react';
import {
  useLightSetBrightness,
  useLightSetRgb,
  useLightSetTemperature,
  useLightToggleOff,
  useLightToggleOn,
} from '../../api/modules/light/light.hooks';
import { ChannelTypeInternal, LightToggleValueInternal } from '../../data-access/gql-types/graphql';
import { ChannelInterface, ChannelLightStateInterface } from '../../types';
import { useChannelsState } from '../channels-state';
import { useNative } from '../native';

interface UseLightActionInterface {
  handleControlOn: (status: boolean) => void;
  handleControlBrightness: (value: number) => void;
  handleControlTemperature: (value: number) => void;
  handleControlRgb: (value: string) => void;
  isOn: boolean;
}

export const useLight = (channel: ChannelInterface): UseLightActionInterface => {
  const { handleVibration } = useNative();
  const toggleOn = useLightToggleOn();
  const toggleOff = useLightToggleOff();
  const setBrightness = useLightSetBrightness();
  const setTemperature = useLightSetTemperature();
  const setColor = useLightSetRgb();
  const { channelState } = useChannelsState();

  const isOn =
    channel.data.type === ChannelTypeInternal.Light
      ? (channelState[channel.id] as ChannelLightStateInterface).toggleValue === LightToggleValueInternal.On
      : false;

  const previousValueRef = useRef<boolean>(isOn);

  useEffect(() => {
    if (isOn !== previousValueRef.current) {
      handleVibration(channel.id);
    }

    previousValueRef.current = isOn;
  }, [isOn, previousValueRef]);

  const handleControlOn = useCallback(
    (status: boolean) => {
      if (!status) {
        toggleOff.mutate({ deviceId: channel.deviceId, channelId: channel.id });
      } else {
        toggleOn.mutate({ deviceId: channel.deviceId, channelId: channel.id });
      }
    },
    [isOn, channel, channelState[channel.id]],
  );

  const handleControlBrightness = useCallback(
    (value: number) => {
      setBrightness.mutate({ deviceId: channel.deviceId, channelId: channel.id, brightness: value });
    },
    [channel, channelState[channel.id]],
  );

  const handleControlTemperature = useCallback(
    (value: number) => {
      setTemperature.mutate({ deviceId: channel.deviceId, channelId: channel.id, temperature: value });
    },
    [channel, channelState[channel.id]],
  );

  const handleControlRgb = useCallback(
    (value: string) => {
      setColor.mutate({
        deviceId: channel.deviceId,
        channelId: channel.id,
        r: Number(value.split(',')[0]),
        g: Number(value.split(',')[1]),
        b: Number(value.split(',')[2]),
      });
    },
    [channel, channelState[channel.id]],
  );

  return { handleControlOn, handleControlBrightness, handleControlTemperature, handleControlRgb, isOn };
};
