import React from 'react';

export const IconDelete: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 8V14" stroke="#15141D" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M5 5H16V15C16 16.1046 15.1046 17 14 17H7C5.89543 17 5 16.1046 5 15V5Z"
      stroke="#15141D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.73535 3C8.73535 2.44772 9.18307 2 9.73535 2H11.2648C11.817 2 12.2648 2.44772 12.2648 3V4.64706H8.73535V3Z"
      stroke="#15141D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12 8V14" stroke="#15141D" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3 4.64697H18" stroke="#15141D" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
