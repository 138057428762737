import { useState, useEffect } from 'react';
import * as storage from '../../utils/storage/lavva';

interface HookInterface {
  handleVibration: (deviceId: string) => void;
  setVibration: (value: boolean) => void;
  forceVibration: () => void;
}

export const useVibrationManager = (): HookInterface => {
  const [devicesQueue, setDevicesQueue] = useState(new Map());
  const [withVibration, setWithVibration] = useState<boolean>(storage.getItem('vibration'));

  useEffect(() => {
    if (devicesQueue.size) {
      const clearDeviceListTimeout = setTimeout(() => {
        setDevicesQueue(new Map());
      }, 10000);

      return () => clearTimeout(clearDeviceListTimeout);
    }
  }, [devicesQueue]);

  const fireVibration = () => {
    if (navigator.vibrate) {
      navigator.vibrate(1000);
    } else {
      console.log('Vibration API is not supported');
    }
  };

  const setVibration = (value) => {
    setWithVibration(value);

    if (value) fireVibration();
  };

  const _removeFromQueue = (deviceId: string) => {
    if (!devicesQueue.get(deviceId)) {
      const newDeviceList = new Map(devicesQueue);
      newDeviceList.delete(deviceId);
      setDevicesQueue(newDeviceList);
    } else {
      const value = devicesQueue.get(deviceId);
      setDevicesQueue(new Map(devicesQueue.set(deviceId, value - 1)));
    }
  };

  const handleVibration = (deviceId: string) => {
    if (devicesQueue.has(deviceId)) {
      _removeFromQueue(deviceId);
      if (withVibration) {
        fireVibration();
      }
    }
  };

  const forceVibration = () => {
    if (withVibration) fireVibration();
  };

  return { handleVibration, setVibration, forceVibration };
};
