import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DeviceType, IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { DeviceTaskExecutionResult } from 'lavva.exalushome/build/js/Services/Devices/TaskExecutionResult';
import { uniq } from 'lodash';
import queryClient from '../../../../../api/clients/query-client';
import { useBackdropContext } from '../../../../../hooks';
import { ROUTES } from '../../../../../routes';
import { useExalusContext } from '../../../context';
import { useExalusServicesContext } from '../../../context/services';
import { useExalusFoundDevices } from '../../../hooks';
import { GroupedDevice } from '../../device-list/types';
import { UpdateDeviceMethod } from '../types';
import { useDeviceTaskExecuted } from './use-device-task-executed';

export const useFoundDevices = () => {
  const history = useHistory();
  const { t: tc } = useTranslation('common');
  const [fetching, setFetching] = useState<boolean>(false);
  const { devicesApi } = useExalusServicesContext();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { data } = useExalusFoundDevices();
  const { selectedExalusCredentials, synchronized } = useExalusContext();
  const { handleResult } = useDeviceTaskExecuted();

  const searchAgainDevices = async () => {
    setFetching(true);
    updateDeviceList(UpdateDeviceMethod.Clear);
    await devicesApi().FindDevicesAsync();
    setFetching(false);
  };

  const stopSearching = () => setFetching(false);

  const registerDevice = async (device: IDevice) => {
    turnOnBackdrop();
    const result: DeviceTaskExecutionResult = await devicesApi().RegisterDeviceAsync(device);

    handleResult(result, device.Name, () => {
      updateDeviceList(UpdateDeviceMethod.Delete, device);
      turnOffBackdrop();
      history.push(ROUTES.DeviceSettings(device.Guid));
    });
  };

  const onDeviceFound = (device: IDevice) => {
    updateDeviceList(UpdateDeviceMethod.Add, device);
  };

  const updateDeviceList = (method: UpdateDeviceMethod, device?: IDevice) => {
    if (selectedExalusCredentials?.id && synchronized) {
      queryClient.setQueryData(
        ['exalus-found-devices', selectedExalusCredentials?.id, synchronized],
        (oldDevices: any) => {
          let oldDevicesTemp = [] as IDevice[];

          if (oldDevices) {
            if (device) {
              oldDevicesTemp = [...oldDevices];
              const deviceIndex = oldDevicesTemp.findIndex((x) => x.Guid === device.Guid);

              if (deviceIndex === -1) {
                if (method === UpdateDeviceMethod.Add) oldDevicesTemp.push(device);
              } else {
                if (method === UpdateDeviceMethod.Delete) oldDevicesTemp.splice(deviceIndex, 1);
              }

              return oldDevicesTemp;
            } else {
              oldDevicesTemp = [];

              return oldDevicesTemp;
            }
          }

          return oldDevices;
        },
      );
    }
  };

  const typeList = useMemo(() => {
    return uniq(data?.map((device) => device && DeviceType[device.DeviceType]));
  }, [data]);

  const sortedDeviceTypes = [
    DeviceType[DeviceType.Driver],
    DeviceType[DeviceType.Receiver],
    DeviceType[DeviceType.Sensor],
    DeviceType[DeviceType.Controller],
    DeviceType[DeviceType.Remote],
    DeviceType[DeviceType.Scene],
    DeviceType[DeviceType.Multipurpose],
    DeviceType[DeviceType.Unknown],
  ];

  const groupedDevices: GroupedDevice[] = useMemo(() => {
    if (data) {
      return typeList
        .sort(
          (a: string, b: string) =>
            sortedDeviceTypes.findIndex((f) => f === a) - sortedDeviceTypes.findIndex((f) => f === b),
        )
        .map((type: string) => ({
          label: tc(`typesPlural.exalus.${type}`),
          devices: data?.filter((device: IDevice) => DeviceType[device.DeviceType] === type) || [],
        }));
    }

    return [];
  }, [typeList, data]);

  useEffect(() => {
    devicesApi().OnDeviceFoundEvent().Subscribe(onDeviceFound);

    return () => {
      turnOffBackdrop();
      devicesApi().OnDeviceFoundEvent().Unsubscribe(onDeviceFound);
    };
  }, [selectedExalusCredentials?.id, synchronized]);

  return {
    groupedDevices,
    fetching,
    registerDevice,
    stopSearching,
    searchAgainDevices,
  };
};
