import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import {
  CustomBackdrop,
  DialogConfirmation,
  Header,
  NavHeader,
  Page,
  PopUpNav,
  SubmitButton,
} from '../../../components';
import { IconDelete, IconUserAccess } from '../../../components/popup-nav/icons';
import {
  ShareInfoModificationType,
  ModifyShareInfoItemBaseInput,
  ShareInfoItemBase,
} from '../../../data-access/gql-types/graphql';
import { useApi } from '../../../hooks';
import { ROUTES } from '../../../routes';
import { SharedItemType, SharingType } from '../../../types';
import { leadingZero } from '../../../utils/helpers';
import { useInstallationShare } from '../hooks/use-installation-share';
import { useMember } from '../hooks/use-member';
import { useModify } from '../hooks/use-modify';
import './index.scss';

interface RemoveGroup {
  groupId: string;
  groupName: string;
}

const GroupsShareEdit: React.FC = () => {
  const history = useHistory();
  const { t: tc } = useTranslation('common');
  const { t } = useTranslation('installation');
  const { methods } = useInstallationShare();
  const { modyfyGroupAccess, modifyUserAccessesLoading } = useModify();
  const { groups } = useApi();
  const { userSharing, userSharingLoading, refetchSharingInfo } = useMember(true);
  const [removeAccessPopup, setRemoveAccessPopup] = useState<boolean>(false);
  const [removeGroup, setRemoveGroup] = useState<RemoveGroup>({ groupId: '', groupName: '' });

  const editAccess = (group: ShareInfoItemBase) => {
    if (userSharing?.sharingInfo) {
      methods.handleResetShareInstallation();
      methods.handleSetModificationType(ShareInfoModificationType.Modify);
      methods.handleSetShareSubject(SharedItemType.Group);
      methods.handleSetUserId(userSharing.sharingInfo.sharedToUserId || '');
      methods.handleSetEditedId(group.id);
      if (group.sharedFrom && group.sharedUntil) {
        methods.handleSetShareAccess(SharingType.Timed);
        const dateStart = new Date(group.sharedFrom);
        methods.handleSetShareDateStart(dateStart);
        methods.handleSetShareTimeStart([dateStart.getHours(), dateStart.getMinutes()].map(leadingZero).join(':'));
        const dateEnd = new Date(group.sharedUntil);
        methods.handleSetShareDateEnd(dateEnd);
        methods.handleSetShareTimeEnd([dateEnd.getHours(), dateEnd.getMinutes()].map(leadingZero).join(':'));
      } else methods.handleSetShareAccess(SharingType.Permanent);
      history.push(ROUTES.InstallationShareRestriction());
    }
  };

  const deletePopup = (group: ShareInfoItemBase) => {
    setRemoveGroup({ groupId: group.id, groupName: groups.find((x) => x.id === group.id)?.group.name || '' });
    setRemoveAccessPopup(true);
  };

  const removeAccess = () => {
    const groups: ModifyShareInfoItemBaseInput[] = [
      { id: removeGroup.groupId, shareInfoModificationType: ShareInfoModificationType.Remove },
    ];

    modyfyGroupAccess(userSharing?.sharingInfo?.sharedToUserId || '', groups, () => {
      setRemoveGroup({ groupId: '', groupName: '' });
      setRemoveAccessPopup(false);
      refetchSharingInfo();
    });
  };

  const groupList = useMemo(() => {
    if (userSharing?.sharingInfo?.groups) {
      if (!userSharing?.sharingInfo?.groups.length) return <p>{t('shared_user.groups_empty')}</p>;

      return userSharing?.sharingInfo?.groups.map((group) => {
        const sharingType = group.sharedFrom && group.sharedUntil ? SharingType.Timed : SharingType.Permanent;
        const groupName = groups.find((x) => x.id === group.id)?.group.name || 'N/A';

        return (
          <div key={group.id} className="share-groupsEdit-item">
            <div>
              <p>{groupName}</p>
              <p className="access">
                {sharingType === SharingType.Timed
                  ? `${t('share_path.access')} ${t('share_path.from')} ${format(
                      new Date(group.sharedFrom),
                      'dd.MM.yyyy, HH:mm',
                    )} ${t('share_path.to')} ${format(new Date(group.sharedUntil), 'dd.MM.yyyy, HH:mm')}`
                  : `${t('share_path.access')} ${t(`share_path.access_${sharingType.toLowerCase()}`)}`}
              </p>
            </div>
            <PopUpNav
              dark
              links={[
                {
                  label: t('shared_user.edit_access'),
                  onClick: () => editAccess(group),
                  hasLineBelow: true,
                  icon: <IconUserAccess />,
                },
                {
                  label: t('shared_user.remove_access'),
                  onClick: () => deletePopup(group),
                  hasLineBelow: false,
                  icon: <IconDelete />,
                },
              ]}
            />
          </div>
        );
      });
    }

    return [];
  }, [userSharing, groups]);

  const handleNext = () => {
    if (userSharing?.sharingInfo) {
      methods.handleResetShareInstallation();
      methods.handleSetModificationType(ShareInfoModificationType.Add);
      methods.handleSetShareSubject(SharedItemType.Group);
      methods.handleSetUserId(userSharing.sharingInfo.sharedToUserId || '');
      methods.handleSetShareEmail(userSharing.sharingInfo.sharedToUser?.email || '');
      methods.handleSetSharePermission(userSharing.sharingInfo.installation.accessType);
      history.push(ROUTES.InstallationGroupsShare());
    }
  };

  return (
    <Page
      className="share-groupsEdit"
      header={
        <>
          <NavHeader />
          <Header title={t('shared_user.groups')} isUnderline />
        </>
      }
    >
      {!userSharingLoading ? groupList : <CustomBackdrop loading={userSharingLoading} />}
      <SubmitButton type="button" onClick={handleNext}>
        {t('shared_user.share_add_access')}
      </SubmitButton>
      <DialogConfirmation
        show={removeAccessPopup}
        setShow={setRemoveAccessPopup}
        header={t('shared_user.remove_group_access')}
        content={t('shared_user.remove_group_access_confirm', {
          user: userSharing?.sharingInfo?.sharedToUser?.firstName,
          item: removeGroup.groupName,
        })}
        secondaryBtnText={tc('buttons.cancel')}
        primaryBtnText={!modifyUserAccessesLoading ? t('shared_user.remove_access') : tc('buttons.loading')}
        secondaryBtnAction={() => setRemoveAccessPopup(false)}
        primaryBtnAction={removeAccess}
        direction="column"
      />
    </Page>
  );
};

export default GroupsShareEdit;
