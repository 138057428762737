import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header, NavHeader, Page, SubmitButton } from '../../../../../components';
import AddressIp from '../../../../../components/address-ip';
import { useAddDns } from '../hooks/use-add-dns';

const ExalusAddDns: React.FC = () => {
  const { t } = useTranslation('installation');
  const { t: tc } = useTranslation('common');
  const { handleChange, handleSubmit, dns, submitDisabled } = useAddDns();

  return (
    <Page
      isStickyHeader
      header={
        <>
          <NavHeader />
          <Header title={t('exalusNetwork.dnsAdd')} isUnderline />
        </>
      }
    >
      <AddressIp value={dns} label={t('exalusNetwork.addressDns')} handleChange={handleChange} />
      <SubmitButton type="button" onClick={handleSubmit} disabled={submitDisabled}>
        {tc('buttons.add')}
      </SubmitButton>
    </Page>
  );
};

export default ExalusAddDns;
