import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { PopUpNavLinkInterface } from '../../../components';
import { IconGroups, IconSettings, IconShare } from '../../../components/popup-nav/icons';
import { DashboardItemType, InstallationAccessType } from '../../../data-access/gql-types/graphql';
import { useDevicesAndChannels, useInstallation } from '../../../hooks';
import { ROUTES } from '../../../routes';
import { SharedItemType } from '../../../types';
import { useInstallationShare } from '../../installation-share/hooks/use-installation-share';
import { RouteParams } from '../types';
import { useModifyMultipleGroups } from './use-modify-multiple-groups';

export const useChannelDetails = () => {
  const history = useHistory();
  const { t } = useTranslation('channel-details');
  const { t: tc } = useTranslation('configuration');
  const { channelId } = useParams<RouteParams>();
  const { channel } = useDevicesAndChannels({ channelId });
  const { selectedInstallation, selectedInstallationId } = useInstallation();
  const { methods } = useInstallationShare({ ignoreChecking: true });
  const { groupList, groupPopup, handleSaveGroup, toggleGroup, setGroupPopup } = useModifyMultipleGroups({
    itemId: channelId,
    itemType: DashboardItemType.Channel,
  });

  const shareChannel = () => {
    if (channel) {
      methods.handleResetShareInstallation();
      methods.handleSetShareInstallationId(selectedInstallationId || '');
      methods.handleSetShareSubject(SharedItemType.Channel);
      methods.handleSetShareItem({ itemId: channel.id, name: channel.alias });
      methods.handleUpdateChannels([{ id: channel.id, name: channel.alias }]);

      history.push(ROUTES.InstallationShared(selectedInstallationId));
    }
  };

  const popUpNavLinks = useMemo(() => {
    const links: PopUpNavLinkInterface[] = [
      {
        onClick: () => history.push(ROUTES.ChannelConfiguration(channel?.data.type, channel?.id)),
        label: tc('channelSettings'),
        icon: <IconSettings />,
      },
      ...(selectedInstallation?.accessType === InstallationAccessType.Owner ||
      selectedInstallation?.accessType === InstallationAccessType.Admin
        ? [
            {
              onClick: () => setGroupPopup(true),
              label: t('groupAddRemove'),
              icon: <IconGroups />,
              hasLineBelow: true,
            },
          ]
        : []),
      ...(selectedInstallation?.accessType === InstallationAccessType.Owner ||
      selectedInstallation?.accessType === InstallationAccessType.Admin
        ? [
            {
              onClick: shareChannel,
              label: t('shareChannel'),
              icon: <IconShare />,
            },
          ]
        : []),
    ];

    return links;
  }, [channel, selectedInstallation?.accessType]);

  return {
    popUpNavLinks,
    channel,
    groupPopup,
    groupList,
    setGroupPopup,
    handleSaveGroup,
    toggleGroup,
  };
};
