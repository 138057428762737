import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { IntegrationType } from '../../data-access/gql-types/graphql';
import { useBackdropContext, useInstallation } from '../../hooks';
import { ROUTES } from '../../routes';
import { DownloadContent } from './components/download-content';
import { UpdateContent } from './components/update-content';
import './index.scss';

export const ExalusBackdropPopup: React.FC = () => {
  const { t } = useTranslation('installation');
  const { backdropPopupOpen, exalusPopupContent } = useBackdropContext();
  const { integrationType } = useInstallation();

  const changeInstallation = () => (window.location.href = ROUTES.Installation());

  const backdropOpen = useMemo(() => {
    if (
      [
        ROUTES.Installation(),
        ROUTES.InstallationCreate(),
        ROUTES.IntegrationsList(),
        ROUTES.InstallationCreated(),
      ].includes(window.location.pathname)
    )
      return false;
    return backdropPopupOpen && integrationType === IntegrationType.Exalus;
  }, [backdropPopupOpen, integrationType]);

  const renderPopupContent = () => {
    if (exalusPopupContent?.updateContent) return <UpdateContent />;
    if (exalusPopupContent?.downloadContent) return <DownloadContent />;

    return (
      <>
        {exalusPopupContent?.loader && <CircularProgress className="backdrop-loader" color="inherit" size={40} />}
        {exalusPopupContent?.buttonTitle && exalusPopupContent?.handleClick && (
          <div className="exalus-button-container">
            <button className="button button--secondary" onClick={exalusPopupContent?.handleClick}>
              {exalusPopupContent?.buttonTitle}
            </button>
            {exalusPopupContent?.changeInstallationButton && (
              <button className="button button--secondary" onClick={changeInstallation}>
                {t('changeInstallation')}
              </button>
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <Backdrop classes={{ root: 'backdrop-popup' }} open={backdropOpen}>
      <div className="backdrop-popup--content">
        {exalusPopupContent?.header && <p className="backdrop-popup--header">{exalusPopupContent.header}</p>}
        {!exalusPopupContent?.updateContent && !exalusPopupContent?.downloadContent && (
          <div className={classNames('backdrop-popup--info', { bold: !exalusPopupContent?.header })}>
            {exalusPopupContent?.message}
          </div>
        )}
        {renderPopupContent()}
      </div>
    </Backdrop>
  );
};
