import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { GateEventTypeInternal, GateHistoryEntry } from '../../../../../../data-access/gql-types/graphql';
import { IconGateClose } from '../icons/close';
import { IconGateOpen } from '../icons/open';
import { IconGateTilt } from '../icons/tilt';
import './index.scss';

interface ComponentProps {
  item: GateHistoryEntry;
}

export const HistoryItem: React.FC<ComponentProps> = ({ item }) => {
  const { t } = useTranslation('channel-details');

  const icon = useMemo(() => {
    switch (item.eventType) {
      case GateEventTypeInternal.SetClose:
        return <IconGateClose source={item.controlledBy.controlSource} />;
      case GateEventTypeInternal.SetTilt:
        return <IconGateTilt />;
      default:
        return <IconGateOpen source={item.controlledBy.controlSource} />;
    }
  }, [item.eventType]);

  return (
    <>
      <div className="gate-history-item">
        <div className="history-icon">{icon}</div>
        <div className="history-details">
          <p className="title">
            {`${t(`gateHistory.event.${item.eventType}`, {
              position: item.value !== undefined ? item.value : '?',
            })} ${t(`gateHistory.controlledBy.${item.controlledBy.controlSource}`, {
              name: item.controlledBy.name || '-',
            })}`}
          </p>
          <p className="date">{item.occuredAt ? format(new Date(item.occuredAt), 'dd.MM.yy, HH:mm') : ''}</p>
        </div>
      </div>
      <hr />
    </>
  );
};
