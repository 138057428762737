import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useDeviceSwapIn } from '../../../../api/modules/device/device.hooks';
import { CustomBackdrop, Header, NavHeader, Page, SubmitButton } from '../../../../components';
import { IconReverseInputs } from '../../../../components/icons/reverse-inputs';
import { ChannelTypeInternal } from '../../../../data-access/gql-types/graphql';
import { useBackdropContext, useDevicesAndChannels } from '../../../../hooks';
import { useErrors } from '../../../../hooks/use-errors';
import { ROUTES } from '../../../../routes';
import { toastSuccess } from '../../../../utils/toast';
import './index.scss';

const translateKey = {
  COVER: 'Cover',
  SWITCH: 'Switch',
};

const ReverseInputs: React.FC = () => {
  const history = useHistory();
  const { deviceId } = useParams<{ deviceId: string; output: string }>();
  const { t } = useTranslation('device-add');
  const { t: tc } = useTranslation('common');
  const { t: td } = useTranslation('channel-settings');
  const { device, channelList } = useDevicesAndChannels({ deviceId });
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const swapIn = useDeviceSwapIn();
  const { handleLavvaResolve } = useErrors();

  const redirect = useCallback(() => {
    switch (device?.type) {
      case ChannelTypeInternal.Blind: {
        const channels = (channelList || []).filter((c) => c.deviceId === deviceId);
        const channel = channels.find((c) => c.data.type === ChannelTypeInternal.Blind);

        if (channel) history.push(ROUTES.CalibrateChannel(channel.id));
        else history.push(ROUTES.AddSuccess());
        break;
      }
      case ChannelTypeInternal.Gate: {
        const channels = (channelList || []).filter((c) => c.deviceId === deviceId);
        const channel = channels.find((c) => c.data.type === ChannelTypeInternal.Gate);

        if (channel) history.push(ROUTES.CalibrateChannel(channel.id));
        else history.push(ROUTES.AddSuccess());
        break;
      }
      case ChannelTypeInternal.Switch: {
        history.push(ROUTES.AddSuccess());
        break;
      }
      default: {
        history.push(ROUTES.AddSuccess());
        break;
      }
    }
  }, [device, deviceId]);

  const swapInDevice = useCallback(() => {
    if (deviceId) {
      turnOnBackdrop();
      swapIn.mutate(
        { deviceId },
        {
          onSuccess: ({ data }) => {
            handleLavvaResolve(data.publishStatus, () => {
              toastSuccess({ content: `${td('toast.swapInSuccess')}` });
              turnOffBackdrop();
              redirect();
            });
          },
          onError: () => {
            turnOffBackdrop();
          },
        },
      );
    }
  }, [deviceId]);

  return (
    <Page
      className="reverse-inputs"
      header={
        <>
          <NavHeader />
          {device && (
            <Header
              title={t(`reverseInputs.header${translateKey[device.type]}`)}
              subtitle={t(`reverseInputs.message${translateKey[device.type]}`)}
              column
            />
          )}
        </>
      }
    >
      <div className="icon-container">
        <IconReverseInputs />
      </div>
      <div className="row_container">
        <button className="button button--secondary" onClick={swapInDevice}>
          {t('reverseInputs.option')}
        </button>
      </div>
      <SubmitButton onClick={redirect} disabled={!device}>
        {tc('buttons.next')}
      </SubmitButton>
      <CustomBackdrop loading={!device} />
    </Page>
  );
};

export default ReverseInputs;
