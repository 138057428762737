import React from 'react';

export const IconTimeDelay: React.FC = () => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.43295 13.73C3.80942 12.5588 3.53653 11.2286 3.65309 9.89971C3.78431 8.40384 4.40183 6.99211 5.41123 5.88038C6.42062 4.76864 7.76635 4.01809 9.24263 3.74347C10.7189 3.46885 12.2445 3.68528 13.5862 4.35968C14.9278 5.03407 16.0117 6.12931 16.672 7.47793C17.3324 8.82655 17.5329 10.3543 17.2429 11.8277C16.9529 13.301 16.1883 14.6388 15.0661 15.6365C14.0692 16.5229 12.8387 17.0975 11.5268 17.2958C11.5276 17.2938 11.5293 17.2901 11.5331 17.2855C11.5419 17.275 11.557 17.2653 11.5759 17.2623C12.8631 17.0574 14.0693 16.4886 15.0491 15.6174C16.1671 14.6234 16.9288 13.2906 17.2178 11.8227C17.5067 10.3549 17.307 8.83278 16.6491 7.48918C15.9912 6.14558 14.9113 5.05442 13.5747 4.38253C12.238 3.71065 10.7181 3.49502 9.24731 3.76862C7.77652 4.04221 6.43581 4.78998 5.43017 5.89757C4.42453 7.00517 3.80931 8.41165 3.67858 9.90194C3.56401 11.208 3.82706 12.5154 4.43058 13.6707C4.43944 13.6876 4.44064 13.7055 4.43744 13.7188C4.43604 13.7246 4.43415 13.7282 4.43295 13.73Z"
      stroke="#15141D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M10.0397 6.81622V11.4242H14.1868" stroke="#15141D" strokeLinecap="round" />
    <path
      d="M9.6 16.1333L7.73333 18M7.73333 18L6 16.1333M7.73333 18L7.73333 14"
      stroke="#15141D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
