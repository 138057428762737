import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ChannelBox, CustomBackdrop } from '../../../../components';
import { ChannelTypeInternal } from '../../../../data-access/gql-types/graphql';
import { useDevicesAndChannels } from '../../../../hooks';
import { useGateSupportedFeatures } from '../../../channel-details/gate/hooks/use-gate-supported';
import { Calibration } from '../../../device-settings/device-settings-form/sections';
import { useConfiguration } from '../../hooks/use-configuration';
import { InfoPageType } from '../../types';
import Info from '../components/info';

interface ComponentProps {
  openInfoPage: (type: InfoPageType) => void;
}

const GateConfiguration: React.FC<ComponentProps> = ({ openInfoPage }) => {
  const { t } = useTranslation('configuration');
  const { channelId } = useParams<{ channelType: ChannelTypeInternal; channelId: string }>();
  const { channel } = useDevicesAndChannels({ channelId });
  const { calibrationSupported } = useGateSupportedFeatures(channel);
  const { editLoading } = useConfiguration({
    channel,
  });

  if (!channel) return null;

  return (
    <>
      <Info title={t('channelTest')} onClick={() => openInfoPage(InfoPageType.CHANNEL)} />
      <ChannelBox channel={channel} isListItem />
      {calibrationSupported && (
        <>
          <hr />
          <Info title={t('calibration')} onClick={() => openInfoPage(InfoPageType.CALIBRATION)} />
          <Calibration channel={channel} />
        </>
      )}
      {editLoading && <CustomBackdrop loading />}
    </>
  );
};

export default GateConfiguration;
