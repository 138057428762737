import React, { useMemo } from 'react';
import { sum } from 'lodash';
import { useSubscription } from '@apollo/client';
import {
  VectorParameterTypeInternal,
  ChannelTypeInternal,
  MeterTypeInternal,
  StandaloneMeasurementPayload,
  MeasurementChannelKind,
} from '../../../data-access/gql-types/graphql';
import { ON_MEASUREMENT } from '../../../data-access/subscriptions/on-measurement';
import { useApi, useInstallation } from '../../../hooks';
import { ChannelInterface, ChannelMeterInterface } from '../../../types';
import { EcoBar } from '../eco-bar';

interface PropsInterface {
  channel: ChannelInterface;
}

export const EcoRatio: React.FC<PropsInterface> = ({ channel }) => {
  const { measurementMap, convertMeasurementToNumber } = useApi();
  const { skipLavvaFetch } = useInstallation();
  const { data: meterMeasurement } = useSubscription(ON_MEASUREMENT, {
    variables: {
      input: {
        channelId: channel.id,
        kind: MeasurementChannelKind.Meter,
        scalarParameterTypes: [],
        vectorParameterTypes: [
          convertMeasurementToNumber(MeasurementChannelKind.Meter)('vectors', VectorParameterTypeInternal.ActivePower),
        ].filter((x) => x !== 0),
        indices: [],
      },
      skip: !measurementMap || !channel || skipLavvaFetch,
    },
  });

  const isStandalone = useMemo(() => {
    return channel.data.type === ChannelTypeInternal.Meter && channel.data.meterType === MeterTypeInternal.Standalone;
  }, [channel]);

  if (!isStandalone) return null;

  const subscriptionValue = useMemo(() => {
    const phaseMeasurements = meterMeasurement?.onMeasurement?.data?.vectorMeasurements;
    const sumOfPhases = sum(phaseMeasurements?.map((phaseMeasurement) => phaseMeasurement.value));

    return +sumOfPhases.toFixed(2);
  }, [meterMeasurement?.onMeasurement?.data?.unixTimestamp]);

  const payload = useMemo(() => {
    if (isStandalone && ((channel.data as ChannelMeterInterface)?.payload as StandaloneMeasurementPayload)) {
      return (channel.data as ChannelMeterInterface)?.payload as StandaloneMeasurementPayload;
    }
  }, [channel, isStandalone]);

  if (payload?.activeEnergy === null && !subscriptionValue) {
    return null;
  }

  return <EcoBar value={subscriptionValue || 0} maxValue={payload?.maxActivePower || 0} className="m-t-4" />;
};
