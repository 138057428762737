import React from 'react';

export const IconLocation: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.71441 2C8.19889 2 6.74543 2.60204 5.6738 3.67368C4.60216 4.74531 4.00012 6.19876 4.00012 7.71429C4.00012 11.1918 6.15956 14.2374 8.60109 16.8511C9.20429 17.4968 10.2245 17.4969 10.8341 16.8571C13.273 14.2973 15.4287 11.4369 15.4287 7.71429C15.4287 6.96388 15.2809 6.22081 14.9937 5.52752C14.7066 4.83423 14.2856 4.2043 13.755 3.67368C13.2244 3.14306 12.5945 2.72214 11.9012 2.43497C11.2079 2.1478 10.4648 2 9.71441 2Z"
      stroke="#15141D"
      strokeLinejoin="round"
    />
    <circle cx="9.71452" cy="7.7144" r="2.66667" stroke="#15141D" strokeLinejoin="bevel" />
  </svg>
);
