import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header, NavHeader, Page } from '../../components';
import { ChannelTypeInternal } from '../../data-access/gql-types/graphql';
import { useDevicesAndChannels } from '../../hooks';

interface PropsInterface {
  onChooseType: (type: ChannelTypeInternal) => void;
}

const ActionChannelsType: React.FC<PropsInterface> = ({ onChooseType }) => {
  const { channelTypesList } = useDevicesAndChannels({
    channelTypes: [
      ChannelTypeInternal.Switch,
      ChannelTypeInternal.Blind,
      ChannelTypeInternal.Light,
    ],
  });
  const { t } = useTranslation('action');
  const { t: tc } = useTranslation('common');

  return (
    <Page
      header={
        <>
          <NavHeader />
          <Header title={t('basic.creatorHeaderStep1')} />
        </>
      }
    >
      {channelTypesList.map((type, index) => (
        <div
          onClick={() => onChooseType(type as ChannelTypeInternal)}
          key={index}
          className="action-create-basic__type"
        >
          {tc(`typesPlural.${type}`)}
        </div>
      ))}
    </Page>
  );
};

export default ActionChannelsType;
