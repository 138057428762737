import { useCallback, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  ChannelTypeInternal,
  MeasurementChannelKind,
  MeterCurrentMeasurementsQueryVariables,
  MeterTypeInternal,
  Query,
  VectorParameterTypeInternal,
} from '../../../../../data-access/gql-types/graphql';
import { METER_CURRENT_MEASUREMENTS } from '../../../../../data-access/queries/meter';
import { useApi, useInstallation } from '../../../../../hooks';
import { ChannelMeterInterface } from '../../../../../types';
import { CurrentHook, CurrentHookParams } from '../types';
import { getParameterAverageValue, getParameterMaxValue, getParameterMinValue } from '../utils';

const kind = MeasurementChannelKind.Meter;

export const useMeterCurrent = ({ channel }: CurrentHookParams): CurrentHook => {
  const { selectedInstallationId, skipLavvaFetch } = useInstallation();
  const { convertMeasurementToNumber } = useApi();

  const { data, loading } = useQuery<Query, MeterCurrentMeasurementsQueryVariables>(METER_CURRENT_MEASUREMENTS, {
    variables: {
      installationId: selectedInstallationId,
      input: {
        channelId: channel?.id,
        deviceId: channel?.deviceId,
        kind,
        indices: [],
        scalarParameterTypes: (channel?.data as ChannelMeterInterface)?.supportedScalarParameters,
        vectorParameterTypes: (channel?.data as ChannelMeterInterface)?.supportedVectorParameters,
      },
    },
    skip: !channel || skipLavvaFetch,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const parameters = useMemo(() => {
    return [
      VectorParameterTypeInternal.ActivePower,
      VectorParameterTypeInternal.Current,
      VectorParameterTypeInternal.Voltage,
    ];
  }, []);

  const selectedParameter = useMemo(() => {
    return parameters[selectedIndex];
  }, [selectedIndex, parameters]);

  const changeSelectedParameter = useCallback(() => {
    if (selectedIndex === parameters.length - 1) {
      setSelectedIndex(0);
    } else {
      setSelectedIndex(selectedIndex + 1);
    }
  }, [selectedParameter]);

  const totalForwardActiveEnergy = useMemo(() => {
    return data?.meterCurrentMeasurements?.data?.vectorMeasurements.find(
      (measurement) =>
        measurement.type ===
        convertMeasurementToNumber(kind)('vectors', VectorParameterTypeInternal.ForwardActiveEnergy),
    )?.value;
  }, [data?.meterCurrentMeasurements?.data?.scalarMeasurements]);

  const selectedAverageValue = useMemo(() => {
    return (
      data?.meterCurrentMeasurements?.data?.scalarMeasurements.find((measurement) => {
        const scalarVal = getParameterAverageValue(selectedParameter);

        return scalarVal ? measurement.type === convertMeasurementToNumber(kind)('scalars', scalarVal) : 0;
      })?.value ?? 0
    );
  }, [data?.meterCurrentMeasurements?.data?.scalarMeasurements, selectedParameter]);

  const selectedMaxValue = useMemo(() => {
    return (
      data?.meterCurrentMeasurements?.data?.scalarMeasurements.find((measurement) => {
        const scalarVal = getParameterMaxValue(selectedParameter);

        return scalarVal ? measurement.type === convertMeasurementToNumber(kind)('scalars', scalarVal) : 0;
      })?.value ?? 0
    );
  }, [data?.meterCurrentMeasurements?.data?.scalarMeasurements, selectedParameter]);

  const selectedMinValue = useMemo(() => {
    return (
      data?.meterCurrentMeasurements?.data?.scalarMeasurements.find((measurement) => {
        const scalarVal = getParameterMinValue(selectedParameter);

        return scalarVal ? measurement.type === convertMeasurementToNumber(kind)('scalars', scalarVal) : 0;
      })?.value ?? 0
    );
  }, [data?.meterCurrentMeasurements?.data?.scalarMeasurements, selectedParameter]);

  const selectedSinglePhaseMeasurements = useMemo(() => {
    return data?.meterCurrentMeasurements?.data?.vectorMeasurements.filter(
      (measurement) => measurement.type === convertMeasurementToNumber(kind)('vectors', selectedParameter),
    );
  }, [data?.meterCurrentMeasurements, selectedParameter]);

  const percentageRatioActualToMaximum = useMemo(() => {
    const sum = selectedSinglePhaseMeasurements?.reduce((prev, curr) => prev + curr.value, 0) || 0;
    const percentageValue = (sum / selectedMaxValue) * 100;
    if (percentageValue > 100) {
      return 100;
    } else if (percentageValue < 0) {
      return 0;
    }
    return percentageValue || 0;
  }, [data?.meterCurrentMeasurements, selectedParameter]);

  const isActivePowerSelected = useMemo(() => {
    return selectedParameter === VectorParameterTypeInternal.ActivePower;
  }, [selectedParameter]);

  const isStandalone = useMemo(() => {
    return channel?.data.type === ChannelTypeInternal.Meter && channel?.data.meterType === MeterTypeInternal.Standalone;
  }, [channel]);

  return {
    data: data?.meterCurrentMeasurements?.data,
    isLoading: loading,
    totalForwardActiveEnergy,
    selectedAverageValue,
    selectedMaxValue,
    selectedMinValue,
    percentageRatioActualToMaximum,
    selectedParameter,
    changeSelectedParameter,
    isActivePowerSelected,
    selectedSinglePhaseMeasurements,
    isStandalone,
  };
};
