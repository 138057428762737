import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { ChannelBox, Page } from '../../../../components';
import { IconAddDevice } from '../../../../components/icons/add-device';
import { useDevicesAndChannels } from '../../../../hooks';
import { ROUTES } from '../../../../routes';
import { useAddDevice } from '../../hooks/use-add-device';
import './index.scss';

const AddSuccess: React.FC = () => {
  const history = useHistory();
  const { channelId } = useParams<{ channelId: string }>();
  const { t } = useTranslation('device-add');
  const { channel } = useDevicesAndChannels({ channelId });
  const { addDevice } = useAddDevice();

  const nextStep = () => history.push(ROUTES.Channel());

  return (
    <Page
      className="add-success"
      {...(channel
        ? {
            header: (
              <div className="fixed-device-box">
                <ChannelBox channel={channel} isListItem />
              </div>
            ),
          }
        : {})}
    >
      <div className="icon-container">
        <IconAddDevice />
      </div>
      <div className="text-container">
        <p className="text-header">{t('addDeviceSuccess.header')}</p>
        <p>{t('addDeviceSuccess.message')}</p>
      </div>
      <div className="success-buttons">
        <button className="button button--secondary" onClick={addDevice}>
          {t('addDeviceSuccess.addNext')}
        </button>
        <button className="text-button" onClick={nextStep}>
          {t('addDeviceSuccess.ready')}
        </button>
      </div>
    </Page>
  );
};

export default AddSuccess;
