import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ManuallyPairedDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { EmptyStateBox, IconWarning } from '../../../../components';
import { useBackdropContext } from '../../../../hooks';
import { useExalusServicesContext } from '../../context/services';
import ManuallyDevice from './components/found-device/manually';
import { useManuallyPaired } from './hooks/use-manually-paired';

const ManuallyPaired: React.FC = () => {
  const { t } = useTranslation('device-add');
  const { devicesApi } = useExalusServicesContext();
  const { data, listenToDeviceRegistered } = useManuallyPaired();
  const { backdropOpen } = useBackdropContext();

  useEffect(() => {
    devicesApi().OnDeviceRegisteredEvent().Subscribe(listenToDeviceRegistered);

    return () => {
      devicesApi().OnDeviceRegisteredEvent().Unsubscribe(listenToDeviceRegistered);
    };
  }, []);

  return (
    <div className="grouped-list grid-list-24">
      {(data || []).length > 0 ? (
        data?.map((device: ManuallyPairedDevice) => <ManuallyDevice key={device.ModelGuid} device={device} />)
      ) : (
        <>{!backdropOpen && <EmptyStateBox content={t('exalus.emptyListContent')} icon={<IconWarning />} />}</>
      )}
    </div>
  );
};

export default ManuallyPaired;
