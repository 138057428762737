import { useEffect, useState } from 'react';
import { ChatType, IMessage } from 'lavva.exalushome/build/js/Services/Chat/IChatService';
import { useExalusServicesContext } from '../../../context/services';
import { useExalusFamilyChatMessages } from '../../../hooks/chat.hooks';
import { ChatMessage } from '../types';
import { useChat } from './use-chat';

export const useFamilyChat = () => {
  const [familyChat, setFamilyChat] = useState<ChatMessage<IMessage>[]>([]);
  const { data } = useExalusFamilyChatMessages();
  const { chatApi } = useExalusServicesContext();
  const { mapMessages, messageHandler, sendMessage } = useChat<IMessage>(familyChat);

  useEffect(() => {
    const chat = chatApi().GetChatImplementation(ChatType.FamilyChat);

    chat.SubscribeToMessages<IMessage>((message) => messageHandler<IMessage>(message, setFamilyChat));
    return () => {
      chat.UnsubscribeFromMessages<IMessage>((message) => messageHandler<IMessage>(message, setFamilyChat));
    };
  }, []);

  useEffect(() => {
    if (data) setFamilyChat(mapMessages<IMessage>(data));
  }, [data]);

  return {
    familyChat,
    sendFamilyMessage: sendMessage,
  };
};
