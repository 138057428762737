import React from 'react';
import './index.scss';

interface ComponentProps {
  title: string;
  onClick?: () => void;
  icon?: React.ReactNode;
}

const Info: React.FC<ComponentProps> = ({ title, icon, onClick }) => (
  <div className="configuration_info max-width-desktop">
    <p>{title}</p>
    {onClick && (
      <>
        {icon ? (
          <button type="button" onClick={onClick}>
            {icon}
          </button>
        ) : (
          <button className="configuration_info__icon" type="button" onClick={onClick}>
            ?
          </button>
        )}
      </>
    )}
  </div>
);

export default Info;
