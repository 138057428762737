import React, { Dispatch, SetStateAction, createContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { NativeContext as NtxContext } from 'lavva.webview/build/js/Services/Common/ICommonService';
import * as uuid from 'uuid';
import { useLazyQuery } from '@apollo/client';
import {
  PushReceiverRegistration,
  PushReceiversRegistrationsQuery,
  PushReceiversRegistrationsQueryVariables,
} from '../../data-access/gql-types/graphql';
import { MOBILE_REGISTRATIONS } from '../../data-access/queries/register';
import { isLocalApp } from '../../utils/helpers/local-app';
import { getBrowserName } from '../../utils/helpers/operation-system';
import { usePushNotification } from '../use-push-notification';
import { useProfile } from '../user';
import { useNativeFunctionsContext } from './native-functions';
import { useVibrationManager } from './vibration-manager';

const initialState: useNativeInterface = {
  setVibration: () => null,
  forceVibration: () => null,
  showBluetoothNotSupportedDialog: false,
  setShowBluetoothNotSupportedDialog: () => null,
  showNotificationPopup: false,
  setShowNotificationPopup: () => null,
  registerNotificationToken: () => null,
  handleVibration: () => null,
};

export interface MessageOptions {
  onSuccess?: () => void;
  onFailed?: () => void;
  showNativeError?: boolean;
}

export const NativeContext = createContext(initialState);

export interface useNativeInterface {
  setVibration: (value: boolean) => void;
  forceVibration: () => void;
  showBluetoothNotSupportedDialog: boolean;
  setShowBluetoothNotSupportedDialog: Dispatch<SetStateAction<boolean>>;
  showNotificationPopup: boolean;
  setShowNotificationPopup: Dispatch<SetStateAction<boolean>>;
  handleVibration: (deviceId: string) => void;
  registerNotificationToken: (permission: string) => void;
}

const NativeProvider: React.FC = ({ children }) => {
  const { user } = useProfile();
  const { requestForPushToken, requestForNativeToken } = usePushNotification();
  const [getPushRegistrations] = useLazyQuery<
    PushReceiversRegistrationsQuery,
    PushReceiversRegistrationsQueryVariables
  >(MOBILE_REGISTRATIONS, {
    fetchPolicy: 'no-cache',
  });
  const [showBluetoothNotSupportedDialog, setShowBluetoothNotSupportedDialog] = useState<boolean>(false);
  const [showNotificationPopup, setShowNotificationPopup] = useState<boolean>(false);
  const { handleVibration, setVibration, forceVibration } = useVibrationManager();
  const { getNativeContext, nativeInit } = useNativeFunctionsContext();

  const getDeviceName = () => {
    let names: Record<string, string> = {};
    const newDeviceName = `${getBrowserName()}-${uuid.v4()}`;
    const devicesNames = Cookies.get('devicesNames');

    if (devicesNames) {
      const devicesNamesParsed: Record<string, string> = JSON.parse(devicesNames);
      names = { ...devicesNamesParsed };
      if (!devicesNamesParsed[user.profile.id]) names = { ...devicesNamesParsed, [user.profile.id]: newDeviceName };
    } else names = { [user.profile.id]: newDeviceName };

    Cookies.set('devicesNames', JSON.stringify(names));

    return names[user.profile.id];
  };

  const registerNotificationToken = async (permission: string) => {
    const nativeCtx = await getNativeContext();
    const pushRegistrations = await getPushRegistrations();

    if (
      pushRegistrations.data?.allPushReceiversRegistrations &&
      user.profile.id &&
      nativeCtx === NtxContext.None &&
      !isLocalApp
    ) {
      const deviceName = getDeviceName();

      requestForPushToken(
        pushRegistrations.data
          ? (pushRegistrations.data.allPushReceiversRegistrations as PushReceiverRegistration[])
          : [],
        deviceName,
        permission,
      );
    }
  };

  useEffect(() => {
    const generateDeviceToken = async () => {
      const nativeCtx = await getNativeContext();

      if (!isLocalApp && (nativeCtx === NtxContext.AndroidGecko || nativeCtx === NtxContext.IosWebkit)) {
        const deviceName = getDeviceName();
        requestForNativeToken(deviceName, nativeCtx);
      }
    };

    if (nativeInit) generateDeviceToken();
  }, [nativeInit]);

  const values = {
    setVibration,
    forceVibration,
    handleVibration,
    showBluetoothNotSupportedDialog,
    setShowBluetoothNotSupportedDialog,
    showNotificationPopup,
    setShowNotificationPopup,
    registerNotificationToken,
  };

  return <NativeContext.Provider value={values}>{children}</NativeContext.Provider>;
};

export default NativeProvider;
