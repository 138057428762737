import { PointerEvent } from 'react';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { GateActionEnum, SetGatePositionPrecise } from 'lavva.exalushome/build/js/Services/Devices/Tasks/Tasks';
import { GatePreciseControlHook } from '../types';

export const useGatePreciseControl = (channel?: IDeviceChannel): GatePreciseControlHook => {
  const open = async () => {
    const task = new SetGatePositionPrecise();
    task.GateControlAction = GateActionEnum.Open;
    await channel?.ExecuteTaskAsync(task);
  };

  const close = async () => {
    const task = new SetGatePositionPrecise();
    task.GateControlAction = GateActionEnum.Close;
    await channel?.ExecuteTaskAsync(task);
  };

  const stop = async () => {
    const task = new SetGatePositionPrecise();
    task.GateControlAction = GateActionEnum.Stop;
    await channel?.ExecuteTaskAsync(task);
  };

  const handleAction = async (action: GateActionEnum, position?: number) => {
    const task = new SetGatePositionPrecise();
    task.GateControlAction = action;
    if (typeof position === 'number' && position >= 0) {
      task.Position = position;
    }
    await channel?.ExecuteTaskAsync(task);
  };

  const handleSliderEvent = (event: PointerEvent<HTMLInputElement>) => {
    handleAction(GateActionEnum.Percentage, Number((event.target as HTMLInputElement).value));
  };

  return {
    open,
    close,
    stop,
    handleAction,
    handleSliderEvent,
  };
};
