import { useInstallation } from '../../../../hooks';
import { ROUTES } from '../../../../routes';
import { useExalusServicesContext } from '../../../exalus/context/services';
import { removeExalusCredentials } from '../../../exalus/utils';

export const useLogoutExalus = () => {
  const { sessionApi } = useExalusServicesContext();
  const { selectedInstallationId } = useInstallation();

  const handleLogoutExalus = async () => {
    await sessionApi().UserLogOutAsync();
    if (selectedInstallationId) removeExalusCredentials(selectedInstallationId);

    location.href = ROUTES.Installation();
  };

  return {
    handleLogoutExalus,
  };
};
