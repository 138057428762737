import React, { ReactNode } from 'react';
import c from 'classnames';
import { IconChevron } from '../icons';
import './index.scss';

interface ComponentProps {
  title?: string;
  subTitle?: string;
  upper?: ReactNode;
  onClick?: () => void;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  icon?: ReactNode;
  arrowIcon?: ReactNode;
  className?: string;
  small?: boolean;
  iconAccent?: boolean;
  type?: 'normal' | 'delete';
  dark?: boolean;
}

const ArrowButton: React.FC<ComponentProps> = ({
  title,
  onClick,
  label,
  disabled,
  icon,
  arrowIcon,
  placeholder = '',
  subTitle = '',
  upper = '',
  className,
  small,
  iconAccent,
  dark,
  type = 'normal',
}) => (
  <div
    className={c('arrow_button max-width-desktop', {
      disabled,
      [className as string]: className,
      small: small,
      delete: type === 'delete',
    })}
  >
    {label && <label className="arrow_label">{label}</label>}
    <button
      className={c('arrow_btn', { small: small, 'not-clickable': !onClick, dark: dark })}
      type="button"
      onClick={onClick}
    >
      <div className="left_section">
        {icon && <div className={c('arrow_button--icon', { accent: iconAccent })}>{icon}</div>}
        <div>
          {upper && upper}
          <p className={c('', { placeholder: !title })}>{title ? title : placeholder}</p>
          {subTitle && <p className="sub_title">{subTitle}</p>}
        </div>
      </div>
      {arrowIcon || <IconChevron direction="right" noSpace />}
    </button>
  </div>
);

export default ArrowButton;
