import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { VectorMeasurementResponseItem } from '../../../data-access/gql-types/graphql';
import { SelectedPhase } from '../../../modules/channel-details/measurement/types';
import { ecoLevels } from '../../../modules/channel-details/meter/current/utils';
import { IconArrowLink } from '../../icons';
import { IconTip } from '../../icons/icon-tip';
import { DonutChart } from '../chart';
import './index.scss';

interface PropsInterface {
  lazyValue: number;
  value: number;
  averageValue: number;
  percentageRatioActualToMaximum: number;
  maxScaleValue: number;
  label: string;
  unit: string;
  showEco: boolean;
  phaseMeasurements: VectorMeasurementResponseItem[] | undefined;
  selectedPhases: SelectedPhase[];
  changeSelectedParameter?: () => void;
  noFixedScaleValues?: boolean;
  ticks?: boolean;
  indicator?: boolean;
  axis?: boolean;
  color?: string;
  arrowDirection?: 'up' | 'down';
  fixed?: number;
  arrow?: boolean;
  clickIndicator?: boolean;
}

export const DonutChartWrapper: React.FC<PropsInterface> = ({
  lazyValue,
  value,
  averageValue,
  percentageRatioActualToMaximum,
  maxScaleValue,
  label,
  unit,
  changeSelectedParameter,
  showEco = false,
  phaseMeasurements,
  selectedPhases,
  children,
  noFixedScaleValues,
  ticks = true,
  indicator = true,
  axis = true,
  color,
  arrowDirection,
  arrow = true,
  fixed,
  clickIndicator = false,
}) => {
  const [clickableTip, setClickableTip] = useState<boolean>(!!clickIndicator);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setClickableTip(false);
    }, 8000);

    return () => clearTimeout(timeout);
  }, []);

  const getFixedValue = (value) => {
    if (noFixedScaleValues) return value.toFixed(3);
    const power = Math.floor(value).toString().length;
    return power > 1 ? +value.toFixed(0) : +value.toFixed(1);
  };

  const scaleValues = useMemo(
    () => [
      0,
      getFixedValue(maxScaleValue * 0.25),
      getFixedValue(maxScaleValue * 0.5),
      getFixedValue(maxScaleValue * 0.75),
      getFixedValue(maxScaleValue),
    ],
    [maxScaleValue, noFixedScaleValues],
  );

  const style = useMemo(() => {
    return showEco
      ? ({
          '--shadow': `3px 3px 15px rgba(${
            ecoLevels.find((level) => percentageRatioActualToMaximum <= level.maxValue)?.rgbValue
          }, 0.3)`,
        } as React.CSSProperties)
      : ({
          '--shadow': `5px 5px 15px rgba(0, 0, 0, 0.15)`,
        } as React.CSSProperties);
  }, [showEco, percentageRatioActualToMaximum]);

  const elementStyle = useMemo(() => {
    return selectedPhases.length > 1 || !selectedPhases.length
      ? null
      : ({
          '--color': selectedPhases[0]?.color,
        } as React.CSSProperties);
  }, [selectedPhases]);

  return (
    <div className="donut-chart-wrapper">
      <div className="donut-chart-wrapper__donut">
        <DonutChart
          value={lazyValue}
          maxScaleValue={maxScaleValue}
          averageValue={averageValue}
          phaseMeasurements={phaseMeasurements}
          selectedPhases={selectedPhases}
          ticks={ticks}
          indicator={indicator}
          color={color}
        />
      </div>
      {axis && (
        <div className="donut-chart-wrapper__axis">
          {scaleValues.map((scaleValue, index) => (
            <div
              {...(elementStyle ? { style: elementStyle } : {})}
              className={classNames('donut-chart-wrapper__axis-element', {
                'donut-chart-wrapper__axis-element--active': scaleValue <= lazyValue,
              })}
              key={index}
            >
              {scaleValue}
            </div>
          ))}
        </div>
      )}
      <div className="donut-chart-wrapper__donut-element">
        <div className="donut-chart-wrapper__donut-element-bg" style={style} />
        <div className="donut-chart-wrapper__donut-element-info" style={style} onClick={changeSelectedParameter}>
          {label && <div className="donut-chart-wrapper__donut-element-text">{label}</div>}
          <div className="donut-chart-wrapper__donut-element-wrapper">
            <div className="donut-chart-wrapper__donut-element-value">
              {value.toFixed(fixed !== undefined ? fixed : 1)}
            </div>
            <div className="donut-chart-wrapper__donut-element-unit">{unit}</div>
          </div>
          {arrow && (
            <div
              className={classNames('donut-chart-wrapper__donut-element-icon', {
                up: arrowDirection === 'up',
                down: arrowDirection === 'down',
              })}
            >
              <IconArrowLink className="icon-arrow-link--accent" size={22} />
            </div>
          )}
        </div>
        {clickableTip && <IconTip pulse />}
      </div>
      {children}
    </div>
  );
};
