import React, { createContext, useContext } from 'react';
import { Api } from 'lavva.exalushome';
import { AppStateService } from 'lavva.exalushome/build/js/Services/AppStateService';
import { ChatService } from 'lavva.exalushome/build/js/Services/Chat/ChatService';
import { IChatService } from 'lavva.exalushome/build/js/Services/Chat/IChatService';
import { ControllerConfigurationService } from 'lavva.exalushome/build/js/Services/Controller/ControllerConfigurationService';
import { GeolocationService } from 'lavva.exalushome/build/js/Services/Controller/GeolocationService';
import { IControllerConfigurationService } from 'lavva.exalushome/build/js/Services/Controller/IControllerConfigurationService';
import { IGeolocationService } from 'lavva.exalushome/build/js/Services/Controller/IGeolocationService';
import { ChannelsGroupsService } from 'lavva.exalushome/build/js/Services/Devices/ChannelsGroupsService';
import { DevicesService } from 'lavva.exalushome/build/js/Services/Devices/DevicesService';
import { IChannelsGroupsService } from 'lavva.exalushome/build/js/Services/Devices/IChannelsGroupsService';
import { IDevicesService } from 'lavva.exalushome/build/js/Services/Devices/IDevicesService';
import { ExalusConnectionService } from 'lavva.exalushome/build/js/Services/ExalusConnectionService';
import { IAppStateService } from 'lavva.exalushome/build/js/Services/IAppStateService';
import { IExalusConnectionService } from 'lavva.exalushome/build/js/Services/IExalusConnectionService';
import { IRemoteStorageService } from 'lavva.exalushome/build/js/Services/IRemoteStorageService';
import { ILoggerService } from 'lavva.exalushome/build/js/Services/Logging/ILoggerService';
import { LoggerService } from 'lavva.exalushome/build/js/Services/Logging/LoggerService';
import { RemoteStorageService } from 'lavva.exalushome/build/js/Services/RemoteStorageService';
import { IScenesService } from 'lavva.exalushome/build/js/Services/Scenes/IScenesService';
import { ScenesService } from 'lavva.exalushome/build/js/Services/Scenes/ScenesService';
import { ISessionService } from 'lavva.exalushome/build/js/Services/Session/ISessionService';
import { SessionService } from 'lavva.exalushome/build/js/Services/Session/SessionService';
import { IStatesHistoryService } from 'lavva.exalushome/build/js/Services/StatesHistory/IStatesHistoryService';
import { StatesHistoryService } from 'lavva.exalushome/build/js/Services/StatesHistory/StatesHistoryService';
import { ISupportedDevicesService } from 'lavva.exalushome/build/js/Services/SupportedDevices/ISupportedDevicesService';
import { SupportedDevicesService } from 'lavva.exalushome/build/js/Services/SupportedDevices/SupportedDevicesService';
import { IUsersService } from 'lavva.exalushome/build/js/Services/Users/IUsersService';
import { UsersService } from 'lavva.exalushome/build/js/Services/Users/UsersService';

interface ExalusServicesProviderInterface {
  devicesApi: () => IDevicesService;
  groupsApi: () => IChannelsGroupsService;
  logApi: () => ILoggerService;
  sessionApi: () => ISessionService;
  connectionApi: () => IExalusConnectionService;
  geolocationApi: () => IGeolocationService;
  usersApi: () => IUsersService;
  historyStatesApi: () => IStatesHistoryService;
  appStateApi: () => IAppStateService;
  configurationApi: () => IControllerConfigurationService;
  remoteStorageApi: () => IRemoteStorageService;
  supportedDevicesApi: () => ISupportedDevicesService;
  scenesApi: () => IScenesService;
  chatApi: () => IChatService;
}

export const ExalusServicesContext = createContext<ExalusServicesProviderInterface>(
  {} as ExalusServicesProviderInterface,
);

export const useExalusServicesContext = (): ExalusServicesProviderInterface => useContext(ExalusServicesContext);

export const ExalusServicesContextProvider: React.FC = ({ children }) => {
  const devicesApi = () => Api.Get<IDevicesService>(DevicesService.ServiceName);
  const groupsApi = () => Api.Get<IChannelsGroupsService>(ChannelsGroupsService.ServiceName);
  const scenesApi = () => Api.Get<IScenesService>(ScenesService.ServiceName);
  const logApi = () => {
    const log = Api.Get<ILoggerService>(LoggerService.ServiceName);
    log.DisableLogsFrom(DevicesService.ServiceName);
    log.DisableLogsFrom(ChannelsGroupsService.ServiceName);
    log.DisableLogsFrom(LoggerService.ServiceName);
    log.DisableLogsFrom(SessionService.ServiceName);
    log.DisableLogsFrom(ExalusConnectionService.ServiceName);
    log.DisableLogsFrom(GeolocationService.ServiceName);
    log.DisableLogsFrom(UsersService.ServiceName);
    log.DisableLogsFrom(AppStateService.ServiceName);
    log.DisableLogsFrom(ControllerConfigurationService.ServiceName);

    return log;
  };
  const sessionApi = () => Api.Get<ISessionService>(SessionService.ServiceName);
  const connectionApi = () => {
    const connection = Api.Get<IExalusConnectionService>(ExalusConnectionService.ServiceName);
    connection.DisablePacketsLogging();

    return connection;
  };
  const geolocationApi = () => Api.Get<IGeolocationService>(GeolocationService.ServiceName);
  const usersApi = () => Api.Get<IUsersService>(UsersService.ServiceName);
  const historyStatesApi = () => Api.Get<IStatesHistoryService>(StatesHistoryService.ServiceName);
  const appStateApi = () => Api.Get<IAppStateService>(AppStateService.ServiceName);
  const configurationApi = () => Api.Get<IControllerConfigurationService>(ControllerConfigurationService.ServiceName);
  const remoteStorageApi = () => Api.Get<IRemoteStorageService>(RemoteStorageService.ServiceName);
  const supportedDevicesApi = () => Api.Get<ISupportedDevicesService>(SupportedDevicesService.ServiceName);
  const chatApi = () => Api.Get<IChatService>(ChatService.ServiceName);
  // (logApi as LoggerService).EnableStackTrace();

  const values: ExalusServicesProviderInterface = {
    devicesApi,
    groupsApi,
    logApi,
    sessionApi,
    connectionApi,
    geolocationApi,
    usersApi,
    historyStatesApi,
    appStateApi,
    configurationApi,
    remoteStorageApi,
    supportedDevicesApi,
    scenesApi,
    chatApi,
  };

  return <ExalusServicesContext.Provider value={values}>{children}</ExalusServicesContext.Provider>;
};
