import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ChannelTypeInternal,
  MeterTypeInternal,
  StandaloneMeasurementPayload,
} from '../../../../data-access/gql-types/graphql';
import { ChannelInterface, ChannelMeterInterface } from '../../../../types';
import './index.scss';

interface PropsInterface {
  channel: ChannelInterface;
}

export const MeterControl: React.FC<PropsInterface> = ({ channel }) => {
  const { t } = useTranslation('channel-details');

  const isStandalone = useMemo(() => {
    return channel.data.type === ChannelTypeInternal.Meter && channel.data.meterType === MeterTypeInternal.Standalone;
  }, [channel]);

  if (!isStandalone) {
    return null;
  }

  const value = useMemo(() => {
    if (isStandalone && ((channel.data as ChannelMeterInterface)?.payload as StandaloneMeasurementPayload)) {
      const payload = (channel.data as ChannelMeterInterface)?.payload as StandaloneMeasurementPayload;
      return payload?.activeEnergy;
    }
  }, [channel, isStandalone]);

  if (value === null || !(channel.data as ChannelMeterInterface)?.payload) {
    return null;
  }

  return (
    <div className="meter-control">
      <div className="meter-control__value-wrapper">
        <span className="meter-control__value">{value?.toFixed(0)}</span>
        <span className="meter-control__unit">{t('kwh')}</span>
      </div>
    </div>
  );
};
