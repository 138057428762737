import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { useMutation } from '@apollo/client';
import { IconInitiate, ControlWrapper, InitiateButton } from '../../../components';
import LavvaDetailsWrapper from '../../../components/details-wrapper';
import { defaultFavouriteSetOnTimeList } from '../../../const';
import {
  FavouritePositionType,
  ChannelTypeInternal,
  SetFavouriteChannelPositionsMutation,
  SetFavouriteChannelPositionsMutationVariables,
  DeviceConnectionState,
} from '../../../data-access/gql-types/graphql';
import { CHANNEL_SET_FAVOURITE_POSITIONS } from '../../../data-access/mutations/channels';
import { useChannelsState, useDevicesAndChannels, useSwitch } from '../../../hooks';
import { ChannelInterface, ChannelSwitchInterface } from '../../../types';
import { parseFavouritePositions } from '../../../utils/channels/helpers';
import { toastError, toastSuccess } from '../../../utils/toast';
// import { ChannelDetailsAnalysis } from './analysis';
import { ChannelDetailsMeasurements } from './measurements';
import { TimeActivation } from './time-activation';

interface PropsInterface {
  channel: ChannelInterface;
}

const ChannelDetailsSwitch: React.FC<PropsInterface> = ({ channel }) => {
  const { t } = useTranslation('channel-details');
  const { t: tc } = useTranslation('common');
  const { handleControlOn: onSwitch, isOn: isOnSwitch } = useSwitch({
    channelId: channel.id,
    deviceId: channel.deviceId,
  });
  const { channelList, setChannelList } = useDevicesAndChannels();
  const { channelState } = useChannelsState();
  const [setFavouritePositions] = useMutation<
    SetFavouriteChannelPositionsMutation,
    SetFavouriteChannelPositionsMutationVariables
  >(CHANNEL_SET_FAVOURITE_POSITIONS);

  const favouriteSetOnTimeList = useMemo(() => {
    if (channel.data.type === ChannelTypeInternal.Switch) {
      return parseFavouritePositions(channel.data.favouritePositionList, defaultFavouriteSetOnTimeList);
    }

    return defaultFavouriteSetOnTimeList;
  }, [channel, channelList, setChannelList]);

  const onFavouriteTimeChange = useCallback(
    (value, index) => {
      const newChannelList: ChannelInterface[] = cloneDeep(channelList);
      const channelIndex = newChannelList.findIndex((channelItem) => channelItem && channelItem.id === channel.id);
      const newChannel = { ...newChannelList[channelIndex] } as ChannelInterface;

      const changedSetOnTime = [...favouriteSetOnTimeList];
      changedSetOnTime[index] = value;

      if (channelIndex !== -1) {
        const channelFavouritePositionList = (newChannelList[channelIndex]?.data as ChannelSwitchInterface)
          .favouritePositionList;
        if (channelFavouritePositionList) {
          channelFavouritePositionList[index] = value;
        } else {
          const channelNewFavouriteList: [number, number, number] = defaultFavouriteSetOnTimeList as [
            number,
            number,
            number,
          ];
          channelNewFavouriteList[index] = value;
          (newChannelList[channelIndex]?.data as ChannelSwitchInterface).favouritePositionList =
            channelNewFavouriteList;
        }
      }

      setFavouritePositions({
        variables: {
          input: {
            favouritePositions: changedSetOnTime.map((position) => ({
              favouritePositionType: FavouritePositionType.Switch,
              value: position,
            })),
            channelId: channel?.id,
          },
        },
        onCompleted: (data) => {
          if (data.setFavouriteChannelPositions.ok) {
            newChannelList[channelIndex] = newChannel;
            setChannelList(newChannelList);
            toastSuccess({ content: t('favouriteSetSuccess') });
          } else {
            toastError({ content: tc('errors.somethingWentWrong') });
            // TODO errors
          }
        },
      });
    },
    [channel, channelList, setChannelList],
  );

  const statusOn = useMemo(() => isOnSwitch, [isOnSwitch, channel]);

  const handleControl = useCallback((status: boolean) => onSwitch(status), [onSwitch, channel]);

  return (
    <LavvaDetailsWrapper
      tabs={[{ label: t('currently') }, { label: t('measurement') } /* { label: t('analysis') } */]}
      additionalTabs={
        channel.data.type === ChannelTypeInternal.Switch &&
        channel.data.measuredBy &&
        channelState[channel.id].deviceConnectionState !== DeviceConnectionState.Recovery
          ? [
              <ChannelDetailsMeasurements key={2} channel={channel} measurementChannel={channel.data.measuredBy} />,
              // <ChannelDetailsAnalysis key={3} channel={channel} measurementChannel={channel.data.measuredBy} />,
            ]
          : []
      }
    >
      <ControlWrapper>
        <InitiateButton isOn={statusOn} onClickControl={handleControl}>
          <IconInitiate />
        </InitiateButton>
      </ControlWrapper>
      <div className="p-l-24 p-r-24">
        <h3 className="m-t-16 m-b-16">{t('timeActivation')}</h3>
        <div className="favourite">
          <div className="favourite__buttons favourite__buttons--switch">
            {favouriteSetOnTimeList.map((value, index) => {
              return (
                <TimeActivation
                  channel={channel}
                  key={index}
                  value={value}
                  index={index}
                  onFavouriteTimeChange={onFavouriteTimeChange}
                />
              );
            })}
          </div>
        </div>
      </div>
    </LavvaDetailsWrapper>
  );
};

export default ChannelDetailsSwitch;
