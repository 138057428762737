import React from 'react';

export const IconEye: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.5 9.98214C17.5 9.98214 14.0302 14.9643 9.75 14.9643C5.46979 14.9643 2 9.98214 2 9.98214C2 9.98214 5.46979 5 9.75 5C14.0302 5 17.5 9.98214 17.5 9.98214Z"
      stroke="#15141D"
    />
    <circle cx="9.74999" cy="10.1667" r="1.47619" fill="#15141D" />
  </svg>
);
