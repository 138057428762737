import React, { useMemo } from 'react';
import { ControlWrapper, CustomBackdrop, DatePicker, InputSelect, Summary, TimeRanges } from '../../../../components';
import { ChannelInterface, ValueEntry } from '../../../../types';
import { PhasesButtonGroup } from '../../measurement/components/phases-button-group';
import { conditionallySliceArray } from '../../measurement/utils';
import { AnalysisBarChart } from './components/chart';
import { useMeterAnalysis } from './hooks/use-meter-analysis';

interface PropsInterface {
  channel: ChannelInterface;
}

export interface ChartDataItem {
  time?: string;
  tooltipTime?: string;
  values: ChartDataValues[];
}

export interface ChartDataValues extends ValueEntry {
  color: string;
}

export const MeterAnalysis: React.FC<PropsInterface> = ({ channel }) => {
  const {
    data,
    isLoading,
    selectedPhases,
    setSelectedPhases,
    mappedChartData,
    mappedSummaryData,
    onClickActiveMeasurementRange,
    handleOnChange,
    activeDay,
    activeAggregatedParameter,
    activeMeasurementRange,
    options,
  } = useMeterAnalysis({ channel });

  const defaultPhases = useMemo(() => {
    const phases = data?.aggregateVectorMeasurements?.map((measurement) => measurement.index);
    return [0, ...(phases ?? [])];
  }, [data]);

  if (isLoading && !data) {
    return <CustomBackdrop loading />;
  }

  return (
    <>
      <ControlWrapper className="control-wrapper--column  control-wrapper--reverse p-l-24 p-r-24 p-b-16">
        <InputSelect
          options={options}
          value={activeAggregatedParameter}
          onChange={handleOnChange}
          className="input--reverse m-b-32"
          label={''}
        />
        <TimeRanges
          activeMeasurementRange={activeMeasurementRange}
          setActiveMeasurementRange={onClickActiveMeasurementRange}
        />
        <DatePicker
          showDateButtons={false}
          activeDay={activeDay}
          activeMeasurementRange={activeMeasurementRange}
          setActiveDay={() => null}
        />
        <AnalysisBarChart
          data={conditionallySliceArray(mappedChartData, activeMeasurementRange)}
          activeMeasurementRange={activeMeasurementRange}
          selectedPhases={selectedPhases}
          isLoading={isLoading}
        />
        {data?.aggregateVectorMeasurements ? (
          <PhasesButtonGroup onSelectedPhases={setSelectedPhases} defaultPhases={defaultPhases} />
        ) : null}
      </ControlWrapper>
      <Summary
        summaryData={conditionallySliceArray(mappedSummaryData, activeMeasurementRange)}
        activeMeasurementRange={activeMeasurementRange}
        selectedPhases={selectedPhases}
      />
    </>
  );
};
