import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ActionChannelSort from '../modules/action-channels-sort';
import ActionCreateBasic from '../modules/action-create-basic';
import ActionDetails from '../modules/action-details';
import ActionEditChannels from '../modules/action-edit-channels';
import ActionEditName from '../modules/action-edit-name';
import MeasurementDetails from '../modules/channel-details/meter/measurements/details';
import DeviceAddBluetooth from '../modules/device-add/bluetooth';
import DeviceAddBluetoothIdentify from '../modules/device-add/bluetooth/identify';
import DeviceAddBluetoothNetwork from '../modules/device-add/bluetooth/network';
import DeviceAddBluetoothSuccess from '../modules/device-add/bluetooth/success';
import DeviceAddBluetoothUpdate from '../modules/device-add/bluetooth/update';
import DeviceUpdateBluetoothSuccess from '../modules/device-add/bluetooth/update-success';
import DeviceAddBluetoothSetWifiSuccess from '../modules/device-add/bluetooth/wifi-success';
import WisniowskiDriveAddStart from '../modules/device-add/bluetooth/wisniowski/drive-add-start';
import WisniowskiDriveAddSuccess from '../modules/device-add/bluetooth/wisniowski/drive-add-success';
import WisniowskiDriveAddTutorialStep1 from '../modules/device-add/bluetooth/wisniowski/drive-add-tutorial/step-1';
import WisniowskiDriveAddTutorialStep2 from '../modules/device-add/bluetooth/wisniowski/drive-add-tutorial/step-2';
import CalibrateChannel from '../modules/device-add/components/calibrate';
import ReverseInputs from '../modules/device-add/components/reverse-inputs';
import ReverseOutputs from '../modules/device-add/components/reverse-outputs';
import AddSuccess from '../modules/device-add/components/success';
import UpdateSoftware from '../modules/device-add/components/update';
import DeviceBindings from '../modules/device-settings/device-bindings';
import DeviceWifiConfiguration from '../modules/device-wifi-configuration';
import ExalusActionCreateConditions from '../modules/exalus/pages/action-create/conditions';
import ExalusActionSources from '../modules/exalus/pages/action-create/conditions/action-sources';
import ExalusDeviceParameters from '../modules/exalus/pages/action-create/conditions/action-sources/device-parameters';
import ExalusDeviceSource from '../modules/exalus/pages/action-create/conditions/action-sources/device-source';
import ExalusScenarioSource from '../modules/exalus/pages/action-create/conditions/action-sources/scenario-source';
import ExalusScheduleSource from '../modules/exalus/pages/action-create/conditions/action-sources/schedule-source';
import ExalusActionConditionsTypes from '../modules/exalus/pages/action-create/conditions/conditions-types';
import ExalusActionTaskDelay from '../modules/exalus/pages/action-create/tasks/task-variants/delay-view';
import ExalusActionTaskLock from '../modules/exalus/pages/action-create/tasks/task-variants/lock-view';
import ExalusActionDetails from '../modules/exalus/pages/action-details';
import AddDevices from '../modules/exalus/pages/add-devices';
import CentralActionByTypeDetails from '../modules/exalus/pages/central-action-by-type-details';
import CentralActionDetails from '../modules/exalus/pages/central-action-details';
import BlindAutoCalibration from '../modules/exalus/pages/channel-configuration/forms/common/auto-calibration';
import DeviceRemotes from '../modules/exalus/pages/channel-configuration/forms/common/device-remotes';
import DeviceUpdate from '../modules/exalus/pages/channel-configuration/forms/common/update';
import Srp22Calibration from '../modules/exalus/pages/channel-configuration/forms/srp/calibration-srp22';
import ZfBidiCalibration from '../modules/exalus/pages/channel-configuration/forms/zf-bidi/calibration';
import Chat from '../modules/exalus/pages/chat';
import ExalusControllerSettings from '../modules/exalus/pages/controller-settings';
import CreateAccountOffer from '../modules/exalus/pages/dashboard/components/create-account-popup';
import ExalusIntegrationCreate from '../modules/exalus/pages/integration-create';
import ExalusIntegrationLogin from '../modules/exalus/pages/integration-login';
import ExalusQRCodeAuth from '../modules/exalus/pages/integration-qr-auth';
import ExalusLogin from '../modules/exalus/pages/login';
import ExalusAddDns from '../modules/exalus/pages/network/add-dns';
import ExalusNetworkSettings from '../modules/exalus/pages/network/network-settings';
import ExalusNetworkStaticSettings from '../modules/exalus/pages/network/static-settings';
import ExalusStore from '../modules/exalus/pages/store';
import StoreDeviceDetails from '../modules/exalus/pages/store-device-details';
import UserManual from '../modules/exalus/pages/user-manual';
import ExalusUsersList from '../modules/exalus/pages/users-list';
import ExalusChangePassword from '../modules/exalus/pages/users-list/change-password';
import ExalusUserCreate from '../modules/exalus/pages/users-list/create-user';
import ExalusUserEdit from '../modules/exalus/pages/users-list/edit-user';
import ExalusUserDetails from '../modules/exalus/pages/users-list/user-details';
import InstallationCreate from '../modules/installation-create';
import IntegrationsList from '../modules/installation-create/integration-list';
import InstallationCreated from '../modules/installation-created';
import InstallationList from '../modules/installation-list';
import InstallationSettings from '../modules/installation-settings';
import RemoveInstallation from '../modules/installation-settings/installation/installation-remove';
import ShareAccessCount from '../modules/installation-share/access-count';
import ShareAccessTime from '../modules/installation-share/access-time';
import InstallationSummaryForward from '../modules/installation-share/forward-summary-installation';
import ChannelsShare from '../modules/installation-share/share-channels';
import ChannelsShareEdit from '../modules/installation-share/share-channels-edit';
import ShareEmail from '../modules/installation-share/share-email';
import GroupsShare from '../modules/installation-share/share-groups';
import GroupsShareEdit from '../modules/installation-share/share-groups-edit';
import ShareRestriction from '../modules/installation-share/share-restriction';
import ShareRole from '../modules/installation-share/share-role';
import InstallationShareSubject from '../modules/installation-share/share-subject';
import SharedInstallations from '../modules/installation-share/shared-installation';
import SharedUser from '../modules/installation-share/shared-user';
import InvitationSummary from '../modules/installation-share/summary-share-invitation/invitation';
import ShareSummary from '../modules/installation-share/summary-share-invitation/share';
import InvitationAccepted from '../modules/invitation-accepted';
import InvitationReceived from '../modules/invitation-received';
import NotificationList from '../modules/notification-list';
import OptimizerSchedule from '../modules/optimizer-schedule';
import PrivacyPage from '../modules/privacy';
import RgbSceneForm from '../modules/rgb-scene-form';
import RodoPage from '../modules/rodo';
import Settings from '../modules/settings';
import SupportPage from '../modules/support';
import TermsPage from '../modules/terms';
import TriggerDetails from '../modules/trigger-details';
import TriggerForm from '../modules/trigger-form';
import {
  ChannelList,
  Dashboard,
  ChannelConfiguration,
  ChannelDetails,
  GroupList,
  ActionList,
  GroupCreate,
  GroupEdit,
  DeviceList,
  DeviceDetails,
  ChannelsVisibility,
  GroupSort,
  GroupSortChannels,
  ActionCreate,
  ActionCreateTasks,
  ActionTasksTypes,
  ActionTasksVariants,
  ActionDeviceVariants,
  ActionChannelSettings,
  ActionEdit,
  ActionEditTasks,
} from './integration-pages';
import { ROUTES } from './routes';

export const RouterSwitch: React.FC = () => (
  <Switch>
    <Route path={ROUTES.Root()} component={Dashboard} exact />
    <Route path={ROUTES.Notification()} component={NotificationList} exact />
    <Route path={ROUTES.Settings()} component={Settings} />
    <Route path={ROUTES.Channel()} component={ChannelList} exact />
    <Route path={ROUTES.ChannelsVisibility()} component={ChannelsVisibility} exact />
    <Route path={ROUTES.ChannelDetails()} component={ChannelDetails} exact />
    <Route path={ROUTES.Device()} component={DeviceList} exact />
    <Route path={ROUTES.DeviceSettings()} component={DeviceDetails} exact />
    <Route path={ROUTES.DeviceBindings()} component={DeviceBindings} exact />
    <Route path={ROUTES.DeviceWifiConfiguration()} component={DeviceWifiConfiguration} exact />
    <Route path={ROUTES.ChannelSettings()} component={DeviceDetails} />
    <Route path={ROUTES.FavouritesForm()} component={GroupEdit} />
    <Route path={ROUTES.Group()} component={GroupList} exact />
    <Route path={ROUTES.GroupSort()} component={GroupSort} exact />
    <Route path={ROUTES.GroupSortChannels()} component={GroupSortChannels} exact />
    <Route path={ROUTES.GroupCreate()} component={GroupCreate} />
    <Route path={ROUTES.GroupEdit()} component={GroupEdit} />
    <Route path={ROUTES.InstallationGroupsShare()} component={GroupsShare} />
    <Route path={ROUTES.InstallationChannelsShare()} component={ChannelsShare} />
    <Route path={ROUTES.Terms()} component={TermsPage} exact />
    <Route path={ROUTES.Privacy()} component={PrivacyPage} exact />
    <Route path={ROUTES.Support()} component={SupportPage} exact />
    <Route path={ROUTES.Rodo()} component={RodoPage} exact />
    <Route path={ROUTES.Installation()} component={InstallationList} exact />
    <Route path={ROUTES.InstallationCreate()} component={InstallationCreate} exact />
    <Route path={ROUTES.InstallationCreated()} component={InstallationCreated} exact />
    <Route path={ROUTES.IntegrationsList()} component={IntegrationsList} exact />
    <Route path={ROUTES.InstallationSettings()} component={InstallationSettings} exact />
    <Route path={ROUTES.InstallationForwardSummary()} component={InstallationSummaryForward} exact />
    <Route path={ROUTES.InstallationRemove()} component={RemoveInstallation} exact />
    <Route path={ROUTES.InstallationShared()} component={SharedInstallations} exact />
    <Route path={ROUTES.SharedUser()} component={SharedUser} exact />
    <Route path={ROUTES.InstallationShareEmail()} component={ShareEmail} exact />
    <Route path={ROUTES.InstallationShareSubject()} component={InstallationShareSubject} exact />
    <Route path={ROUTES.InstallationShareRole()} component={ShareRole} exact />
    <Route path={ROUTES.UserSharedGroups()} component={GroupsShareEdit} exact />
    <Route path={ROUTES.UserSharedChannels()} component={ChannelsShareEdit} exact />
    <Route path={ROUTES.InstallationShareRestriction()} component={ShareRestriction} exact />
    <Route path={ROUTES.InstallationShareAccessCount()} component={ShareAccessCount} exact />
    <Route path={ROUTES.InstallationShareAccessTime()} component={ShareAccessTime} exact />
    <Route path={ROUTES.InstallationSummaryShare()} component={ShareSummary} exact />
    <Route path={ROUTES.InvitationSummary()} component={InvitationSummary} exact />
    <Route path={ROUTES.ActionList()} component={ActionList} exact />
    <Route path={ROUTES.ActionEditChannels()} component={ActionEditChannels} exact />
    <Route path={ROUTES.ActionEditName()} component={ActionEditName} exact />
    <Route path={ROUTES.ActionCreate()} component={ActionCreate} exact />
    <Route path={ROUTES.ActionCreateTasks()} component={ActionCreateTasks} exact />
    <Route path={ROUTES.ActionChannelsSort()} component={ActionChannelSort} exact />
    <Route path={ROUTES.ActionCreateBasic()} component={ActionCreateBasic} exact />
    <Route path={ROUTES.TriggerCreate()} component={TriggerForm} exact />
    <Route path={ROUTES.TriggerEdit()} component={TriggerForm} exact />
    <Route path={ROUTES.RgbSceneCreate()} component={RgbSceneForm} exact />
    <Route path={ROUTES.InvitationReceived()} component={InvitationReceived} exact />
    <Route path={ROUTES.InvitationAccepted()} component={InvitationAccepted} exact />
    <Route path={ROUTES.CreateAccountOffer()} component={CreateAccountOffer} exact />
    <Route path={ROUTES.ActionDetails()} component={ActionDetails} exact />
    <Route path={ROUTES.TriggerDetails()} component={TriggerDetails} exact />
    <Route path={ROUTES.ChannelConfiguration()} component={ChannelConfiguration} exact />
    <Route path={ROUTES.UpdateSoftware()} component={UpdateSoftware} exact />
    <Route path={ROUTES.ReverseOutputs()} component={ReverseOutputs} exact />
    <Route path={ROUTES.ReverseInputs()} component={ReverseInputs} exact />
    <Route path={ROUTES.CalibrateChannel()} component={CalibrateChannel} exact />
    <Route path={ROUTES.OptimizerSchedule()} component={OptimizerSchedule} exact />
    <Route path={ROUTES.OptimizerScheduleEdit()} component={OptimizerSchedule} exact />
    <Route path={ROUTES.AddSuccess()} component={AddSuccess} exact />
    <Route path={ROUTES.ExalusIntegrationCreate()} component={ExalusIntegrationCreate} exact />
    <Route path={ROUTES.ExalusAuthorization()} component={ExalusIntegrationLogin} exact />
    <Route path={ROUTES.ExalusQRCodeAuthorization()} component={ExalusQRCodeAuth} exact />
    <Route path={ROUTES.ExalusLogin()} component={ExalusLogin} exact />
    <Route path={ROUTES.CentralAction()} component={CentralActionDetails} exact />
    <Route path={ROUTES.CentralActionByType()} component={CentralActionByTypeDetails} exact />
    <Route path={ROUTES.AddExalusDevices()} component={AddDevices} exact />
    <Route path={ROUTES.FacadeCalibration()} component={ZfBidiCalibration} exact />
    <Route path={ROUTES.Srp22Calibration()} component={Srp22Calibration} exact />
    <Route path={ROUTES.DeviceUpdate()} component={DeviceUpdate} exact />
    <Route path={ROUTES.BlindAutoCalibration()} component={BlindAutoCalibration} exact />
    <Route path={ROUTES.ExalusUsersList()} component={ExalusUsersList} exact />
    <Route path={ROUTES.UserDetails()} component={ExalusUserDetails} exact />
    <Route path={ROUTES.UserEdit()} component={ExalusUserEdit} exact />
    <Route path={ROUTES.ChangeUserPassword()} component={ExalusChangePassword} exact />
    <Route path={ROUTES.UserCreate()} component={ExalusUserCreate} exact />
    <Route path={ROUTES.UserManual()} component={UserManual} exact />
    <Route path={ROUTES.ExalusControllerSettings()} component={ExalusControllerSettings} exact />
    <Route path={ROUTES.ExalusNetworkSettings()} component={ExalusNetworkSettings} exact />
    <Route path={ROUTES.ExalusActionCreateConditions()} component={ExalusActionCreateConditions} exact />
    <Route path={ROUTES.ExalusActionConditionsTypes()} component={ExalusActionConditionsTypes} exact />
    <Route path={ROUTES.ActionTasksTypes()} component={ActionTasksTypes} exact />
    <Route path={ROUTES.ActionTasksVariants()} component={ActionTasksVariants} exact />
    <Route path={ROUTES.DeviceVariants()} component={ActionDeviceVariants} exact />
    <Route path={ROUTES.ActionChannelSettings()} component={ActionChannelSettings} exact />
    <Route path={ROUTES.ExalusActionTaskDelay()} component={ExalusActionTaskDelay} exact />
    <Route path={ROUTES.ExalusActionTaskLock()} component={ExalusActionTaskLock} exact />
    <Route path={ROUTES.ExalusActionSources()} component={ExalusActionSources} exact />
    <Route path={ROUTES.ExalusDeviceSource()} component={ExalusDeviceSource} exact />
    <Route path={ROUTES.ExalusScheduleSource()} component={ExalusScheduleSource} exact />
    <Route path={ROUTES.ExalusScenarioSource()} component={ExalusScenarioSource} exact />
    <Route path={ROUTES.ExalusDeviceParameters()} component={ExalusDeviceParameters} exact />
    <Route path={ROUTES.ExalusActionDetails()} component={ExalusActionDetails} exact />
    <Route path={ROUTES.ExalusActionEdit()} component={ActionEdit} exact />
    <Route path={ROUTES.ExalusActionEditConditions()} component={ExalusActionCreateConditions} exact />
    <Route path={ROUTES.ExalusActionEditTasks()} component={ActionEditTasks} exact />
    <Route path={ROUTES.DeviceRemotes()} component={DeviceRemotes} exact />
    <Route path={ROUTES.ExalusDnsAdd()} component={ExalusAddDns} exact />
    <Route path={ROUTES.ExalusStore()} component={ExalusStore} exact />
    <Route path={ROUTES.ExalusStoreDeviceDetails()} component={StoreDeviceDetails} exact />
    <Route path={ROUTES.ExalusNetworkStaticSettings()} component={ExalusNetworkStaticSettings} exact />
    <Route path={ROUTES.Chat()} component={Chat} />
    <Route path={ROUTES.MeasurementDetails()} component={MeasurementDetails} exact />
    <Route path={ROUTES.DeviceAddBluetooth()} component={DeviceAddBluetooth} exact />
    <Route path={ROUTES.DeviceAddBluetoothIdentify()} component={DeviceAddBluetoothIdentify} exact />
    <Route path={ROUTES.DeviceAddBluetoothNetwork()} component={DeviceAddBluetoothNetwork} exact />
    <Route path={ROUTES.DeviceAddBluetoothSuccess()} component={DeviceAddBluetoothSuccess} exact />
    <Route path={ROUTES.DeviceUpdateBluetoothSuccess()} component={DeviceUpdateBluetoothSuccess} exact />
    <Route path={ROUTES.DeviceAddBluetoothSetWifiSuccess()} component={DeviceAddBluetoothSetWifiSuccess} exact />
    <Route path={ROUTES.DeviceAddBluetoothUpdate()} component={DeviceAddBluetoothUpdate} exact />
    <Route path={ROUTES.DeviceAddBluetoothWisniowskiStart()} component={WisniowskiDriveAddStart} exact />
    <Route
      path={ROUTES.DeviceAddBluetoothWisniowskiTutorialStep1()}
      component={WisniowskiDriveAddTutorialStep1}
      exact
    />
    <Route
      path={ROUTES.DeviceAddBluetoothWisniowskiTutorialStep2()}
      component={WisniowskiDriveAddTutorialStep2}
      exact
    />
    <Route path={ROUTES.DeviceAddBluetoothWisniowskiSuccess()} component={WisniowskiDriveAddSuccess} exact />
    <Redirect to={ROUTES.Root()} />
  </Switch>
);
