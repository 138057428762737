import { MeasurementRange } from '../../../../../types';
import { convertDateToString } from '../../../../../utils/helpers';
import { AvailableLongFormatEnum, AvailableShortFormatEnum } from '../types';

export const getChartTime = (longFormat: boolean, value: number, activeMeasurementRange: MeasurementRange): string => {
  const currentFormat = (activeMeasurementRange: MeasurementRange) => {
    switch (activeMeasurementRange) {
      case MeasurementRange.DayInHours:
        return longFormat ? AvailableLongFormatEnum.HOURLY : AvailableShortFormatEnum.HOURLY;
      case MeasurementRange.WeekInDays:
        return longFormat ? AvailableLongFormatEnum.WEEKLY : AvailableShortFormatEnum.WEEKLY;
      case MeasurementRange.MonthInDays:
        return longFormat ? AvailableLongFormatEnum.MONTHLY : AvailableShortFormatEnum.MONTHLY;
      case MeasurementRange.YearInMonths:
        return longFormat ? AvailableLongFormatEnum.YEARLY : AvailableShortFormatEnum.YEARLY;
      default:
        return '';
    }
  };

  const formattedValue = (activeMeasurementRange: MeasurementRange) => {
    switch (activeMeasurementRange) {
      case MeasurementRange.DayInHours:
        return new Date().setHours(value, 0, 0, 0);
      case MeasurementRange.WeekInDays: {
        const day = new Date().getDate();
        if (day > 7) {
          return new Date().setDate(day - 7 + value + 1);
        }
        return new Date().setDate(value + 1);
      }
      case MeasurementRange.MonthInDays:
        return new Date().setDate(value + 1);
      case MeasurementRange.YearInMonths:
        return new Date().setMonth(value);
      default:
        return 0;
    }
  };

  return convertDateToString(formattedValue(activeMeasurementRange), currentFormat(activeMeasurementRange));
};
