import React from 'react';

export const IconShare: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.4998 4.32414C16.4998 5.3297 15.6808 6.14827 14.6663 6.14827C13.6519 6.14827 12.8329 5.3297 12.8329 4.32414C12.8329 3.31857 13.6519 2.5 14.6663 2.5C15.6808 2.5 16.4998 3.31857 16.4998 4.32414Z"
      stroke="#15141D"
      strokeLinecap="round"
    />
    <path
      d="M16.4998 14.949C16.4998 15.9546 15.6808 16.7731 14.6663 16.7731C13.6519 16.7731 12.8329 15.9546 12.8329 14.949C12.8329 13.9435 13.6519 13.1249 14.6663 13.1249C15.6808 13.1249 16.4998 13.9435 16.4998 14.949Z"
      stroke="#15141D"
      strokeLinecap="round"
    />
    <path
      d="M7.16688 9.63627C7.16688 10.6418 6.34791 11.4604 5.33344 11.4604C4.31897 11.4604 3.5 10.6418 3.5 9.63627C3.5 8.63071 4.31897 7.81213 5.33344 7.81213C6.34791 7.81213 7.16688 8.63071 7.16688 9.63627Z"
      stroke="#15141D"
      strokeLinecap="round"
    />
    <path d="M7.33276 8.9718L12.9997 5.65161" stroke="#15141D" />
    <path d="M7.33276 10.6318L12.6663 13.952" stroke="#15141D" strokeLinecap="round" />
  </svg>
);
