import React from 'react';

export const IconInfo: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.6546 12.1947V7.96421V6H8.61822H7.34851V7.96421H8.61822V12.1947H7.34851V14H8.61822H11.6546H12.9243V12.1947H11.6546Z"
      stroke="#15141D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5282 4.47362C12.4753 1.42074 7.52637 1.42074 4.47349 4.47362C1.42062 7.52649 1.42062 12.4755 4.47349 15.5283C7.08407 18.1389 11.0813 18.515 14.0937 16.6603L17.4592 17.4594L16.6602 14.0938C18.5149 11.0814 18.1388 7.08419 15.5282 4.47362Z"
      stroke="#15141D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
