import React from 'react';
import { useTranslation } from 'react-i18next';
import LavvaDetailsWrapper from '../../../components/details-wrapper';
import { useBackdropContext } from '../../../hooks';
import { useChannelDetailsContext } from '../../../hooks/channel-details';
import { ChannelInterface } from '../../../types';
import { OptimizerMeasurements } from '../meter/measurements/optimizer';
import { OptimizerConfiguration } from './configuration';
import { Preview } from './configuration/components/preview';
import { OptimizerCurrent } from './current';
import { useOptimizerCurrent } from './hooks/use-optimizer-current';

interface PropsInterface {
  channel: ChannelInterface;
}

const ChannelDetailsOptimizer: React.FC<PropsInterface> = () => {
  const { t } = useTranslation('channel-details');
  const { channel: channelDetails } = useChannelDetailsContext();
  const { carouselDisabled } = useBackdropContext();
  const { optimizerData, workMode, temperature, measurementFullData } = useOptimizerCurrent({
    channel: channelDetails,
  });

  return (
    <LavvaDetailsWrapper
      preventSwipeEvent={carouselDisabled}
      tabs={[{ label: t('currently') }, { label: t('details') }, { label: t('configuration') }]}
      additionalTabs={[
        <OptimizerMeasurements key={2} channelId={channelDetails?.id} measurementFullData={measurementFullData} />,
        <OptimizerConfiguration key={2} channel={channelDetails} temperature={temperature} />,
      ]}
      underTabs={{ component: <Preview optimizerData={optimizerData} />, index: 2 }}
    >
      <OptimizerCurrent optimizerData={optimizerData} workMode={workMode} temperature={temperature} />
    </LavvaDetailsWrapper>
  );
};

export default ChannelDetailsOptimizer;
