import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Rdp21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Dimmer/Rdp21ConfigService';
import { ZfBidiConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/FacadeBlindService/ZfBidiConfigService';
import { Rcz21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/FloodSensor/Rcz21ConfigService';
import { Rob21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/GateControler/Rob21ConfigService';
import { SbrConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/GateControler/SbrConfigService';
import { Slr21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/LedController/Slr21ConfigService';
import { Slr22ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/LedController/Slr22ConfigService';
import { Rcr21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/MovementSensor/Rcr21ConfigService';
import { Rcm21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/MultiSensor/Rcm21ConfigService';
import { Rog21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/PowerPlugSwitch/Rog21ConfigService';
import { Rck21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/ReedSwitch/Rck21ConfigService';
import { RomConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/RomSwitches/RomConfigService';
import { RopConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/RopSwitches/RopConfigService';
import { Srp22ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/SrpConfigService/Srp22ConfigService';
import { Srp30ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/SrpConfigService/Srp30ConfigService';
import { SsrConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/SsrConfigService/SsrConfigService';
import { WszfBidiConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/WindSensor/WszfBidiConfigService';
import { Portos433ConfigService } from 'lavva.exalushome.portos/build/js/Devices/DeviceServices/Portos433Mhz/Portos433ConfigService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import Detail from '../../../../../configuration/configuration-details/components/detail';
import { useExalusServicesContext } from '../../../../context/services';
import { useLoggedUser } from '../../../../hooks/common/logged-user';
import Config433Form from '../../../channel-configuration/forms/433';
import Rck21ConfigForm from '../../../channel-configuration/forms/rck21';
import Rcm21ConfigForm from '../../../channel-configuration/forms/rcm21';
import Rcr21ConfigForm from '../../../channel-configuration/forms/rcr21';
import Rcz21ConfigForm from '../../../channel-configuration/forms/rcz21';
import Rdp21ConfigForm from '../../../channel-configuration/forms/rdp21';
import Rob21ConfigForm from '../../../channel-configuration/forms/rob21';
import Rog21ConfigForm from '../../../channel-configuration/forms/rog21';
import RomConfigForm from '../../../channel-configuration/forms/rom';
import RopConfigForm from '../../../channel-configuration/forms/rop';
import SbrConfigForm from '../../../channel-configuration/forms/sbr';
import SlrConfigForm from '../../../channel-configuration/forms/slr';
import SrpConfigForm from '../../../channel-configuration/forms/srp';
import SsrConfigForm from '../../../channel-configuration/forms/ssr';
import WszfBidiConfigForm from '../../../channel-configuration/forms/wsz-bidi';
import ZfBidiConfigForm from '../../../channel-configuration/forms/zf-bidi';
import { useConfigurationService } from '../../hooks/use-configuration-service';
import { useDeviceConfiguration } from '../../hooks/use-device-configuration';
import { DeviceConfigurationDetails } from '../../types';
import ConfigurationInput from '../configuration-input';
import './index.scss';

interface ComponentProps {
  device?: IDevice;
}

const ConfigurationDetails: React.FC<ComponentProps> = ({ device }) => {
  const { t } = useTranslation('device-info');
  const [nameDisabled, setNameDisabled] = useState<boolean>(true);
  const { changeName } = useDeviceConfiguration(setNameDisabled);
  const { devicesApi } = useExalusServicesContext();
  const { canDeviceEdit, canAddRemoveConfigureDevice } = useLoggedUser();
  const { configService, firmwareService, DiagnosticButton } = useConfigurationService(device);
  const form = useForm<DeviceConfigurationDetails>();

  useEffect(() => {
    if (device) form.setValue('name', device.Name);
  }, [device]);

  const onSubmit = form.handleSubmit(async (values) => {
    changeName(values.name);
  });

  const deviceConfigForms = useMemo(() => {
    if (!device || !canAddRemoveConfigureDevice) return null;

    switch (configService) {
      case Rcz21ConfigService.ServiceName: {
        return <Rcz21ConfigForm device={device} />;
      }
      case Rck21ConfigService.ServiceName: {
        return <Rck21ConfigForm device={device} />;
      }
      case WszfBidiConfigService.ServiceName: {
        return <WszfBidiConfigForm device={device} />;
      }
      case Rcm21ConfigService.ServiceName: {
        return <Rcm21ConfigForm device={device} />;
      }
      case ZfBidiConfigService.ServiceName: {
        return <ZfBidiConfigForm device={device} />;
      }
      case Rog21ConfigService.ServiceName: {
        return <Rog21ConfigForm device={device} />;
      }
      case Rdp21ConfigService.ServiceName: {
        return <Rdp21ConfigForm device={device} />;
      }
      case RomConfigService.ServiceName: {
        return <RomConfigForm device={device} />;
      }
      case RopConfigService.ServiceName: {
        return <RopConfigForm device={device} />;
      }
      case Rob21ConfigService.ServiceName: {
        return <Rob21ConfigForm device={device} />;
      }
      case Rcr21ConfigService.ServiceName: {
        return <Rcr21ConfigForm device={device} />;
      }
      case Slr21ConfigService.ServiceName:
      case Slr22ConfigService.ServiceName: {
        return <SlrConfigForm device={device} serviceName={configService} />;
      }
      case Srp30ConfigService.ServiceName:
      case Srp22ConfigService.ServiceName: {
        return <SrpConfigForm device={device} />;
      }
      case SsrConfigService.ServiceName: {
        return <SsrConfigForm device={device} />;
      }
      case SbrConfigService.ServiceName: {
        return <SbrConfigForm device={device} />;
      }
      case Portos433ConfigService.ServiceName: {
        return <Config433Form device={device} />;
      }
      default: {
        return null;
      }
    }
  }, [configService, device, canAddRemoveConfigureDevice]);

  const protocol = useMemo(() => {
    if (device?.ProtocolGuid) {
      return devicesApi().ProtocolGuidToProtocolName(device.ProtocolGuid);
    }
  }, [device?.ProtocolGuid]);

  return (
    <>
      <div className="configuration_details_exalus">
        <FormProvider {...form}>
          <div className="grid-list-24">
            <form onSubmit={onSubmit}>
              <ConfigurationInput
                name="name"
                label={t('device_name')}
                placeholder={t('device_name')}
                edited
                disabled={nameDisabled || !canDeviceEdit}
                setDisabled={setNameDisabled}
              />
            </form>
          </div>
        </FormProvider>
        {device?.SerialNumber && <Detail label={t('serialNumber')} value={device.SerialNumber} />}
        {protocol && <Detail label={t('protocol')} value={protocol} />}
      </div>
      {deviceConfigForms && <hr />}
      <div className="grid-list-24">{deviceConfigForms}</div>
      {(firmwareService || DiagnosticButton) && <hr className="m-t-24 m-b-24" />}
    </>
  );
};

export default ConfigurationDetails;
