import React, { ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import c from 'classnames';
import { orderBy } from 'lodash';
import { Header, IconWrapper, NavHeader, Page, SubmitButton } from '../../components';
import { IntegrationType } from '../../data-access/gql-types/graphql';
import { useIntegrationOptions } from '../../hooks/integrations/use-options';
import { ROUTES } from '../../routes';
import { useCreateInstallationContext } from './context';
import { useCreateInstallation } from './hooks/use-create-installation';

const IntegrationList: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation('installation');
  const { t: tc } = useTranslation('common');
  const { state: installationState } = useCreateInstallationContext();
  const { editIntegration, submitInstallation } = useCreateInstallation();
  const { integrationsOptions } = useIntegrationOptions();

  useEffect(() => {
    if (!installationState.installationName) history.replace(ROUTES.InstallationCreate());
  }, []);

  const onChangeIntegration = ({ target }: ChangeEvent<HTMLInputElement>) => {
    editIntegration(target.value as IntegrationType);
  };

  const next = () => {
    if (installationState.integrationType === IntegrationType.Lavva) submitInstallation();
    else history.push(ROUTES.ExalusIntegrationCreate(installationState));
  };

  return (
    <>
      <Page
        header={
          <>
            <NavHeader />
            <Header title={t('integration')} />
          </>
        }
      >
        <div className="radios-with-icons radios-with-icons--background">
          {orderBy(integrationsOptions, 'weight', 'asc').map((option) => (
            <label
              key={option.value}
              className={c('radios_group__label-weight', {
                checked: installationState.integrationType === option.value,
              })}
            >
              <div className="icon-label">
                {option.icon ? (
                  <IconWrapper className="icon-wrapper--size-32 icon-wrapper--no-padding m-r-16">
                    {option.icon}
                  </IconWrapper>
                ) : null}
                {option.label}
              </div>
              <div>
                <input
                  type="radio"
                  value={option.value}
                  checked={installationState.integrationType === option.value}
                  onChange={onChangeIntegration}
                />
                <span></span>
              </div>
            </label>
          ))}
        </div>

        <SubmitButton onClick={next} type="button" isLoading={installationState.createLoading}>
          {tc('buttons.next')}
        </SubmitButton>
      </Page>
    </>
  );
};

export default IntegrationList;
