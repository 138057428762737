import { useEffect, useMemo, useState } from 'react';
import { orderBy, uniqBy } from 'lodash';
import { useQuery } from '@apollo/client';
import {
  GateChannelStateResponse,
  GateHistoryQuery,
  GateHistoryQueryVariables,
  GateResponseOfGateHistory,
} from '../../../../data-access/gql-types/graphql';
import { GATE_HISTORY } from '../../../../data-access/queries/channels';
import { useInstallation } from '../../../../hooks';

type GateChannelStateResponseExtended = GateChannelStateResponse & { id: string };

export const useGateHistory = (channelId: string) => {
  const [gateHistory, setGateHistory] = useState<GateResponseOfGateHistory | null>(null);
  const [offset, setOffset] = useState<number>(0);
  const { selectedInstallationId, skipLavvaFetch } = useInstallation();
  const { data, loading } = useQuery<GateHistoryQuery, GateHistoryQueryVariables>(GATE_HISTORY, {
    variables: {
      installationId: selectedInstallationId,
      channelId: channelId,
      count: 50,
      offset: offset,
    },
    fetchPolicy: 'cache-and-network',
    skip: skipLavvaFetch,
  });

  useEffect(() => {
    const historyData = (data?.gateUserChannel?.payload as GateChannelStateResponseExtended)?.history;

    if (historyData) {
      setGateHistory((prev) => ({
        ...historyData,
        data: {
          ...historyData?.data,
          entries: orderBy(
            uniqBy([...(prev?.data?.entries || []), ...(historyData.data?.entries || [])], 'id'),
            'id',
            'desc',
          ),
          totalCount: historyData.data?.totalCount || 0,
        },
      }));
    }
  }, [data]);

  const totalCount = useMemo(() => gateHistory?.data?.totalCount || 0, [gateHistory]);
  const historyError = useMemo(() => gateHistory?.error, [gateHistory]);
  const entries = useMemo(() => gateHistory?.data?.entries || [], [gateHistory]);

  const emptyData = useMemo(() => {
    if (!gateHistory) return null;
    return !gateHistory.error && (gateHistory.data === undefined || !gateHistory.data?.entries.length);
  }, [gateHistory]);

  const loadMore = () => {
    setOffset((prev) => prev + 50);
  };

  return {
    historyError,
    emptyData,
    loading: loading || emptyData === null,
    loadMore,
    entries,
    totalCount,
  };
};
