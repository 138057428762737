import React, { Suspense, useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { QueryClientProvider } from 'react-query';
import { Api } from 'lavva.exalushome';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { App, AppLocal } from './App';
import queryClient from './api/clients/query-client';
import { SplashScreen } from './components';
import { BackdropSuspense } from './components/backdrop-suspense';
import { environment } from './environment';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Tokens } from './types/auth';
import { isProd } from './utils/helpers/environment';
import { isLocalApp, registerServiceWorkerShouldRegister } from './utils/helpers/local-app';
import { getKeycloakInitOptions, keycloakInstance } from './utils/keycloak';
import { setTheme, setWisniowskiTheme } from './utils/theme';
import './utils/translations/i18n';

const Providers: React.FC = () => {
  useEffect(() => Api.Init(), []);

  return (
    <QueryClientProvider client={queryClient}>
      {!isLocalApp ? <App /> : <AppLocal />}
      {!isProd && <code className="app-version">v.{environment.VERSION}</code>}
    </QueryClientProvider>
  );
};

const keycloakInitOptions = getKeycloakInitOptions();

const Root: React.FC = () => {
  const [tokenUpdateCount, setTokenUpdateCount] = useState<number>(0);

  useEffect(() => {
    if (isProd) window.loglevel = 4;

    setTheme();
    setWisniowskiTheme();
  }, []);

  const onTokens = useCallback(
    ({ token, idToken, refreshToken }: Tokens) => {
      if (token && idToken && refreshToken) {
        localStorage.removeItem('local-app');

        window.localStorage.setItem(
          'tokens',
          JSON.stringify({
            token,
            idToken,
            refreshToken,
          }),
        );
        setTokenUpdateCount((value) => value + 1);
      }
    },
    [tokenUpdateCount],
  );

  return !isLocalApp ? (
    <ReactKeycloakProvider
      authClient={keycloakInstance}
      initOptions={keycloakInitOptions}
      LoadingComponent={<SplashScreen />}
      onTokens={onTokens}
    >
      <Suspense fallback={<BackdropSuspense />}>
        <Providers />
      </Suspense>
    </ReactKeycloakProvider>
  ) : (
    <Suspense fallback={<BackdropSuspense />}>
      <Providers />
    </Suspense>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
if (registerServiceWorkerShouldRegister()) serviceWorkerRegistration.register();
