import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { IconReferenceLine } from '../../../../../../components';
import { useCurrentLanguage } from '../../../../../../hooks';
import { MeasurementRange } from '../../../../../../types';
import { SelectedPhase } from '../../../../measurement/types';
import { ChartDataItem } from '../../index';
import { getChartTime } from '../../utils';
import { EmptyState } from './empty-state';
import './index.scss';
import { LoadingState } from './loading-state';
import { Tooltip } from './tooltip';

interface PropsInterface {
  data: ChartDataItem[];
  activeMeasurementRange: MeasurementRange;
  selectedPhases: SelectedPhase[] | undefined;
  isLoading: boolean;
}

const strokeLineColor = '#9A9BA2';

export const AnalysisBarChart: React.FC<PropsInterface> = ({
  data,
  activeMeasurementRange,
  selectedPhases,
  isLoading,
}) => {
  const [chartData, setChartData] = useState<ChartDataItem[]>([]);
  const [payload, setPayload] = useState<ChartDataItem>();
  const [barPayload, setBarPayload] = useState<{ x: number; height: number; width: number }>({
    x: 0,
    height: 0,
    width: 0,
  });
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [clickedBar, setClickedBar] = useState<string>('');
  const { currentLanguage } = useCurrentLanguage();

  const maxChartDataValue = useMemo(() => {
    const allValues = data.reduce<number[]>(
      (all, chartDataItem) => [...all, ...chartDataItem.values.map((valueObject) => valueObject.value)],
      [],
    );
    return Math.max(...allValues, 0);
  }, [data]);

  useEffect(() => {
    if (selectedPhases === undefined || data.length === 0) return;
    const keys = selectedPhases.map((phase) => phase.value);

    if (selectedPhases.length === 0) return;

    const chartData = data.map((el, index) => {
      const values = el.values.filter((value) => keys.includes(value.type));
      const dateKeyValue = values.reduce((prev, curr) => ({ ...prev, [curr.type]: curr.value }), {});

      return {
        tooltipTime: getChartTime(true, index, activeMeasurementRange),
        time: getChartTime(false, index, activeMeasurementRange),
        ...dateKeyValue,
        values,
      };
    });

    setChartData(chartData);
  }, [data, selectedPhases, maxChartDataValue, activeMeasurementRange]);

  const handleHideTooltip = useCallback(() => {
    setClickedBar('');
    setShowTooltip(false);
  }, [showTooltip, clickedBar]);

  const handleShowTooltip = useCallback(
    (data) => {
      setClickedBar(data.tooltipTime);
      setBarPayload({ x: data.x, height: data.height, width: data.width });
      setPayload(data.payload);
      setShowTooltip(true);
    },
    [showTooltip, clickedBar],
  );

  const referenceLineLeftPosition = useMemo(() => barPayload.x + barPayload.width * 0.5 - 8.5, [barPayload]);

  const label = useMemo(() => payload?.tooltipTime || '', [activeMeasurementRange, currentLanguage, payload]);

  const xAxisInterval = useMemo(() => {
    switch (activeMeasurementRange) {
      case MeasurementRange.DayInHours:
        return 3;
      case MeasurementRange.MonthInDays:
        return 3;
      case MeasurementRange.WeekInDays:
      case MeasurementRange.YearInMonths:
        return 'preserveStartEnd';
      default:
        return 1;
    }
  }, [activeMeasurementRange]);

  if (isLoading) {
    return <LoadingState />;
  }

  if (data.length === 0) {
    return <EmptyState missingData />;
  }

  if (!chartData.length) {
    return <EmptyState />;
  }

  return (
    <>
      <Tooltip active={showTooltip} payload={payload} label={label} />
      <div className="chart">
        <div
          className={classNames('chart__reference-line', { 'chart__reference-line--active': showTooltip })}
          style={{ left: referenceLineLeftPosition }}
        >
          <IconReferenceLine height={barPayload.height} />
        </div>
        <ResponsiveContainer width="100%" height={176}>
          <BarChart data={chartData} margin={{ top: 8, right: 0, left: -20, bottom: 0 }}>
            <CartesianGrid vertical={false} stroke={strokeLineColor} strokeOpacity={0.2} />
            <XAxis
              tickMargin={8}
              dataKey="time"
              axisLine={false}
              interval={xAxisInterval}
              tickLine={{ stroke: 'transparent' }}
            />
            <YAxis
              axisLine={{ strokeWidth: '0' }}
              tickCount={6}
              tickMargin={6}
              tickLine={{ stroke: strokeLineColor, strokeWidth: '0.5px', strokeOpacity: '0.2' }}
            />
            {chartData.length && chartData[0].values.length
              ? chartData[0].values.map((item, index) => (
                  <Bar
                    radius={index === chartData[0].values.length - 1 ? [2, 2, 0, 0] : undefined}
                    onMouseOver={handleShowTooltip}
                    onMouseLeave={handleHideTooltip}
                    key={item.type}
                    dataKey={item.type}
                    stackId="energy"
                    fill={item.color}
                  />
                ))
              : null}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};
