import React, { useState } from 'react';
import c from 'classnames';
import { IconSynchronize } from '../../../../components/icons/icon-synchronize';
import { isIos } from '../../../../utils/helpers/operation-system';
import { useExalusContext } from '../../context';
import { useExalusServicesContext } from '../../context/services';
import './index.scss';

const StateSyncButton: React.FC = () => {
  const [syncing, setSyncing] = useState<boolean>(false);
  const { devicesApi } = useExalusServicesContext();
  const { statesSynced } = useExalusContext();

  const handleClick = async () => {
    setSyncing(true);
    await devicesApi().SyncDevicesStatesAsync(true);
    setSyncing(false);
  };

  return (
    <button
      className={c('state-sync-btn', { rotating: syncing || !statesSynced, ios: isIos && !window.chrome })}
      onClick={handleClick}
    >
      <IconSynchronize />
    </button>
  );
};

export default StateSyncButton;
