import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Status } from 'lavva.exalushome/build/js/DataFrame';
import { DeviceUsedInScenes } from 'lavva.exalushome/build/js/Services/Devices/DevicesService';
import { DeviceType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { DeviceTaskExecutionResult } from 'lavva.exalushome/build/js/Services/Devices/TaskExecutionResult';
import { DialogConfirmation, Header, IconDelete, NavHeader, Page, Tabs } from '../../../../components';
import ArrowButton from '../../../../components/arrow-button';
import { useBackdropContext } from '../../../../hooks';
import { ROUTES } from '../../../../routes';
import { getParameterByName } from '../../../../utils/location';
import { toastError, toastSuccess } from '../../../../utils/toast';
import { DeviceIcon } from '../../components/device/icon';
import { useExalusServicesContext } from '../../context/services';
import { DeletePopupType } from '../../enums/device';
import { useExalusDevice, useHandleDataFrameErrors } from '../../hooks';
import { useLoggedUser } from '../../hooks/common/logged-user';
import ConfigurationDetails from './components/configuration-details';
import { DeviceChannels } from './components/device-channels';
import './index.scss';

interface LocationStateType {
  back?: number;
}

const DeviceDetails: React.FC = () => {
  const history = useHistory();
  const location = useLocation<LocationStateType>();
  const { t } = useTranslation('channel-list');
  const { t: td } = useTranslation('device-settings');
  const { t: tc } = useTranslation('common');
  const { deviceId } = useParams<{ deviceId?: string }>();
  const [dialog, setShowDialog] = useState<DeletePopupType | null>(null);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const { data: device } = useExalusDevice(deviceId || '');
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { devicesApi } = useExalusServicesContext();
  const { handleError } = useHandleDataFrameErrors();
  const { canAddRemoveConfigureDevice } = useLoggedUser();

  useEffect(() => {
    const tab = getParameterByName('tab');
    if (tab) setActiveTabIndex(parseInt(tab));
  }, []);

  const removeDevice = useCallback(async () => {
    if (device) {
      turnOnBackdrop();
      const usedInScene: DeviceUsedInScenes | Status = await devicesApi().CheckIfDeviceUsedInScenesAsync(device.Guid);

      if ((usedInScene as DeviceUsedInScenes).DeviceGuid) {
        if (
          !(usedInScene as DeviceUsedInScenes).ConditionsAsCondition.length &&
          !(usedInScene as DeviceUsedInScenes).ConditionsAsTask.length
        ) {
          switch (dialog) {
            case DeletePopupType.Delete: {
              const result: DeviceTaskExecutionResult = await device.RemoveDeviceAsync();
              handleResult(result);
              break;
            }
            case DeletePopupType.DeleteForce: {
              const result: DeviceTaskExecutionResult = await devicesApi().RemoveDeviceAsync(device, true);
              handleResult(result, true);
              break;
            }
            default: {
              toastError({ content: tc('errors.somethingWentWrong') });
              break;
            }
          }
        } else {
          const scenesFromConditions = (usedInScene as DeviceUsedInScenes).ConditionsAsCondition.map((x) => x.Name);
          const scenesFromTasks = (usedInScene as DeviceUsedInScenes).ConditionsAsTask.map((x) => x.Name);
          turnOffBackdrop();
          setShowDialog(null);

          toastError({
            content: `${td('toast.removeDeviceErrorScene')} ${scenesFromConditions.concat(scenesFromTasks).join(', ')}`,
          });
        }
      } else {
        handleError(usedInScene as Status);
      }
    }
  }, [device, dialog, location]);

  const handleResult = useCallback(
    (result: DeviceTaskExecutionResult, force?: boolean) => {
      turnOffBackdrop();

      switch (result) {
        case DeviceTaskExecutionResult.Executed:
          toastSuccess({ content: td('toast.removeDeviceSuccess') });

          if (location.state?.back) history.go(-location.state.back);
          else history.replace(ROUTES.Root());
          break;
        case DeviceTaskExecutionResult.ControllerResponseTimeout:
          toastError({ content: tc('exalus.taskResult.controllerResponseTimeout', { deviceName: name }) });
          break;
        case DeviceTaskExecutionResult.Failed:
          if (force) {
            toastError({ content: t('exalus.taskResult.failed', { deviceName: name }) });
          } else {
            setShowDialog(DeletePopupType.DeleteForce);
          }

          break;
        case DeviceTaskExecutionResult.DeviceResponseTimeout:
          if (force) {
            toastError({ content: t('exalus.taskResult.deviceResponseTimeout', { deviceName: name }) });
          } else {
            setShowDialog(DeletePopupType.DeleteForce);
          }

          break;
        default:
          break;
      }
    },
    [location],
  );

  const changeTab = (tabIndex: number) => {
    setActiveTabIndex(tabIndex);
    history.replace(`${history.location.pathname}?tab=${tabIndex}`);
  };

  const closeDialog = () => setShowDialog(null);

  const tabs = useMemo(
    () => [{ label: t('configuration') }, ...(device?.Channels.length ? [{ label: t('channels') }] : [])],
    [device?.Channels],
  );

  return (
    <Page
      className="device-settings__page"
      isStickyHeader
      header={
        <>
          <NavHeader />
          {device && (
            <Header>
              <div className="device-settings__header">
                <div className="device-settings">
                  <div className="device-settings__icon">
                    <DeviceIcon iconType={device.IconType} />
                  </div>
                  <h4 className="device-settings__info">
                    <div className="device-title text-ellipsis">{device.Name}</div>
                    <div className="device-type text-ellipsis">
                      {`${tc(`types.exalus.${DeviceType[device.DeviceType]}`)} ${device.Model || ''}`}
                    </div>
                  </h4>
                </div>
              </div>
            </Header>
          )}
          <Tabs
            tabList={tabs}
            activeTabIndex={activeTabIndex}
            setActiveTabIndex={changeTab}
            tabsType="pills"
            isDivider={false}
          />
        </>
      }
    >
      <>
        {activeTabIndex === 0 ? (
          <>
            {device && <ConfigurationDetails device={device} />}
            {canAddRemoveConfigureDevice && (
              <div className="grid-list-24">
                <ArrowButton
                  title={td('removeDevice')}
                  onClick={() => setShowDialog(DeletePopupType.Delete)}
                  type="delete"
                  arrowIcon={<IconDelete />}
                />
              </div>
            )}
          </>
        ) : (
          <>
            <Header subheader title={t('channels')} />
            {device && <DeviceChannels channels={device.Channels} />}
          </>
        )}
      </>
      <DialogConfirmation
        show={!!dialog}
        setShow={closeDialog}
        header={td('removeDeviceDialog.header')}
        content={td(`removeDeviceDialog.content${dialog === DeletePopupType.DeleteForce ? 'Force' : ''}`)}
        primaryBtnText={tc('buttons.remove')}
        secondaryBtnText={tc('buttons.cancel')}
        primaryBtnAction={removeDevice}
        secondaryBtnAction={() => setShowDialog(null)}
      />
    </Page>
  );
};

export default DeviceDetails;
