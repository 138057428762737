import { useEffect } from 'react';
import { useMutation, UseMutationResult, useQuery, UseQueryResult } from 'react-query';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { IDeviceState, IDeviceStateData } from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { FieldChangeResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import queryClient from '../../../api/clients/query-client';
import { useBackdropContext } from '../../../hooks';
import { useExalusContext } from '../context';
import { useExalusServicesContext } from '../context/services';
import { updateChannelQueryCache, updateChannelsQueryCache } from '../utils';

export const useExalusChannels = (): UseQueryResult<IDeviceChannel[]> => {
  const { connected, selectedExalusCredentials, synchronized } = useExalusContext();
  const { devicesApi } = useExalusServicesContext();

  return useQuery<IDeviceChannel[]>(
    ['exalus-channels', selectedExalusCredentials?.id, synchronized],
    async () => {
      const channels: IDeviceChannel[] = [];
      const devices = await devicesApi().GetDevicesAsync();
      devices.forEach((device) => {
        device.Channels.forEach((channel) => {
          channels.push(channel);
        });
      });

      return channels;
    },
    {
      enabled: connected && !!selectedExalusCredentials?.id && synchronized,
      retry: true,
    },
  );
};

export const useExalusChannel = (id: string, channelId: string): UseQueryResult<IDeviceChannel | undefined> => {
  const { connected, selectedExalusCredentials, synchronized } = useExalusContext();
  const { devicesApi } = useExalusServicesContext();

  return useQuery<IDeviceChannel | undefined>(
    ['exalus-channel', id, channelId, selectedExalusCredentials?.id, synchronized],
    async () => {
      const device = await devicesApi().GetDevice(id);
      return device?.Channels.find((channel) => channel.ChannelId === channelId);
    },
    {
      enabled: !!id && !!channelId && connected && !!selectedExalusCredentials?.id && synchronized,
      retry: true,
    },
  );
};

export const useExalusChannelChangeName = (
  channel: IDeviceChannel | undefined,
): UseMutationResult<FieldChangeResult | undefined, unknown, string> => {
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();

  return useMutation<FieldChangeResult | undefined, unknown, string>(
    ['exalus-channel-change-name'],
    async (body) => {
      if (channel) {
        turnOnBackdrop();
        return await channel.ChangeNameAsync(body);
      }
    },
    { onSuccess: () => turnOffBackdrop(), onError: () => turnOffBackdrop() },
  );
};

export const useExalusChannelChangeVisibility = (
  channel: IDeviceChannel | undefined,
): UseMutationResult<FieldChangeResult | undefined, unknown, boolean> => {
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();

  return useMutation<FieldChangeResult | undefined, unknown, boolean>(
    ['exalus-channel-change-visibility'],
    async (body) => {
      if (channel) {
        turnOnBackdrop();
        if (body) {
          return await channel.HideAsync();
        } else {
          return await channel.ShowAsync();
        }
      }
    },
    { onSuccess: () => turnOffBackdrop(), onError: () => turnOffBackdrop() },
  );
};

export const useExalusChannelChangeIcon = (
  channel: IDeviceChannel | undefined,
): UseMutationResult<FieldChangeResult | undefined, unknown, string> => {
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();

  return useMutation<FieldChangeResult | undefined, unknown, string>(
    ['exalus-channel-change-icon'],
    async (body) => {
      if (channel) {
        turnOnBackdrop();
        return await channel.ChangeIconNameAsync(body);
      }
    },
    { onSuccess: () => turnOffBackdrop(), onError: () => turnOffBackdrop() },
  );
};

export const useExalusChannelStateChanged = (channel: IDeviceChannel | undefined) => {
  const { synchronized, connected, selectedExalusCredentials } = useExalusContext();
  const deviceId = channel?.GetDevice().Guid;

  const onChannelDetailsStateChanged = (state: IDeviceState<IDeviceStateData>) => {
    if (selectedExalusCredentials?.id && synchronized && channel && deviceId) {
      queryClient.setQueryData(
        ['exalus-channel', deviceId, channel.ChannelId, selectedExalusCredentials.id, synchronized],
        (oldChannel: any) => {
          return updateChannelQueryCache(oldChannel, state);
        },
      );
    }
  };

  useEffect(() => {
    channel?.OnChannelStateRefreshedOrChangedEvent().Subscribe(onChannelDetailsStateChanged);

    return () => {
      channel?.OnChannelStateRefreshedOrChangedEvent().Unsubscribe(onChannelDetailsStateChanged);
    };
  }, [connected, selectedExalusCredentials, synchronized, deviceId, channel?.ChannelId]);
};

export const useExalusChannelStateListChanged = () => {
  const { devicesApi } = useExalusServicesContext();
  const { synchronized, connected, selectedExalusCredentials } = useExalusContext();

  const onChannelListItemStateChanged = ({
    Device,
    State,
  }: {
    Device: IDevice;
    State: IDeviceState<IDeviceStateData>;
  }) => {
    if (selectedExalusCredentials?.id && synchronized) {
      queryClient.setQueryData(['exalus-channels', selectedExalusCredentials.id, synchronized], (oldChannels: any) => {
        return updateChannelsQueryCache(oldChannels, Device, State);
      });
    }
  };

  useEffect(() => {
    devicesApi().OnDeviceStateChangedEvent().Subscribe(onChannelListItemStateChanged);

    return () => {
      devicesApi().OnDeviceStateChangedEvent().Unsubscribe(onChannelListItemStateChanged);
    };
  }, [connected, selectedExalusCredentials, synchronized]);
};

export const useExalusChannelStateGroupChanged = () => {
  const { devicesApi } = useExalusServicesContext();
  const { synchronized, connected, selectedExalusCredentials, activeGroup } = useExalusContext();

  const onChannelListItemStateChanged = ({
    Device,
    State,
  }: {
    Device: IDevice;
    State: IDeviceState<IDeviceStateData>;
  }) => {
    if (selectedExalusCredentials?.id && synchronized && activeGroup?.Guid) {
      if (activeGroup?.Guid) {
        queryClient.setQueryData(
          ['exalus-group-channels', activeGroup.Guid, selectedExalusCredentials.id, synchronized],
          (oldGroupChannels: any) => {
            return updateChannelsQueryCache(oldGroupChannels, Device, State);
          },
        );
      }
    }
  };

  useEffect(() => {
    devicesApi().OnDeviceStateChangedEvent().Subscribe(onChannelListItemStateChanged);

    return () => {
      devicesApi().OnDeviceStateChangedEvent().Unsubscribe(onChannelListItemStateChanged);
    };
  }, [connected, selectedExalusCredentials, synchronized, activeGroup?.Guid]);
};
