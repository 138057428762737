import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import { IChannelsGroup } from 'lavva.exalushome/build/js/Services/Devices/IChannelsGroupsService';
import { DeviceTasksInfo } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { ExalusInstallationCredentials } from '../../../types/exalus';
import { useAppState, useExalusConnection, useExalusGroups } from '../hooks';
import { useExalusDevicesContext } from './devices';
import { useExalusServicesContext } from './services';
import { useExalusStorageContext } from './storage';

interface ExalusProviderInterface {
  connected: boolean;
  connectionExists: boolean;
  synchronized: boolean;
  statesSynced: boolean;
  selectedExalusCredentials: ExalusInstallationCredentials | null;
  setConnectionExists: Dispatch<SetStateAction<boolean>>;
  setConnected: Dispatch<SetStateAction<boolean>>;
  setSelectedExalusCredentials: Dispatch<SetStateAction<ExalusInstallationCredentials | null>>;
  activeGroup: IChannelsGroup | undefined;
  allGroups: IChannelsGroup[];
  groupsFetched: boolean;
  refetchAllGroups: () => any;
}

const initialState: ExalusProviderInterface = {
  connected: false,
  connectionExists: false,
  synchronized: false,
  statesSynced: false,
  selectedExalusCredentials: null,
  setConnectionExists: () => null,
  setConnected: () => null,
  setSelectedExalusCredentials: () => null,
  activeGroup: undefined,
  allGroups: [],
  groupsFetched: false,
  refetchAllGroups: () => null,
};

export const ExalusContext = createContext<ExalusProviderInterface>(initialState);

export const useExalusContext = (): ExalusProviderInterface => useContext(ExalusContext);

export const ExalusContextProvider: React.FC = ({ children }) => {
  const { activeGroupIndex, getStorageData } = useExalusStorageContext();
  const { setDevicesTasks } = useExalusDevicesContext();
  const { connect } = useExalusConnection();
  const [connected, setConnected] = useState<boolean>(false);
  const [connectionExists, setConnectionExists] = useState<boolean>(false);
  const [synchronized, setSynchronized] = useState<boolean>(false);
  const [statesSynced, setStatesSynced] = useState<boolean>(false);
  const [selectedExalusCredentials, setSelectedExalusCredentials] = useState<ExalusInstallationCredentials | null>(
    null,
  );
  const { devicesApi, groupsApi } = useExalusServicesContext();
  const {
    data: groups,
    refetch: refetchGroups,
    isFetched,
  } = useExalusGroups({
    selectedExalusCredentials,
    connected,
    synchronized,
  });
  const { initObservableAppStateChange, initObservableControllerConfigurationChange } = useAppState();

  const connectionStatus = async (selectedExalusCredentials: ExalusInstallationCredentials) => {
    const res = !connectionExists ? await connect(selectedExalusCredentials) : true;
    setConnected(res);
    setConnectionExists(res);

    await groupsApi().WaitForSynchronizationAsync();
    await devicesApi().WaitForSynchronizationAsync();
    setSynchronized(true);

    await devicesApi().WaitForDevicesStatesSynchronizationAsync();
    setStatesSynced(true);
  };

  useEffect(() => {
    setConnected(false);
    if (selectedExalusCredentials?.id) {
      connectionStatus(selectedExalusCredentials);
      initObservableAppStateChange();
      initObservableControllerConfigurationChange();
    }
  }, [selectedExalusCredentials]);

  useEffect(() => {
    if (connected) getStorageData();
  }, [connected]);

  const updateDevicesTasks = (deviceTasksInfo: DeviceTasksInfo[]) => {
    setDevicesTasks(deviceTasksInfo);
  };

  const activeGroup = useMemo(() => (groups ? groups[activeGroupIndex] : undefined), [activeGroupIndex, groups]);

  const allGroups = useMemo(() => groups || [], [groups]);

  const refetchAllGroups = () => refetchGroups();

  useEffect(() => {
    devicesApi().OnDevicesTasksExecutionChangeEvent().Subscribe(updateDevicesTasks);

    return () => {
      devicesApi().OnDevicesTasksExecutionChangeEvent().Unsubscribe(updateDevicesTasks);
    };
  }, []);

  const values: ExalusProviderInterface = {
    connected,
    connectionExists,
    synchronized,
    statesSynced,
    setConnectionExists,
    setConnected,
    selectedExalusCredentials,
    setSelectedExalusCredentials,
    activeGroup,
    allGroups,
    groupsFetched: isFetched,
    refetchAllGroups,
  };

  return <ExalusContext.Provider value={values}>{children}</ExalusContext.Provider>;
};
