import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useWakeLock } from 'react-screen-wake-lock';
import { Api } from 'lavva.exalushome';
import { Status } from 'lavva.exalushome/build/js/DataFrame';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import {
  ControllerUpdateType,
  ControllerUpdatesInfo,
  IControllerAvailableUpdateInfo,
  IDeviceUpdateInfo,
} from 'lavva.exalushome/build/js/Services/Updates/IUpdateInfo';
import { ControllerVersion } from 'lavva.exalushome/build/js/Services/Updates/IUpdateInfo';
import {
  DownloadProgressInfo,
  IUpdatesService,
  UpdateErrorCode,
  UpdateProgressInfo,
} from 'lavva.exalushome/build/js/Services/Updates/IUpdatesService';
import { UpdatesService } from 'lavva.exalushome/build/js/Services/Updates/UpdatesService';
import { DialogConfirmation } from '../../../../../components';
import ArrowButton from '../../../../../components/arrow-button';
import { Dialog } from '../../../../../components/dialog/base';
import TextInfo from '../../../../../components/text-info';
import { useBackdropContext } from '../../../../../hooks';
import { ROUTES } from '../../../../../routes';
import { FeedbackUpdate } from '../types';
import '../updates.scss';
import { useUpdateErrors } from './use-update-errors';
import { useUpdateProcess } from './use-update-process';

export const useUpdates = (device?: IDevice) => {
  const history = useHistory();
  const { t } = useTranslation('device-info');
  const { t: ti } = useTranslation('installation');
  const { t: tc } = useTranslation('common');
  const { turnOnBackdrop, turnOffBackdrop, turnOffBackdropPopup } = useBackdropContext();
  const [deviceVersion, setDeviceVersion] = useState<string>('');
  const [retransmitterPopup, setRetransmitterPopup] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [deviceUpdate, setDeviceUpdate] = useState<IDeviceUpdateInfo | null>(null);
  const [controllerUpdate, setControllerUpdate] = useState<ControllerUpdatesInfo | null>(null);
  const [controllerVersion, setControllerVersion] = useState<ControllerVersion[]>([]);
  const [show, setShow] = useState<boolean>(false);
  const [feedback, setFeedback] = useState<FeedbackUpdate | null>(null);
  const { handleUpdatesResponse, showUpdateError } = useUpdateErrors();
  const { request, release } = useWakeLock();

  useEffect(() => {
    request();

    return () => {
      release();
    };
  }, []);

  const getCurrentDeviceVersion = useCallback(async () => {
    if (device) {
      turnOnBackdrop();
      const updateService = Api.Get<IUpdatesService>(UpdatesService.ServiceName);
      const result: string | ResponseResult<UpdateErrorCode> = await updateService.CheckDeviceVersionAsync(device);

      handleUpdatesResponse<string>(result, () => {
        setDeviceVersion(result as string);
        setShow(true);
        turnOffBackdrop();
      });
    }
  }, [device]);

  const getCurrentControllerVersion = useCallback(async () => {
    turnOnBackdrop();
    const updateService = Api.Get<IUpdatesService>(UpdatesService.ServiceName);
    const result: ControllerVersion[] | ResponseResult<UpdateErrorCode> =
      await updateService.CheckControllerVersionAsync();

    handleUpdatesResponse<ControllerVersion[]>(result, () => {
      setControllerVersion(result as ControllerVersion[]);
      setShow(true);
      turnOffBackdrop();
    });
  }, []);

  const checkForDeviceUpdates = useCallback(async () => {
    setDeviceUpdate(null);
    setFeedback(null);

    if (device) {
      turnOnBackdrop();
      const updateService = Api.Get<IUpdatesService>(UpdatesService.ServiceName);
      const result: IDeviceUpdateInfo | ResponseResult<UpdateErrorCode> = await updateService.CheckDeviceUpdateAsync(
        device,
      );

      if (typeof (result as ResponseResult<UpdateErrorCode>).Type === 'number') {
        const updateResult = showUpdateError(result as ResponseResult<UpdateErrorCode>, true);

        if (updateResult) {
          setFeedback(updateResult);
        }
      } else {
        setDeviceUpdate(result as IDeviceUpdateInfo);
        turnOffBackdrop();
      }
    }
  }, [device]);

  const checkForControllerUpdates = useCallback(async () => {
    setControllerUpdate(null);

    turnOnBackdrop();
    const updateService = Api.Get<IUpdatesService>(UpdatesService.ServiceName);
    const result: ControllerUpdatesInfo = await updateService.CheckControllerUpdatesAsync();

    if (result.UpdatesAvailable.length > 0 || result.UpdatesNotAvailable.length > 0) {
      setControllerUpdate(result);
    }

    turnOffBackdrop();
  }, []);

  const updateStart = useCallback(
    async (force: boolean) => {
      if (device) {
        setLoading(true);
        const updateService = Api.Get<IUpdatesService>(UpdatesService.ServiceName);

        const result: Status.OK | ResponseResult<UpdateErrorCode> = await updateService.InstallDeviceUpdateAsync(
          device,
          (updateProgress: UpdateProgressInfo) => onUpdateDeviceProgress(updateProgress, deviceUpdate),
          undefined,
          force,
        );

        setLoading(false);
        if ((result as ResponseResult<UpdateErrorCode>).Type) {
          if ((result as ResponseResult<UpdateErrorCode>).Type === UpdateErrorCode.RetransmitterFound) {
            setRetransmitterPopup(true);
          } else {
            showUpdateError(result as ResponseResult<UpdateErrorCode>);
          }
        }
      }
    },
    [device, deviceUpdate],
  );

  const startOneControllerUpdate = useCallback(async (update: IControllerAvailableUpdateInfo) => {
    setLoading(true);
    const updateService = Api.Get<IUpdatesService>(UpdatesService.ServiceName);

    const result: Status.OK | ResponseResult<UpdateErrorCode> = await updateService.InstallControllerUpdateAsync(
      (updateProgress: UpdateProgressInfo) => onUpdateControllerProgress(updateProgress, update),
      (downloadProgress: DownloadProgressInfo) => onDownloadControllerProgress(downloadProgress),
      update.UpdateIdentifier,
      update.DownloadProgress === 0 && !update.UpdateIsDownloading,
    );

    setLoading(false);
    if (result === Status.OK) {
      if (update.DownloadProgress === 0 && !update.UpdateIsDownloading) {
        checkForControllerUpdates();
        turnOffBackdropPopup();
      } else window.location.href = ROUTES.Root();
    } else {
      showUpdateError(result as ResponseResult<UpdateErrorCode>);
    }
  }, []);

  const startAllControllerUpdates = useCallback(async (updates: IControllerAvailableUpdateInfo[]) => {
    setLoading(true);
    const updateService = Api.Get<IUpdatesService>(UpdatesService.ServiceName);

    const result: Status.OK | ResponseResult<UpdateErrorCode> = await updateService.InstallControllerUpdateAsync(
      (updateProgress: UpdateProgressInfo) => onUpdateAllControllerProgresses(updateProgress, updates),
      (downloadProgress: DownloadProgressInfo) => onDownloadControllerProgress(downloadProgress),
    );

    setLoading(false);
    if (result === Status.OK) {
      window.location.href = ROUTES.Root();
    } else {
      showUpdateError(result as ResponseResult<UpdateErrorCode>);
    }
  }, []);

  const {
    onUpdateDeviceProgress,
    onUpdateControllerProgress,
    onUpdateAllControllerProgresses,
    onDownloadControllerProgress,
  } = useUpdateProcess({
    checkForControllerUpdates,
  });

  const VersionDeviceButton = useMemo(
    () => (
      <>
        <ArrowButton
          className="m-t-24"
          title={t('exalus.params.Version.currentVersion')}
          onClick={getCurrentDeviceVersion}
        />
        <DialogConfirmation
          show={show}
          setShow={setShow}
          header={t('exalus.params.Version.software')}
          primaryBtnText={`${tc('buttons.understand')}`}
          primaryBtnAction={() => setShow(false)}
        >
          <div className="version-value">
            <TextInfo
              value={deviceVersion}
              label={`${t('exalus.params.Version.currentVersion')}:`}
              big
              reverse
              className="m-b-8"
            />
          </div>
        </DialogConfirmation>
      </>
    ),
    [deviceVersion, show],
  );

  const CheckUpdateDeviceButton = useMemo(
    () => (
      <ArrowButton
        className="m-t-24"
        title={t('exalus.params.Version.update')}
        onClick={() => history.push(ROUTES.DeviceUpdate(device?.Guid))}
      />
    ),
    [deviceUpdate, show, device],
  );

  const ControllerVersionButton = useMemo(
    () => (
      <>
        <ArrowButton title={ti('exalus.controllerVersion')} className="m-b-24" onClick={getCurrentControllerVersion} />
        <Dialog
          show={show}
          setShow={setShow}
          title={t('exalus.params.Version.currentVersion')}
          closeBtn
          className="update-controller"
        >
          <div className="update-controller--content dialog-content">
            {controllerVersion.map((version: ControllerVersion) => (
              <div key={version.Resource} className="version-container">
                <h3>{`${t(
                  `exalus.params.Version.${
                    version.Type === ControllerUpdateType.SoftwareUpdate ? 'SoftwareUpdate' : 'RadioFirmware'
                  }`,
                )} ${version.Type !== ControllerUpdateType.SoftwareUpdate ? version.Resource : ''}`}</h3>
                <div className="version-values">
                  <TextInfo
                    value={version.Version}
                    label={`${t('exalus.params.Version.currentVersion')}:`}
                    big
                    className="m-b-8"
                  />
                  <TextInfo
                    value={
                      version.Type !== null ? t(`exalus.params.Version.${ControllerUpdateType[version.Type]}`) : '-'
                    }
                    label={`${t('exalus.params.Version.type')}:`}
                    big
                    className="m-b-8"
                  />
                  <hr className="m-t-24 m-b-24" />
                </div>
              </div>
            ))}
          </div>
        </Dialog>
      </>
    ),
    [controllerVersion, show],
  );

  return {
    VersionDeviceButton,
    CheckUpdateDeviceButton,
    ControllerVersionButton,
    deviceUpdate,
    controllerUpdate,
    feedback,
    retransmitterPopup,
    loading,
    checkForDeviceUpdates,
    checkForControllerUpdates,
    updateStart,
    startOneControllerUpdate,
    startAllControllerUpdates,
    setRetransmitterPopup,
  };
};
