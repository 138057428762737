import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useBlindCalibrate } from '../../../../api/modules/blind/blind.hooks';
import { useGateCalibrate } from '../../../../api/modules/gate/gate.hooks';
import { ChannelBox, CustomBackdrop, NavHeader, Page, SubmitButton } from '../../../../components';
import { IconCalibrate } from '../../../../components/icons/calibrate';
import { ChannelTypeInternal } from '../../../../data-access/gql-types/graphql';
import { useChannelsState, useDevicesAndChannels } from '../../../../hooks';
import { ROUTES } from '../../../../routes';
import { ChannelCoverStateInterface, ChannelGateStateInterface } from '../../../../types';
import './index.scss';

const CalibrateChannel: React.FC = <T extends ChannelCoverStateInterface | ChannelGateStateInterface>() => {
  const history = useHistory();
  const { channelId } = useParams<{ channelId: string }>();
  const { t } = useTranslation('device-add');
  const calibrationBlind = useBlindCalibrate();
  const calibrationGate = useGateCalibrate();
  const { channel } = useDevicesAndChannels({ channelId });
  const { channelState } = useChannelsState();
  const calibrateState = (channelState[channelId] as T).calibrateState;

  const handleCalibrate = useCallback(() => {
    if (channel) {
      if (channel.data.type === ChannelTypeInternal.Blind) {
        calibrationBlind.mutate({ deviceId: channel.deviceId, channelId: channel.id });
      } else if (channel.data.type === ChannelTypeInternal.Gate) {
        calibrationGate.mutate({ deviceId: channel.deviceId, channelId: channel.id });
      }
    }
  }, [channel]);

  const calibrateButtonVisible = useMemo(() => {
    if (calibrateState?.started) return false;
    if (!calibrateState?.openingMilliseconds || !calibrateState?.openingMilliseconds) {
      return true;
    }

    return false;
  }, [calibrateState]);

  const nextStep = useCallback(() => {
    history.push(ROUTES.AddSuccess(channelId));
  }, [channelId]);

  return (
    <Page className="calibrate-device" header={<NavHeader />}>
      <div className="icon-container">
        <IconCalibrate />
      </div>
      <div className="text-container">
        <p className="text-header">{t('calibrateDevice.header')}</p>
        <p>{t('calibrateDevice.message')}</p>
      </div>
      <div className="action-box-container">{channel && <ChannelBox channel={channel} isListItem />}</div>
      {calibrateButtonVisible ? (
        <SubmitButton onClick={handleCalibrate} disabled={!channel}>
          {t('calibrateDevice.calibrate')}
        </SubmitButton>
      ) : (
        <button className="text-button" onClick={nextStep}>
          {t('calibrateDevice.next')}
        </button>
      )}
      <CustomBackdrop loading={!channel} />
    </Page>
  );
};

export default CalibrateChannel;
