import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DialogConfirmation, Header, NavHeader, Page } from '../../../components';
import { useBackdropContext } from '../../../hooks';
import { DialogScanning } from '../bluetooth-custom/dialog-scanning';
import { useBluetoothContext } from '../context';
import './index.scss';

const DeviceAddBluetooth: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation('device-add');
  const { t: tc } = useTranslation('common');
  const [bluetoothAvailable, setBluetoothAvailable] = useState<boolean | null>(null);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { searchBluetooth, disconnect, isCustomBluetooth, dialogScanning, setDialogScanning } = useBluetoothContext();

  useEffect(() => {
    const getAvailability = async () => {
      turnOnBackdrop();
      const availability = await navigator.bluetooth.getAvailability();
      setBluetoothAvailable(availability);
      turnOffBackdrop();
    };

    disconnect();
    getAvailability();
  }, []);

  const handleSearch = () => searchBluetooth(isCustomBluetooth);

  return (
    <Page
      isStickyHeader
      className="add-bluetooth"
      header={
        <>
          <NavHeader />
          <Header title={t('bluetooth.bluetoothDevices')} isUnderline />
        </>
      }
    >
      <div className="button-container">
        <button type="button" className="button" onClick={handleSearch}>
          {t('bluetooth.searchDevices')}
        </button>
      </div>
      <DialogScanning show={dialogScanning} setShow={setDialogScanning} />
      <DialogConfirmation
        show={bluetoothAvailable === false}
        setShow={() => console.log(false)}
        header={t('bluetooth.bluetoothAvailability.header')}
        content={t('bluetooth.bluetoothAvailability.content')}
        primaryBtnText={tc('buttons.return')}
        primaryBtnAction={history.goBack}
      />
    </Page>
  );
};

export default DeviceAddBluetooth;
