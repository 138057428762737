import React from 'react';
import { Header, NavHeader, Page } from '../../../../components';

interface ComponentProps {
  onBack: () => void;
}

const ReadMore: React.FC<ComponentProps> = ({ onBack }) => {
  return (
    <Page
      kind="above"
      header={
        <>
          <NavHeader onClick={onBack} />
          <Header title="Info" isUnderline />
        </>
      }
    >
      <div>info</div>
    </Page>
  );
};

export default ReadMore;
