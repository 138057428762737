import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { ControlWrapper, IconChevron, IconConnectionError, Slider } from '../../../../../../../../../components';
import { IconToastWarning2 } from '../../../../../../../../../components/icons/icon-toast-warning-2';
import { useGatePreciseControl } from '../../../../../hooks/use-gate-precise-control';
import { useGatePreciseDetails } from '../../hooks/use-gate-precise-details';
import Favourites from './favourites';

interface ComponentProps {
  channel: IDeviceChannel;
  active: boolean;
}

export const GatePreciseDetailsControls: React.FC<ComponentProps> = ({ channel, active }) => {
  const { t: tc } = useTranslation('common');
  const [sliderValue, setSliderValue] = useState<number>(0);
  const [targetValue, setTargetValue] = useState<number>(0);
  const { open, close, stop, handleSliderEvent } = useGatePreciseControl(channel);
  const {
    connectionIcon,
    calibrationNeed,
    channelOrientedError,
    isError,
    isWarning,
    position,
    controlDisabled,
    externalLimitSwitchActive,
  } = useGatePreciseDetails(channel);

  useEffect(() => {
    if (typeof position === 'number') {
      if (position === sliderValue && targetValue !== sliderValue) return;
      setSliderValue(position);
      setTargetValue(position);
    }
  }, [position]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => setTargetValue(Number(event.target.value));

  return (
    <div
      className={classNames('cover-detail-view', {
        'cover-detail-view--full-space': calibrationNeed,
      })}
    >
      <>
        {!controlDisabled && (
          <>
            <ControlWrapper>
              {isError ? (
                <IconToastWarning2 colorError size={16} {...(typeof isError === 'string' ? { text: isError } : {})} />
              ) : (
                isWarning && <IconToastWarning2 colorWarning size={16} />
              )}
              <div className="cover-detail-view__slider">
                {connectionIcon && <IconConnectionError big size={14} />}
                <Slider
                  onPointerUp={handleSliderEvent}
                  value={sliderValue}
                  targetValue={targetValue}
                  onChange={onChange}
                  active={active}
                  error={!!channelOrientedError}
                  showValue={false}
                  accentBar={externalLimitSwitchActive}
                />
              </div>
            </ControlWrapper>
            <div className="cover-detail-view__controls p-l-24 p-r-24">
              <div onClick={close} className="cover-detail-view__controls-down">
                <IconChevron withCircle direction="down" />
              </div>
              <button className="cover-detail-view__controls-stop" onClick={stop}>
                {tc('status.stop')}
              </button>
              <div onClick={open} className="cover-detail-view__controls-up">
                <IconChevron withCircle direction="up" />
              </div>
            </div>
            <Favourites channel={channel} setTargetValue={setTargetValue} position={position || 0} />
          </>
        )}
      </>
    </div>
  );
};
