import React from 'react';
import './index.scss';

export const IconGateTilt: React.FC = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-gate icon-gate-tilt"
  >
    <g clipPath="url(#clip0_23434_14686)" className="gate-position">
      <path
        d="M6 33 L6 9 L 33 9 33 33"
        stroke="#FF4016"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.3677 13.9187L6.09685 13.9187"
        stroke="#FF4016"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.3677 19.1858L6.09685 19.1858"
        stroke="#FF4016"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <rect width="20" height="20" transform="translate(20 20)" fill="white" />
    <path
      d="M26.5 32.8398L26.9375 29.6081V24.5L33.0625 24.5L33.0625 29.6081L33.5 32.8398M26.5 32.8398H33.5M26.5 32.8398L27.2662 35H32.8209L33.5 32.8398"
      stroke="#15141D"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="sub-icon"
    />
    <path d="M36.5 38H23.5V21.5H36.5V38Z" stroke="#15141D" strokeLinejoin="round" className="sub-icon" />
    <defs>
      <clipPath id="clip0_23434_14686">
        <rect width="28.3203" height="28.3203" fill="white" transform="matrix(0 1 -1 0 33.6719 7.32788)" />
      </clipPath>
    </defs>
  </svg>
);
