import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ChannelTypeInternal } from '../../../../data-access/gql-types/graphql';
import { useChannelsState } from '../../../../hooks';
import { ChannelCoverStateInterface, ChannelGateStateInterface, ChannelInterface } from '../../../../types';
import { EcoRatio } from '../../eco-ratio';

interface PropsInterface {
  channel: ChannelInterface;
}

export const ChannelBoxPostName: React.FC<PropsInterface> = <
  T extends ChannelCoverStateInterface | ChannelGateStateInterface,
>({
  channel,
}) => {
  const { t: tc } = useTranslation('common');
  const { channelState } = useChannelsState();

  const postName = useMemo(() => {
    switch (channel.data.type) {
      case ChannelTypeInternal.Meter:
        return <EcoRatio channel={channel} />;
      case ChannelTypeInternal.Blind: {
        // case ChannelTypeInternal.Gate: {
        const calibrateState = (channelState[channel.id] as T)?.calibrateState;

        if (calibrateState) {
          if (calibrateState.progress)
            return <span className="calibration">{`${tc('status.calibrate')} ${calibrateState.progress}%`}</span>;
          else if (!(calibrateState.openingMilliseconds && calibrateState.closingMilliseconds)) {
            return <span className="no-calibration">{tc('status.no-calibrate')}</span>;
          }
        }

        return null;
      }
      default:
        return null;
    }
  }, [channel.data.type, (channelState[channel.id] as T).calibrateState, tc]);

  return <>{postName}</>;
};
