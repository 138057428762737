import React from 'react';
import './index.scss';

interface PropsInterface {
  header: string;
}

export const CurrentHeader: React.FC<PropsInterface> = ({ header }) => (
  <div className="energy-consumption-header">
    <h4 className="energy-consumption-header__header">{header}</h4>
  </div>
);
