import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import c from 'classnames';
import { IconChevron, IconInitial, PopUpNav } from '../../../../components';
import { IconDelete, IconUserAccess } from '../../../../components/popup-nav/icons';
import { InstallationAccessType } from '../../../../data-access/gql-types/graphql';
import { ROUTES } from '../../../../routes';
import { SharedItemType } from '../../../../types';
import { useInstallationShare } from '../../hooks/use-installation-share';
import './index.scss';

interface ComponentProps {
  id: string;
  name: string;
  email: string;
  img: string;
  accessType?: InstallationAccessType;
  sharedItemType?: SharedItemType;
  background?: boolean;
  redirect?: boolean;
  access?: boolean;
  deletePopup?: (userId: string, userName: string) => void;
  editAccess?: (userId: string) => void;
}

const UserBox: React.FC<ComponentProps> = ({
  id,
  name,
  email,
  img,
  accessType,
  sharedItemType,
  background = false,
  redirect = false,
  access = false,
  deletePopup,
  editAccess,
}) => {
  const history = useHistory();
  const [imgError, setImgError] = useState<boolean>(false);
  const { t } = useTranslation('installation');
  const {
    methods: { handleSetUserId },
  } = useInstallationShare();

  const handleClick = () => {
    if (redirect && !access) {
      handleSetUserId(id);
      history.push(ROUTES.SharedUser(id));
    }
  };

  const handleEditAccess = () => {
    if (editAccess) editAccess(id);
  };

  const showDeletePopup = () => {
    if (deletePopup) deletePopup(id, name);
  };

  const dots = useMemo(() => {
    return (
      access &&
      accessType !== InstallationAccessType.Admin &&
      accessType !== InstallationAccessType.Owner &&
      sharedItemType !== SharedItemType.Installation
    );
  }, [access, accessType]);

  return (
    <button className={c('user-box', { background: background })} onClick={handleClick}>
      <div className="left-section text-ellipsis">
        {img && !imgError ? (
          <img height={40} width={40} src={img} alt="user-img" onError={() => setImgError(true)} />
        ) : (
          <IconInitial initial={name.charAt(0)} />
        )}
        <div className="text-ellipsis">
          <p className="name text-ellipsis">
            {name} {accessType && <span className="separator">|</span>}
            &nbsp;
            {accessType && (
              <span
                className={c('access text-ellipsis', {
                  accent: accessType === InstallationAccessType.Admin || accessType === InstallationAccessType.Owner,
                })}
              >
                {t(`access_${accessType}`)}
              </span>
            )}
          </p>
          <p className="email text-ellipsis">{email}</p>
        </div>
      </div>
      {dots ? (
        <PopUpNav
          dark
          links={[
            {
              label: t('shared_user.edit_access'),
              onClick: handleEditAccess,
              hasLineBelow: true,
              icon: <IconUserAccess />,
            },
            {
              label: t('shared_user.remove_access'),
              onClick: showDeletePopup,
              hasLineBelow: false,
              icon: <IconDelete />,
            },
          ]}
        />
      ) : redirect ? (
        <IconChevron direction="right" />
      ) : null}
    </button>
  );
};

export default UserBox;
