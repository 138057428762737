import { InstallationAccessType, ShareInfoModificationType } from '../../../data-access/gql-types/graphql';
import { SharedItemType, SharingType } from '../../../types';
import { ShareGroup, ShareChannel, ShareItem } from './types';

export const SET_SHARE_INSTALLATION_ID = 'SET_SHARE_INSTALLATION_ID';
export const SET_SHARE_EMAIL = 'SET_SHARE_EMAIL';
export const SET_USER_ID = 'SET_USER_ID';
export const SET_SHARE_SUBJECT = 'SET_SHARE_SUBJECT';
export const SET_SHARE_PERMISSION = 'SET_SHARE_PERMISSION';
export const SET_SHARE_RESTRICTION_ACCESS = 'SET_SHARE_RESTRICTION_ACCESS';
export const SET_SHARE_QUANTITY = 'SET_SHARE_QUANTITY';
export const UPDATE_SHARE_GROUPS = 'UPDATE_SHARE_GROUPS';
export const UPDATE_SHARE_CHANNELS = 'UPDATE_SHARE_CHANNELS';
export const SET_SHARE_TIME_START = 'SET_SHARE_TIME_START';
export const SET_SHARE_TIME_END = 'SET_SHARE_TIME_END';
export const SET_SHARE_DATE_START = 'SET_SHARE_DATE_START';
export const SET_SHARE_DATE_END = 'SET_SHARE_DATE_END';
export const SET_SHARE_ITEM = 'SET_SHARE_ITEM';
export const SET_SHARE_INVITATION_DATE = 'SET_SHARE_INVITATION_DATE';
export const RESET_SHARE_INSTALLATION = 'RESET_SHARE_INSTALLATION';
export const SET_EDITED_ID = 'SET_EDITED_ID';
export const SET_MODIFICATION_TYPE = 'SET_MODIFICATION_TYPE';

export type SetShareInstallationIdAction = {
  type: typeof SET_SHARE_INSTALLATION_ID;
  payload: string;
};

export type SetShareEmailAction = {
  type: typeof SET_SHARE_EMAIL;
  payload: string;
};

export type SetUserIdAction = {
  type: typeof SET_USER_ID;
  payload: string;
};

export type SetShareSubjectAction = {
  type: typeof SET_SHARE_SUBJECT;
  payload: SharedItemType;
};

export type SetSharePermissionAction = {
  type: typeof SET_SHARE_PERMISSION;
  payload: InstallationAccessType;
};

export type SetShareRestrictionAccessAction = {
  type: typeof SET_SHARE_RESTRICTION_ACCESS;
  payload: SharingType;
};

export type SetShareQuantityAction = {
  type: typeof SET_SHARE_QUANTITY;
  payload: number;
};

export type UpdateShareGroupsAction = {
  type: typeof UPDATE_SHARE_GROUPS;
  payload: ShareGroup[];
};

export type UpdateShareChannelsAction = {
  type: typeof UPDATE_SHARE_CHANNELS;
  payload: ShareChannel[];
};

export type SetShareTimeStartAction = {
  type: typeof SET_SHARE_TIME_START;
  payload: string;
};

export type SetShareTimeEndAction = {
  type: typeof SET_SHARE_TIME_END;
  payload: string;
};

export type SetShareDateStartAction = {
  type: typeof SET_SHARE_DATE_START;
  payload: Date;
};

export type SetShareDateEndAction = {
  type: typeof SET_SHARE_DATE_END;
  payload: Date;
};

export type SetShareItemAction = {
  type: typeof SET_SHARE_ITEM;
  payload: ShareItem;
};

export type SetShareInvitationDate = {
  type: typeof SET_SHARE_INVITATION_DATE;
  payload: string;
};

export type SetEditedIdAction = {
  type: typeof SET_EDITED_ID;
  payload: string;
};

export type SetModificationTypeAction = {
  type: typeof SET_MODIFICATION_TYPE;
  payload: ShareInfoModificationType;
};

export type ResetShareInstallationAction = {
  type: typeof RESET_SHARE_INSTALLATION;
};
