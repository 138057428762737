import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { DialogConfirmation, IconNotification, Switch } from '../../../components';
import {
  UpdateNotificationPermissionsMutation,
  UpdateNotificationPermissionsMutationVariables,
  UpdateNotificationPermissionsRequestInput,
} from '../../../data-access/gql-types/graphql';
import { UPDATE_NOTIFICATIONS } from '../../../data-access/mutations/user';
import { useNative, useProfile } from '../../../hooks';
import { toastError } from '../../../utils/toast';
import './index.scss';
import { NotificationTutorial } from './notification-tutorial';

const Notifications: React.FC = () => {
  const { t } = useTranslation('profile');
  const { t: tc } = useTranslation('common');
  const [permissionPopup, setPermissionPopup] = useState<boolean>(false);
  const [tutorial, openTutorial] = useState<boolean>(false);
  const { userProfileData, refetchUserProfile } = useProfile();
  const { registerNotificationToken } = useNative();
  const [updateNotifications] = useMutation<
    UpdateNotificationPermissionsMutation,
    UpdateNotificationPermissionsMutationVariables
  >(UPDATE_NOTIFICATIONS);
  const [settings, updateSettings] = useState<UpdateNotificationPermissionsRequestInput>({
    sharingPush: true,
    sharingEmail: true,
    channelsPush: true,
    channelsInApp: true,
    channelsEmail: true,
    automationPush: true,
    automationInApp: true,
    automationEmail: true,
  });

  useEffect(() => {
    if ('Notification' in window) {
      if (Notification.permission !== 'granted') {
        setPermissionPopup(true);
      }
    }
  }, []);

  useEffect(() => {
    if (userProfileData?.userProfile) {
      updateSettings({
        sharingPush: userProfileData.userProfile.sharingPushNotificationPermission,
        sharingEmail: userProfileData.userProfile.sharingEmailNotificationPermission,
        channelsPush: userProfileData.userProfile.channelsPushNotificationPermission,
        channelsInApp: userProfileData.userProfile.channelsInAppNotificationPermission,
        channelsEmail: userProfileData.userProfile.channelsEmailNotificationPermission,
        automationPush: userProfileData.userProfile.automationPushNotificationPermission,
        automationInApp: userProfileData.userProfile.automationInAppNotificationPermission,
        automationEmail: userProfileData.userProfile.automationEmailNotificationPermission,
      });
    }
  }, [userProfileData?.userProfile]);

  const handleToggle = (settingsKey: keyof UpdateNotificationPermissionsRequestInput) => {
    const newSettings = { ...settings, [settingsKey]: !settings[settingsKey] };
    updateSettings(newSettings);

    updateNotifications({
      variables: {
        input: newSettings,
      },
      onCompleted: (data) => {
        if (data.updateNotificationPermissions.ok) {
          refetchUserProfile();
        } else {
          toastError({ content: tc('errors.somethingWentWrong') });
          // TODO errors
        }
      },
    });
  };

  const handleTogglePushNotifications = () => {
    handleToggle('sharingPush');

    if (!settings.sharingPush && 'Notification' in window) {
      Notification.requestPermission()
        .then((data) => {
          registerNotificationToken(data);
        })
        .catch((error) => console.log('Notification error:', error));
    }
  };

  return (
    <>
      {!tutorial ? (
        <div className="notification-settings max-width-desktop">
          <p className="bold">{t('share-forward-notifications')}</p>
          <div className="option">
            <p>{t('share-app-notification')}</p>
            <Switch checked={true} onChange={() => console.log('change')} disabled />
          </div>
          <div className="option column">
            <div className="row">
              <p>{t('share-push-notification')}</p>
              <Switch checked={settings.sharingPush} onChange={handleTogglePushNotifications} />
            </div>
            <p className="notification__issue--question">{t('notificationIssues.question')}</p>
            <p onClick={() => openTutorial(true)} className="notification__issue--learn">
              {t('notificationIssues.learnMore')}
            </p>
          </div>
          <div className="option">
            <p>{t('share-email-notification')}</p>
            <Switch checked={settings.sharingEmail} onChange={() => handleToggle('sharingEmail')} />
          </div>

          <p className="bold margin">{t('device-issues-notifications')}</p>
          <div className="option">
            <p>{t('share-app-notification')}</p>
            <Switch checked={settings.channelsInApp} onChange={() => handleToggle('channelsInApp')} />
          </div>
          <div className="option">
            <p>{t('share-email-notification')}</p>
            <Switch checked={settings.channelsEmail} onChange={() => handleToggle('channelsEmail')} />
          </div>
          <DialogConfirmation
            show={permissionPopup}
            setShow={setPermissionPopup}
            header={t('notificationPermissions.header')}
            content={t('notificationPermissions.content')}
            primaryBtnText={tc('buttons.understand')}
            icon={<IconNotification />}
            primaryBtnAction={() => setPermissionPopup(false)}
          />
        </div>
      ) : (
        <NotificationTutorial setOpen={openTutorial} />
      )}
    </>
  );
};

export default Notifications;
