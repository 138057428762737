import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useActionBatch } from '../../api/modules/action/action.hooks';
import { SwitchLightOnOffBatchChannel, TypeOfActionType } from '../../api/modules/action/action.types';
import { ChannelTypeInternal, LightToggleValueInternal } from '../../data-access/gql-types/graphql';
import { ChannelInterface } from '../../types';
import { toastInfo } from '../../utils/toast';
import { useNative } from '../native';

interface UseActionControlInterface {
  handleControlsOn: (value: string) => void;
}

export const useActionControl = (channels: ChannelInterface[]): UseActionControlInterface => {
  const { t } = useTranslation('action');
  const { forceVibration } = useNative();
  const actionBatch = useActionBatch();

  const handleControlsOn = useCallback(
    (value: string) => {
      if (!channels.length) {
        toastInfo({ content: t('trigger.validationErrors.deviceNotFound') });
        return;
      }

      actionBatch.mutate({
        controls: channels.map((channel) => ({
          deviceId: channel.deviceId,
          channelId: channel.id,
          value: value === LightToggleValueInternal.On,
          $type:
            channel.data.type === ChannelTypeInternal.Light
              ? TypeOfActionType.BatchLightToggleOnOffRequest
              : TypeOfActionType.BatchSwitchToggleOnOffRequest,
        })) as SwitchLightOnOffBatchChannel[],
      });

      forceVibration();
    },
    [channels],
  );

  return {
    handleControlsOn,
  };
};
