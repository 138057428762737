import { useTranslation } from 'react-i18next';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import { NetworkConfigurationService } from 'lavva.exalushome.network';
import { PortosService } from 'lavva.exalushome.portos';
import { WebCamsService } from 'lavva.exalushome.webcams';
import { WektaIntercomsService } from 'lavva.exalushome.wektaintercoms';
import { AppState } from 'lavva.exalushome/build/js/Services/IAppStateService';
import { useBackdropContext } from '../../../hooks';
import { useExalusServicesContext } from '../context/services';

export const useAppState = () => {
  const { t } = useTranslation('common');
  const { appStateApi, configurationApi } = useExalusServicesContext();
  const { turnOnBackdropPopup, turnOffBackdropPopup } = useBackdropContext();

  const initObservableAppStateChange = () => {
    appStateApi().OnAppStateChanged().Subscribe((state: AppState) => {
      switch (state) {
        case AppState.Connected:
          turnOffBackdropPopup();
          break;
        case AppState.Connecting:
          turnOnBackdropPopup({ message: t(`exalus.appState.${AppState[AppState.Connecting]}`), loader: true });
          break;
        case AppState.Disconnected:
          turnOnBackdropPopup({
            message: t(`exalus.appState.${AppState[AppState.Disconnected]}`),
            buttonTitle: t('exalus.appState.reload'),
            handleClick: () => window.location.reload(),
            changeInstallationButton: true,
          });
          break;
        case AppState.Disconnecting:
          turnOnBackdropPopup({
            message: t(`exalus.appState.${AppState[AppState.Disconnecting]}`),
            buttonTitle: t('exalus.appState.reload'),
            handleClick: () => window.location.reload(),
            changeInstallationButton: true,
          });
          break;
        case AppState.EnteredLowPowerMode:
          turnOnBackdropPopup({
            message: t(`exalus.appState.${AppState[AppState.EnteredLowPowerMode]}`),
            buttonTitle: t('exalus.appState.reload'),
            handleClick: () => window.location.reload(),
            changeInstallationButton: true,
          });
          break;
        case AppState.ExitedLowPowerMode:
          turnOnBackdropPopup({
            message: t(`exalus.appState.${AppState[AppState.ExitedLowPowerMode]}`),
            buttonTitle: t('exalus.appState.reload'),
            handleClick: () => window.location.reload(),
          });
          break;
        case AppState.FailedToConnect:
          turnOnBackdropPopup({
            message: t(`exalus.appState.${AppState[AppState.FailedToConnect]}`),
            buttonTitle: t('exalus.appState.reload'),
            handleClick: () => window.location.reload(),
            changeInstallationButton: true,
          });
          break;
        case AppState.LoggedIn:
          ExtaLifeService.Init();
          NetworkConfigurationService.Init();
          PortosService.Init();
          WebCamsService.Init();
          WektaIntercomsService.Init();
          turnOffBackdropPopup();
          break;
        case AppState.LoggedOut: {
          turnOnBackdropPopup({
            message: t(`exalus.appState.${AppState[AppState.LoggedOut]}`),
            buttonTitle: t('buttons.close'),
            handleClick: () => turnOffBackdropPopup(),
          });
          break;
        }
        case AppState.Reconnecting:
          turnOnBackdropPopup({
            message: t(`exalus.appState.${AppState[AppState.Reconnecting]}`),
            buttonTitle: t('exalus.appState.reload'),
            handleClick: () => window.location.reload(),
            changeInstallationButton: true,
            loader: true,
          });
          break;
        case AppState.ReturnedFromSuspension:
          turnOffBackdropPopup();
          break;
        case AppState.Suspended:
          turnOnBackdropPopup({ message: t(`exalus.appState.${AppState[AppState.Suspended]}`) });
          break;
      }
    });
  };

  const initObservableControllerConfigurationChange = () => {
    configurationApi().OnEnteredConfigurationEvent().Subscribe(() => {
      turnOnBackdropPopup({ message: t('exalus.configurationChanged.EnteredConfiguration'), loader: true });
    });

    configurationApi().OnExitedConfigurationEvent().Subscribe(() => {
      turnOnBackdropPopup({
        message: t('exalus.configurationChanged.ExitedConfiguration'),
        buttonTitle: t('exalus.configurationChanged.reload'),
        handleClick: () => window.location.reload(),
        changeInstallationButton: true,
      });
    });
  };

  return {
    initObservableAppStateChange,
    initObservableControllerConfigurationChange,
  };
};
